<v-card [center]="true" padding="lg" *ngIf="vm$ | async as vm">
  <v-card-image-header
    slot="header"
    [text]="
      'pages.joinOrganization.header'
        | translate: { organizationName: invitation?.organization?.name }
    "
    image="/assets/images/logos/incidite-full-white.svg"
    imageClass="!h-7"></v-card-image-header>

  <div slot="body" class="mt-4">
    @if (vm.user) {
      <v-user-card [user]="vm.user"></v-user-card>
    }

    @if (vm.isAlreadyMember) {
      <div class="mt-4">
        <v-message severity="info">
          {{ 'pages.joinOrganization.alreadyMember' | translate }}
        </v-message>
      </div>
      <div class="mt-4 flex space-between gap-2">
        <div>
          <v-button
            variant="secondary"
            [fullWidth]="true"
            [label]="'pages.joinOrganization.createNewAccountBtn' | translate"
            icon="pi pi-user-plus"
            type="submit"
            (press)="logout()"></v-button>
        </div>
        <div class="flex-1">
          <v-button
            [fullWidth]="true"
            [label]="'pages.joinOrganization.goToDashboardBtn' | translate"
            icon="pi pi-home"
            type="submit"
            (press)="goToDashboard()"></v-button>
        </div>
      </div>
    } @else {
      <form [formGroup]="joinOrgForm" (ngSubmit)="onFormSubmit()">
        <p-message
          severity="danger"
          styleClass="w-full mt-4"
          [text]="joinOrganizationError"
          *ngIf="joinOrganizationError"></p-message>

        <div class="mt-4 justify-center w-full">
          <v-button
            [fullWidth]="true"
            [label]="'pages.joinOrganization.joinBtn' | translate"
            icon="pi pi-sign-in"
            type="submit"
            [loading]="this.isJoining"
            [disabled]="!joinOrgForm.valid"></v-button>
        </div>
      </form>
    }
  </div>
</v-card>
