import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ServiceState } from 'src/app/models/service';

@Component({
  selector: 'app-service-state',
  standalone: true,
  templateUrl: './service-state.component.html',
  styleUrls: ['./service-state.component.scss'],
  imports: [CommonModule, TranslateModule],
})
export class ServiceStateComponent {
  @Input() state?: ServiceState;

  get serviceStateText(): string {
    switch (this.state) {
      case ServiceState.OPERATIONAL:
        return 'misc.serviceState.operational';
      case ServiceState.DEGRADED:
        return 'misc.serviceState.degraded';
      case ServiceState.DOWN:
        return 'misc.serviceState.down';
    }
    return 'misc.serviceState.unknown';
  }
}
