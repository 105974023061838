import { Component, Input } from '@angular/core';
import { SharedCommonModule } from 'src/app/core/common.module';
import { IncidentSchemaState } from 'src/app/models/incident-schema';

@Component({
  standalone: true,
  imports: [SharedCommonModule],
  selector: 'app-incident-state',
  templateUrl: './incident-state.component.html',
  styleUrls: ['./incident-state.component.scss'],
})
export class IncidentStateComponent {
  constructor() {}

  @Input() state?: IncidentSchemaState;
}
