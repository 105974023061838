import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  ControlContainer,
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { FormErrorComponent } from '../utils/form-error/form-error.component';

@Component({
  selector: 'v-input',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, FormsModule, FormErrorComponent],
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class VInputComponent {
  /**
   * Which type of input to use
   */
  @Input()
  type: string = 'text';

  /**
   * Placeholder text to display
   */
  @Input()
  placeholder: string = 'Enter text';

  @Input()
  size: 'small' | 'medium' | 'large' = 'medium';

  /**
   * Optional form control name
   */
  @Input()
  fControlName?: string;

  /**
   * Optionally sets the test id for the input
   */
  @Input()
  testId?: string;
}
