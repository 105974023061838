import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'v-button',
  standalone: true,
  imports: [CommonModule],
  template: `<button
    [type]="type"
    [class]="class"
    [disabled]="disabled || loading"
    [attr.data-testid]="testId"
    (click)="press.emit($event)">
    <i *ngIf="icon && !loading" [class]="icon"></i>
    <i *ngIf="loading" class="pi pi-spin pi-spinner"></i>
    {{ label }}
  </button>`,
  styleUrl: './button.component.scss',
})
export class VButtonComponent {
  /**
   * Which variant to use
   */
  @Input()
  variant: 'primary' | 'secondary' | 'outline' | 'danger' = 'primary';

  @Input()
  size: 'small' | 'medium' | 'large' = 'medium';

  @Input()
  type: 'button' | 'submit' = 'button';

  @Input()
  fullWidth: boolean = false;

  /**
   * Optional icon to display
   */
  @Input()
  icon: string = '';

  /**
   * Whether the button is disabled
   */
  @Input()
  disabled: boolean = false;

  /**
   * Whether the button should display a loading indicator
   */
  @Input()
  loading: boolean = false;

  /**
   * Button contents
   *
   * @required
   */
  @Input()
  label = 'Button';

  /**
   * Optionally sets the test id for the button
   */
  @Input()
  testId?: string;

  /**
   * Optional click handler
   */
  @Output()
  press = new EventEmitter<Event>();

  get class(): string {
    const classes = ['v-button'];

    switch (this.variant) {
      case 'primary':
        classes.push('v-button-primary');
        break;
      case 'secondary':
        classes.push('v-button-secondary');
        break;
      case 'outline':
        classes.push('v-button-outline');
        break;
      case 'danger':
        classes.push('v-button-danger');
        break;
    }

    switch (this.size) {
      case 'small':
        classes.push('v-button-small');
        break;
      case 'medium':
        classes.push('v-button-medium');
        break;
      case 'large':
        classes.push('v-button-large');
        break;
    }

    if (this.fullWidth) {
      classes.push('v-button-full-width');
    }

    return classes.join(' ');
  }
}
