import { CommonModule } from '@angular/common';
import { Component, inject, Input, ViewEncapsulation } from '@angular/core';
import {
  ControlContainer,
  FormGroup,
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

export interface RadioOption {
  label: string;
  description: string;
  value: any;
  icon?: string;
  disabled?: boolean;
}

@Component({
  selector: 'v-radio-group',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './radio-group.component.html',
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class VRadioGroupComponent {
  private controlContainer = inject(ControlContainer);

  /**
   * The variant of the radio group
   */
  @Input() variant: 'neutral' | 'dark' = 'neutral';

  /**
   * Options to display in the dropdown
   */
  @Input()
  options: RadioOption[] = [];

  /**
   * form control name
   */
  @Input({ required: true })
  fControlName!: string;

  setValue(option: RadioOption) {
    if (option.disabled) return;
    this.formGroup.get(this.fControlName)?.setValue(option.value);
    this.formGroup.get(this.fControlName)?.markAsDirty();
  }

  get formGroup(): FormGroup {
    return this.controlContainer.control as FormGroup;
  }

  get currentValue(): any {
    return this.formGroup.get(this.fControlName)?.value;
  }
}
