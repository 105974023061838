import { NgModule } from '@angular/core';
import { StatuspageService } from './statuspage.service';
import { NgxsModule } from '@ngxs/store';
import { StatuspageState } from './statuspages.state';
import { StatuspageDetailsState } from './statuspage-details.state';

@NgModule({
  declarations: [],
  imports: [NgxsModule.forFeature([StatuspageState, StatuspageDetailsState])],
  providers: [StatuspageService],
  exports: [],
})
export class StatuspagesStateModule {}
