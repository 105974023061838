import { Directive, Input, OnChanges, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { GroupingValue } from '../data-display.component';

@Directive({
  selector: '[vDataItem]',
})
export class VDataItemDirective implements OnChanges {
  constructor(public templateRef: TemplateRef<any>) {}

  groupingKeys$ = new Subject<{ [k: string]: GroupingValue }>();

  @Input() groupingKeys: { [k: string]: GroupingValue } = {};

  ngOnChanges(): void {
    this.groupingKeys$.next(this.groupingKeys);
  }
}
