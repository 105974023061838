import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AuthorizedMenuItem } from 'src/app/models/permission';
import { MenuItem } from 'src/app/services/menu/menuItem';

@Component({
  selector: 'v-menu',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule],
  styleUrl: './menu.component.scss',
  templateUrl: './menu.component.html',
})
export class VMenuComponent {
  /**
   * Items to display in the menu
   */
  @Input()
  public items: AuthorizedMenuItem[] = [];

  @Input()
  public size: 'small' | 'medium' | 'large' = 'medium';

  @Input()
  public direction: 'horizontal' | 'vertical' = 'vertical';

  @Input() collapsed?: boolean;

  @Output()
  private selected = new EventEmitter<MenuItem>();

  onClick(item: MenuItem) {
    if (item.onClicked) {
      item.onClicked();
    }

    this.selected.emit(item);
  }
}
