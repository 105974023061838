import { inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { IncidentEvent } from 'src/app/models/incident';
import { Usage } from 'src/app/models/subscription-plan';
import { AddIncidentEventAction } from 'src/app/store/incidents/incidents.actions';
import { EVENT_FIELDS } from 'src/app/store/incidents/incidents.service';
import { UsageUpdatedAction } from 'src/app/store/subscription/subscription.actions';
import { UserState } from 'src/app/store/user/user.state';

const EVENT_SUBSCRIPTION = gql`
  subscription Events($organizationUUID: ID!) {
    incidentEvents(organizationUUID: $organizationUUID) {
      incidentId
      event {
        ${EVENT_FIELDS}
      }
    }
  }
`;

const USAGE_SUBSCRIPTION = gql`
  subscription Usage($organizationId: ID!) {
    usageEvents(organizationId: $organizationId) {
      featureName
      usage {
        unit
        value
      }
    }
  }
`;

interface IncidentSubscriptionEvent {
  incidentId: number;
  event: IncidentEvent;
}

interface UsageSubscriptionEvent {
  featureName: string;
  usage: Usage;
}

@Injectable({
  providedIn: 'root',
})
export class RealtimeService {
  private store = inject(Store);
  private apollo = inject(Apollo);

  backoffInterval = 500;
  maxBackoffInterval = 10_000;

  constructor() {
    this.store.select(UserState.getCurrentOrganizationID).subscribe({
      next: orgId => {
        if (orgId) {
          this.subscribeToIncidentEvents(orgId);
          this.subscribeToUsageEvents(orgId);
        }
      },
    });
  }

  subscribeToIncidentEvents(orgId: string) {
    this.apollo
      .subscribe<{ incidentEvents: IncidentSubscriptionEvent }>({
        query: EVENT_SUBSCRIPTION,
        variables: {
          organizationUUID: orgId,
        },
      })
      .subscribe({
        next: ({ data }) => {
          if (data) {
            this.backoffInterval = 500;

            this.store.dispatch(
              new AddIncidentEventAction(
                data.incidentEvents.incidentId,
                data.incidentEvents.event
              )
            );
          }
        },
        error: () => {
          // resubscribe
          if (this.backoffInterval * 2 < this.maxBackoffInterval) {
            this.backoffInterval *= 2;
          } else {
            this.backoffInterval = this.maxBackoffInterval;
          }

          console.warn(
            `[RealtimeService] Incident subscription failed.. Retrying in ${this.backoffInterval}ms`
          );

          setTimeout(() => {
            this.subscribeToIncidentEvents(orgId);
          }, this.backoffInterval);
        },
      });
  }

  subscribeToUsageEvents(orgId: string) {
    this.apollo
      .subscribe<{ usageEvents: UsageSubscriptionEvent }>({
        query: USAGE_SUBSCRIPTION,
        variables: {
          organizationId: orgId,
        },
      })
      .subscribe({
        next: ({ data }) => {
          if (data) {
            this.backoffInterval = 500;

            this.store.dispatch(
              new UsageUpdatedAction(
                data.usageEvents.featureName,
                data.usageEvents.usage
              )
            );
          }
        },
        error: () => {
          // resubscribe
          if (this.backoffInterval * 2 < this.maxBackoffInterval) {
            this.backoffInterval *= 2;
          } else {
            this.backoffInterval = this.maxBackoffInterval;
          }

          console.warn(
            `[RealtimeService] Usage subscription failed.. Retrying in ${this.backoffInterval}ms`
          );

          setTimeout(() => {
            this.subscribeToUsageEvents(orgId);
          }, this.backoffInterval);
        },
      });
  }
}
