<input
  *ngIf="fControlName"
  [type]="type"
  [formControlName]="fControlName"
  class="v-input"
  [ngClass]="{
    'v-input-small': size === 'small',
    'v-input-medium': size === 'medium',
    'v-input-large': size === 'large'
  }"
  [placeholder]="placeholder"
  [attr.data-testid]="testId" />
