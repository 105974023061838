import { Component, OnInit, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { RealtimeService } from 'src/app/services/realtime/realtime.service';
import { LoadSubscriptionAction } from 'src/app/store/subscription/subscription.actions';
import { LoadUserAction } from 'src/app/store/user/user.actions';

@Component({
  selector: 'app-layout-dashboard',
  templateUrl: './internal.component.html',
})
export class InternalLayoutComponent implements OnInit {
  rt = inject(RealtimeService);

  private store = inject(Store);

  ngOnInit(): void {
    this.store.dispatch(new LoadUserAction());
    this.store.dispatch(new LoadSubscriptionAction());
  }
}
