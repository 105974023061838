import { NgModule } from '@angular/core';
import { SharedCommonModule } from 'src/app/core/common.module';
import { OAuth2StateModule } from 'src/app/store/oauth2/oauth2.state.module';
import { OAuth2ConsentPageComponent } from './consent/consent.component';
import { JoinOrganizationPageComponent } from './join-organization/join-organization.component';
import { LoginPageComponent } from './login/login.component';
import { MaintenancePageComponent } from './maintenance/maintenance.component';
import { NewOrganizationPageComponent } from './new-organization/new-organization.component';
import { PublicPageComponent } from './public.component';
import { RecoveryPageComponent } from './recovery/recovery.component';
import { RegisterPageComponent } from './register/register.component';
import { VerificationPageComponent } from './verification/verification.component';

@NgModule({
  declarations: [PublicPageComponent],
  imports: [
    SharedCommonModule,
    RegisterPageComponent,
    LoginPageComponent,
    RecoveryPageComponent,
    NewOrganizationPageComponent,
    JoinOrganizationPageComponent,
    VerificationPageComponent,
    MaintenancePageComponent,
    OAuth2ConsentPageComponent,
    OAuth2StateModule,
  ],
})
export class PublicModule {}
