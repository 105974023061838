<v-card [center]="true" padding="lg">
  <v-card-image-header
    slot="header"
    [text]="'pages.createOrganization.header' | translate"
    image="/assets/images/logos/incidite-full-white.svg"
    imageClass="!h-7"></v-card-image-header>

  <div slot="body">
    <form [formGroup]="newOrgForm" (ngSubmit)="onFormSubmit()">
      <div class="flex flex-col mt-4">
        <v-input-field
          [label]="'pages.createOrganization.organizationNameInput' | translate"
          fControlName="organizationName">
          <v-input
            type="text"
            [placeholder]="
              'pages.createOrganization.organizationNamePlaceholder' | translate
            "
            fControlName="organizationName"></v-input>
        </v-input-field>

        <p-message
          severity="danger"
          styleClass="w-full mt-4"
          [text]="createOrganizationError"
          *ngIf="createOrganizationError"></p-message>

        <div class="align-self-end mt-4 flex justify-between">
          <v-button
            (press)="logout()"
            variant="secondary"
            [label]="'actions.logout' | translate"></v-button>

          <v-button
            [label]="'pages.createOrganization.createOrgBtn' | translate"
            icon="pi pi-plus"
            type="submit"
            [disabled]="!newOrgForm.valid"></v-button>
        </div>
      </div>
    </form>
  </div>
</v-card>
