<article
  [ngClass]="{
    'v-card-center': center,
    'v-card-clickable': clickable,
    'w-full sm:w-10/12 md:w-8/12 xl:w-4/12': center,
    'v-card-padding-sm': padding === 'sm',
    'v-card-padding-md': padding === 'md',
    'v-card-padding-lg': padding === 'lg',
    'v-card-variant-neutral': variant === 'neutral',
    'v-card-variant-glass': variant === 'glass',
    'v-card-variant-danger': variant === 'danger',
    'h-full': fullHeight,
    'w-full': fullWidth
  }">
  <header>
    <ng-content select="[slot=header]"></ng-content>
  </header>

  <section>
    <ng-content select="[slot=body]"></ng-content>
  </section>

  <footer>
    <ng-content select="[slot=footer]"></ng-content>
  </footer>
</article>
