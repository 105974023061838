<p-sidebar
  [(visible)]="sidebarVisible"
  [position]="position"
  styleClass="w-min"
  [showCloseIcon]="false">
  <ng-template pTemplate="header">
    <div class="flex items-center gap-2">
      <v-icon-button
        icon="pi pi-times"
        variant="secondary"
        size="small"
        (press)="sidebarVisible = false"></v-icon-button>
      <span class="font-bold text-lg"> {{ title }} </span>
    </div>
  </ng-template>

  <ng-content></ng-content>
</p-sidebar>
