import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  LOCAL_STORAGE_LOCALE_KEY = 'dashboard-locale';
  DEFAULT_LANGUAGE = 'en';

  constructor(private translate: TranslateService) {
    this.translate.addLangs(['en', 'de']);
    this.currentLocale = this.loadCurrentLocaleOrDefault();
  }

  get currentLocale(): string {
    return this.translate.currentLang;
  }

  set currentLocale(locale: string) {
    localStorage.setItem(this.LOCAL_STORAGE_LOCALE_KEY, locale);
    this.translate.setDefaultLang(locale);
    this.translate.use(locale);
  }

  private loadCurrentLocaleOrDefault(): string {
    return (
      localStorage.getItem(this.LOCAL_STORAGE_LOCALE_KEY) ||
      this.DEFAULT_LANGUAGE
    );
  }

  getAvailableLocales(): string[] {
    return this.translate.getLangs();
  }

  translateInstantKey(key: string, params?: any): string {
    return this.translate.instant(key, params);
  }

  translateKey(key: string, params?: any): Observable<any> {
    return this.translate.get(key, params);
  }
}
