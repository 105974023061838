import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SharedCommonModule } from 'src/app/core/common.module';
import { VKitModule } from 'src/app/core/components/ui-kit/uikit.module';
import { FeaturesState } from 'src/app/store/features/features.state';
import { FLAGS } from 'src/app/store/features/flags';

@Component({
  selector: 'app-page-maintenance',
  standalone: true,
  imports: [SharedCommonModule, VKitModule],
  templateUrl: './maintenance.component.html',
})
export class MaintenancePageComponent {
  store = inject(Store);
  router = inject(Router);

  public maintenanceEnabled$ = this.store.select(
    FeaturesState.boolFlag(FLAGS.HALO_PROTOCOL_FRONTENT)
  );

  returnToDashboard() {
    this.router.navigate(['/']);
  }
}
