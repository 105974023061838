<v-card [center]="true" *ngIf="showAlreadyLoggedIn" padding="lg">
  <v-card-image-header
    slot="header"
    [text]="'pages.register.alreadyLoggedIn' | translate"
    image="/assets/images/logos/incidite-full-white.svg"
    imageClass="!h-7"></v-card-image-header>

  <div class="flex gap-4 mt-4" slot="body">
    <v-button
      (press)="logout()"
      variant="secondary"
      [label]="'actions.logout' | translate"></v-button>
    <div class="flex-1">
      <v-button
        (press)="goToDashboard()"
        [fullWidth]="true"
        [label]="'actions.gotoDashboard' | translate"></v-button>
    </div>
  </div>
</v-card>

<v-card [center]="true" *ngIf="!showAlreadyLoggedIn" padding="lg">
  <v-card-image-header
    slot="header"
    [text]="'pages.register.header' | translate"
    image="/assets/images/logos/incidite-full-white.svg"
    imageClass="!h-7"></v-card-image-header>

  <div class="mt-4" slot="body">
    @if (disableRegistration$ | async) {
      <p-messages severity="error">
        <ng-template pTemplate>
          <div class="font-medium">Registration is currently disabled</div>
        </ng-template>
      </p-messages>
    } @else {
      <p-messages severity="info" *ngIf="isInvite">
        <ng-template pTemplate>
          <div
            [innerHTML]="
              'pages.register.joinInvite'
                | translate
                  : { organizationName: invitation?.organization?.name }
            "></div>
        </ng-template>
      </p-messages>

      <div class="vops-krator-register mt-6">
        <div class="vops-kratos-register-loading" *ngIf="initialFlowLoading">
          <p-skeleton
            height="100%"
            *ngFor="let i of [].constructor(9)"></p-skeleton>
        </div>

        <app-kratos-form
          *ngIf="registrationFlow"
          [groups]="['password', 'oidc']"
          [flow]="registrationFlow"
          (kratosFormSubmit)="submitFlow($event)"
          [loading]="isCompletingRegistrationFlow"></app-kratos-form>
      </div>

      <div class="mt-6 text-center text-gray-400">
        {{ 'pages.register.accountExists' | translate }}
        <a tabindex="0" class="font-medium" [routerLink]="['/login']">{{
          'actions.login' | translate
        }}</a>
      </div>
    }
  </div>
</v-card>
