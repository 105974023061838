import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, createModelSelector } from '@ngxs/store';
import { map } from 'rxjs';
import { SharedCommonModule } from 'src/app/core/common.module';
import { VKitModule } from 'src/app/core/components/ui-kit/uikit.module';
import { OAuth2ConsentInfo } from 'src/app/models/oauth2';
import { Entity } from 'src/app/store/common/entity';
import {
  OAuth2ConfirmConsentChallengeAction,
  OAuth2GetConsentInfoAction,
} from 'src/app/store/oauth2/oauth2.actions';
import { OAuth2State } from 'src/app/store/oauth2/oauth2.state';

interface VM {
  currentConsent: Entity<OAuth2ConsentInfo>;
  isLoading: boolean;
}

@Component({
  selector: 'app-page-oauth2-consent',
  standalone: true,
  imports: [SharedCommonModule, VKitModule],
  templateUrl: './consent.component.html',
})
export class OAuth2ConsentPageComponent implements OnInit {
  route = inject(ActivatedRoute);
  store = inject(Store);

  oAuth2ConsentChallenge?: string;

  vm$ = this.store
    .select(
      createModelSelector({
        currentConsent: OAuth2State.currentConsent,
        isLoading: OAuth2State.isAcceptingConsent,
      })
    )
    .pipe(
      map(state => {
        return {
          currentConsent: state.currentConsent,
          isLoading: state.isLoading,
        } as VM;
      })
    );

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['consent_challenge']) {
        this.oAuth2ConsentChallenge = params['consent_challenge'];
        this.store.dispatch(
          new OAuth2GetConsentInfoAction(this.oAuth2ConsentChallenge!)
        );
      }
    });
  }

  onConsent() {
    this.store.dispatch(
      new OAuth2ConfirmConsentChallengeAction(this.oAuth2ConsentChallenge!)
    );
  }
}
