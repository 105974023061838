import { Component, HostListener, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store, createModelSelector } from '@ngxs/store';
import { DialogService } from 'primeng/dynamicdialog';
import { map } from 'rxjs';
import { HeaderFormat } from 'src/app/core/components/stats-card/formatters';
import {
  VPageChangeEvent,
  VPaginationState,
} from 'src/app/core/components/ui-kit/paginator/paginator.component';
import {
  Service,
  ServiceStateToStatus,
  ServiceStateToText,
} from 'src/app/models/service';
import { Statistic } from 'src/app/models/statistics';
import { LoadServices } from 'src/app/store/services/services.actions';
import { ServicesState } from 'src/app/store/services/services.state';
import { LoadIncidentsStatisticsAction } from 'src/app/store/statistics/statistics.actions';
import { StatisticsState } from 'src/app/store/statistics/statistics.state';
import { LoadIncidents } from '../../../store/incidents/incidents.actions';
import { IncidentsState } from '../../../store/incidents/incidents.state';
import { NewIncidentPageComponent } from '../incidents/pages/new/new-incident.component';

interface StatisticsVM {
  isLoading: boolean;
  activeIncidents?: Statistic;
  resolvedIncidents?: Statistic;
  totalImpactDuration?: Statistic;
  mtta?: Statistic;
  mttr?: Statistic;
}

@Component({
  selector: 'app-page-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomePageComponent implements OnInit {
  public dialogService = inject(DialogService);
  private store = inject(Store);
  private router = inject(Router);
  public t = inject(TranslateService);

  public statCardSize: 'sm' | 'md' = 'md';

  public activeIncidentsPaginationState: VPaginationState = {
    page: 1,
    pageSize: 3,
  };

  public HeaderFormat = HeaderFormat;

  statisticsVM$ = this.store
    .select(
      createModelSelector({
        isLoading: StatisticsState.isLoading,
        activeIncidents: StatisticsState.activeIncidents,
        resolvedIncidents: StatisticsState.resolvedIncidents,
        totalImpactDuration: StatisticsState.totalImpactDuration,
        mtta: StatisticsState.mtta,
        mttr: StatisticsState.mttr,
      })
    )
    .pipe(
      map(state => {
        if (state.isLoading) {
          return {
            isLoading: true,
          } as StatisticsVM;
        } else {
          return {
            isLoading: false,
            activeIncidents: state.activeIncidents.data,
            resolvedIncidents: state.resolvedIncidents.data,
            totalImpactDuration: state.totalImpactDuration.data,
            mtta: state.mtta.data,
            mttr: state.mttr.data,
          };
        }
      })
    );

  componentsVM$ = this.store.select(ServicesState.services).pipe(
    map(entities => {
      return {
        isLoading: entities.isLoading,
        components: Object.values(entities.entities).map(c => c.data!),
      };
    })
  );

  activeIncidentsVM$ = this.store.select(IncidentsState.incidents).pipe(
    map(entities => {
      if (!entities) {
        return {
          isLoading: true,
          activeIncidents: [],
        };
      }
      return {
        isLoading: entities.isLoading,
        activeIncidents: Object.values(entities.entities).map(e => e.data!),
        pagination: entities.pagination,
      };
    })
  );

  ngOnInit(): void {
    this.store.dispatch(new LoadServices());
    this.store.dispatch(new LoadIncidentsStatisticsAction(7));
    this.setStatsCardSize();
    this.loadActiveIncidents();
  }

  newIncident() {
    this.dialogService.open(NewIncidentPageComponent, {
      header: this.t.instant('pages.createIncident.header'),
      width: '40%',
    });
  }

  setStatsCardSize() {
    if (window.innerWidth < 500) {
      this.statCardSize = 'sm';
    } else {
      this.statCardSize = 'md';
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.setStatsCardSize();
  }

  loadActiveIncidents() {
    this.store.dispatch(
      new LoadIncidents(this.activeIncidentsPaginationState, 'resolved = false')
    );
  }

  onActiveIncidentsPageChange(pagination: VPageChangeEvent) {
    this.activeIncidentsPaginationState = {
      page: pagination.page,
      pageSize: pagination.rows,
    };
    this.loadActiveIncidents();
  }

  goToService(service: Service) {
    this.router.navigate(['/services', service.id]);
  }

  ServiceStateToStatus = ServiceStateToStatus;
  ServiceStateToText = ServiceStateToText;
}
