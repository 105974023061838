import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, Optional, TemplateRef } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormGroupDirective,
} from '@angular/forms';
import { FormErrorComponent } from '../utils/form-error/form-error.component';

@Component({
  selector: 'v-input-field',
  standalone: true,
  imports: [CommonModule, FormErrorComponent],
  templateUrl: './input-field.component.html',
  styleUrl: './input-field.component.scss',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class VInputFieldComponent implements OnInit {
  /**
   * The label to display above the input
   */
  @Input()
  label: string = 'Label';

  /**
   * Optional form control name
   */
  @Input()
  fControlName?: string;

  @Input()
  headerComponent?: TemplateRef<any>;

  public control?: AbstractControl;

  constructor(
    @Optional()
    private controlContainer: ControlContainer
  ) {}

  ngOnInit(): void {
    if (this.controlContainer) {
      if (this.fControlName) {
        const control = this.controlContainer.control!.get(this.fControlName);
        if (!control) {
          console.warn(`Can't find control with name ${this.fControlName}`);
        } else {
          this.control = control;
        }
      } else {
        console.warn(
          'Missing FormControlName directive from host element of the component'
        );
      }
    } else {
      console.warn("Can't find parent FormGroup directive");
    }
  }
}
