import { inject, Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import {
  TrackGenericEventAction,
  TrackSubscriptionCanceledEventAction,
  TrackSubscriptionChangedEventAction,
  TrackSubscriptionDowngradedCanceledEventAction,
  TrackSubscriptionUnCanceledEventAction,
  TrackUserLoggedInEventAction,
  TrackUserRegisteredEventAction,
} from './tracking.actions';
import { Tracker, TrackingService } from './tracking.service';

export interface TrackingStateModel {}

@State<TrackingStateModel>({
  name: 'tracking',
  defaults: {},
})
@Injectable()
export class TrackingState {
  private trackingService = inject(TrackingService);

  @Action(TrackGenericEventAction)
  async trackEvent(
    ctx: StateContext<TrackingStateModel>,
    { event, config }: TrackGenericEventAction
  ) {
    await this.trackingService.trackEvent(event, config);
  }

  @Action(TrackUserLoggedInEventAction)
  async trackUserLoggedInEvent(
    ctx: StateContext<TrackingStateModel>,
    { identityId }: TrackUserLoggedInEventAction
  ) {
    await this.trackingService.trackEvent(
      {
        event: 'incidite_user_logged_in',
        data: {
          userId: identityId,
        },
      },
      { trackers: [Tracker.GTM] }
    );
  }

  @Action(TrackUserRegisteredEventAction)
  async trackUserRegisteredEvent(
    ctx: StateContext<TrackingStateModel>,
    { identityId }: TrackUserRegisteredEventAction
  ) {
    await this.trackingService.trackEvent(
      {
        event: 'incidite_user_registered',
        data: {
          userId: identityId,
        },
      },
      { trackers: [Tracker.GTM] }
    );
  }

  @Action(TrackSubscriptionChangedEventAction)
  async trackSubscriptionChangedEvent(
    ctx: StateContext<TrackingStateModel>,
    {
      planName,
      priceId,
      isTrial,
      discountCodes,
    }: TrackSubscriptionChangedEventAction
  ) {
    await this.trackingService.trackEvent(
      {
        event: 'incidite_subscription_changed',
        data: {
          planName: planName,
          priceId: priceId,
          isTrial: isTrial,
          discountCodes: discountCodes?.join(','),
        },
      },
      { trackers: [Tracker.GTM, Tracker.INTERCOM] }
    );
  }

  @Action(TrackSubscriptionCanceledEventAction)
  async trackSubscriptionCanceledEvent(
    ctx: StateContext<TrackingStateModel>,
    { planName }: TrackSubscriptionCanceledEventAction
  ) {
    await this.trackingService.trackEvent(
      {
        event: 'incidite_subscription_canceled',
        data: {
          planName: planName,
        },
      },
      { trackers: [Tracker.GTM, Tracker.INTERCOM] }
    );
  }

  @Action(TrackSubscriptionUnCanceledEventAction)
  async trackSubscriptionUnCanceledEvent(
    ctx: StateContext<TrackingStateModel>,
    { planName }: TrackSubscriptionUnCanceledEventAction
  ) {
    await this.trackingService.trackEvent(
      {
        event: 'incidite_subscription_uncanceled',
        data: {
          planName: planName,
        },
      },
      { trackers: [Tracker.GTM, Tracker.INTERCOM] }
    );
  }

  @Action(TrackSubscriptionDowngradedCanceledEventAction)
  async trackSubscriptionDowngradedCanceledEvent(
    ctx: StateContext<TrackingStateModel>,
    { planName }: TrackSubscriptionDowngradedCanceledEventAction
  ) {
    await this.trackingService.trackEvent(
      {
        event: 'incidite_subscription_downgrade_canceled',
        data: {
          planName: planName,
        },
      },
      { trackers: [Tracker.GTM, Tracker.INTERCOM] }
    );
  }
}
