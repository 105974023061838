<div
  class="v-sidemenu-wrapper"
  [ngClass]="{
    collapsed: collapsed
  }">
  <div class="v-sidemenu-toggle">
    <button
      (click)="_togglePinned()"
      class="v-sidemenu-toggle-pin-btn"
      [ngClass]="{
        'is-pinned': isPinned
      }">
      <i class="pi pi-thumbtack !text-xs"></i>
    </button>
    <button (click)="_toggleCollapsed()" class="v-sidemenu-toggle-btn">
      <i
        class="pi"
        [ngClass]="{
          'pi-angle-right': collapsed,
          'pi-angle-left': !collapsed
        }"></i>
    </button>
  </div>
  <aside class="v-sidemenu">
    <header>
      <img [src]="logoImage" alt="Incidite Logo" />
    </header>

    <main>
      <v-menu [items]="items" size="large" [collapsed]="collapsed"></v-menu>
    </main>

    @if (additionalContent) {
      <ng-container *ngTemplateOutlet="additionalContent"></ng-container>
    }

    <footer *ngIf="user">
      <div class="v-sidemenu-usercard">
        <img
          [src]="user.avatar"
          alt="User Image"
          [tabindex]="collapsed ? 0 : -1"
          (click)="collapsed && userMenu.toggle($event)"
          (keydown.enter)="collapsed && userMenu.toggle($event)"
          (keydown.space)="collapsed && userMenu.toggle($event)" />
        <div class="v-text-primary">
          <h3>{{ user.name }}</h3>
          <p>{{ user.email }}</p>
        </div>
        <aside #aside>
          <p-overlayPanel
            #userMenu
            [appendTo]="aside"
            position="top-center"
            styleClass="v-sidemenu-overlay">
            <div class="py-2 min-w-fit">
              <v-menu
                [items]="userMenuItems"
                size="small"
                (selected)="userMenu.hide()"></v-menu>
            </div>
          </p-overlayPanel>

          <a (click)="userMenu.toggle($event)" aria-hidden="true">
            <i class="pi pi-ellipsis-v v-text-primary"></i>
          </a>
        </aside>
      </div>

      <div class="v-sidemenu-footer">
        <div class="flex items-center">
          <i class="pi pi-building mr-1"></i>
          {{ user.selectedOrganization }}
        </div>
        <div class="mx-2">|</div>
        <a
          href="https://incidite.com/contact"
          target="_blank"
          class="text-gray-400 flex items-center">
          <i class="pi pi-question-circle mr-1 !text-sm"></i>
          Support
        </a>
      </div>
    </footer>
  </aside>
</div>
