import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MenuItem } from 'src/app/services/menu/menuItem';
import { VButtonComponent } from '../../buttons/button/button.component';
import { VMenuComponent } from '../../menu/menu.component';

@Component({
  selector: 'v-navigation-header',
  standalone: true,
  imports: [CommonModule, VButtonComponent, VMenuComponent],
  templateUrl: './navigation.component.html',
})
export class VNavigationHeaderComponent {
  @Input()
  title: string = '';

  @Input()
  subtitle: string = '';

  @Input()
  items: MenuItem[] = [];

  @Input()
  showActions = false;
}
