import { Injectable, inject } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { Statistic, mapStatistics } from 'src/app/models/statistics';

const GET_STATISTICS_QUERY = gql`
  query GetStatistics($organizationId: ID!, $days: Int!) {
    incidentsStatistics(organizationId: $organizationId, days: $days) {
      name
      unit
      datapoints {
        date
        value
      }
    }
  }
`;

@Injectable()
export class StatisticsService {
  private apollo = inject(Apollo);

  loadStatistics(
    organizationId: string,
    days: number
  ): Observable<Statistic[]> {
    return this.apollo
      .query<{ incidentsStatistics: Statistic[] }>({
        query: GET_STATISTICS_QUERY,
        variables: {
          organizationId: organizationId,
          days: days,
        },
      })
      .pipe(
        map(data => {
          return data.data.incidentsStatistics.map(s => mapStatistics(s));
        })
      );
  }
}
