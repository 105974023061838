import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'v-sso-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sso-button.component.html',
  styleUrl: './sso-button.component.scss',
})
export class VSSOButtonComponent {
  /**
   * Which variant to use
   */
  @Input()
  provider: 'google' | 'github' | 'microsoft' = 'google';

  /**
   * Whether the button should be full width
   */
  @Input()
  fullWidth: boolean = false;

  /**
   * Optional click handler
   */
  @Output()
  press = new EventEmitter<Event>();
}
