import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  inject,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { UserState } from 'src/app/store/user/user.state';

@Directive({
  selector: '[vRequiredRoles]',
})
export class CheckRoleDirective implements OnInit, OnDestroy {
  private store = inject(Store);

  private renderer = inject(Renderer2);

  @Input('vRequiredRoles') roles: string[] = [];
  @Input() permissionUIMode: 'disable' | 'hide' = 'hide';

  private onDestroy$ = new Subject<boolean>();

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.store
      .select(UserState.checkPermissionRole(this.roles))
      .subscribe(hasPermission => {
        if (this.permissionUIMode === 'disable') {
          this.setUiModeDisabled(hasPermission);
        } else if (this.permissionUIMode === 'hide') {
          this.setUiModeHidden(hasPermission);
        }
      });
  }

  setUiModeDisabled(hasPermission: boolean) {
    if (hasPermission) {
      this.renderer.removeAttribute(this.elementRef.nativeElement, 'disabled');
    } else {
      this.renderer.setAttribute(
        this.elementRef.nativeElement,
        'disabled',
        'true'
      );
    }
  }

  setUiModeHidden(hasPermission: boolean) {
    if (hasPermission) {
      this.renderer.setStyle(
        this.elementRef.nativeElement,
        'visibility',
        'visible'
      );
    } else {
      this.renderer.setStyle(
        this.elementRef.nativeElement,
        'visibility',
        'hidden'
      );
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }
}
