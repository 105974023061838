import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { SharedCommonModule } from 'src/app/core/common.module';
import { VButtonComponent } from 'src/app/core/components/ui-kit/buttons/button/button.component';
import { VKitCardModule } from 'src/app/core/components/ui-kit/card/card.module';
import { VInputComponent } from 'src/app/core/components/ui-kit/input/input.component';
import { LoadUserAction, LogoutAction } from 'src/app/store/user/user.actions';

const CREATE_ORGANIZATION_MUTATION = gql`
  mutation CreateOrganization($name: String!) {
    createOrganization(newOrganization: { name: $name }) {
      id
    }
  }
`;

@Component({
  selector: 'app-page-new-organization',
  standalone: true,
  imports: [
    SharedCommonModule,
    VKitCardModule,
    VInputComponent,
    VButtonComponent,
  ],
  templateUrl: './new-organization.component.html',
})
export class NewOrganizationPageComponent {
  private apollo = inject(Apollo);
  private router = inject(Router);
  private store = inject(Store);

  newOrgForm = new FormGroup({
    organizationName: new FormControl('', Validators.required),
  });

  createOrganizationError?: string;

  onFormSubmit() {
    if (!this.newOrgForm.valid) return;

    this.createOrganizationError = undefined;
    this.newOrgForm.disable();

    this.router.navigate(['/dashboard']);

    this.apollo
      .mutate({
        mutation: CREATE_ORGANIZATION_MUTATION,
        variables: {
          name: this.newOrgForm.value.organizationName,
        },
      })
      .subscribe({
        next: () => {
          // reload user
          this.store.dispatch(new LoadUserAction()).subscribe({
            complete: () => {
              this.router.navigate(['/']);
            },
          });
        },
        error: err => {
          this.createOrganizationError = err.toString();
          this.newOrgForm.enable();
        },
      });
  }

  logout() {
    this.store.dispatch(new LogoutAction());
  }
}
