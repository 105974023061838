<fieldset>
  <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
    @for (option of options; track option.value) {
      <label
        [attr.aria-label]="option.label"
        class="relative flex rounded-lg border-2 border-white/20 border-primary/20 p-4 shadow-sm focus:outline-primary focus:outline-2"
        (click)="setValue(option)"
        (keydown.enter)="setValue(option)"
        tabindex="0"
        [ngClass]="{
          'cursor-pointer': !option.disabled,
          'select-none opacity-50': option.disabled,
          'bg-card': variant === 'neutral',
          'bg-gray-900/20': variant === 'dark',
          '!border-transparent ring-2 ring-primary !outline-none !bg-primary/10':
            currentValue === option.value
        }">
        <input
          type="radio"
          [name]="fControlName"
          [value]="option.value"
          class="sr-only" />
        <span class="flex flex-1">
          <span class="flex flex-col">
            <div class="flex items-center">
              <i *ngIf="option.icon" [class]="'mr-2 ' + option.icon"></i>
              <span class="block font-medium text-primary">{{
                option.label
              }}</span>
            </div>
            <span class="mt-1 flex items-center text-sm text-subtle">{{
              option.description
            }}</span>
          </span>
        </span>
        <svg
          *ngIf="currentValue === option.value"
          class="h-5 w-5 text-primary-inverted"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true">
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
            clip-rule="evenodd" />
        </svg>
      </label>
    }
  </div>
</fieldset>
