import { inject, Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import {
  mapGQLResponse,
  toIdentifiableEntities,
} from 'src/app/helpers/graphql';
import {
  IncidentSchema,
  IncidentSchemaVersion,
} from 'src/app/models/incident-schema';
import { IdentifiableEntities } from 'src/app/store/common/list-entity';

export const LIST_INCIDENT_SCHEMAS_QUERY = gql`
  query ListIncidentSchemas($organizationId: ID!) {
    incidentSchemas(organizationId: $organizationId) {
      schemas {
        id
        name
        type
        currentVersion {
          id
          version
        }
      }
    }
  }
`;

export const GET_INCIDENT_SCHEMA_QUERY = gql`
  query GetIncidentSchemas($organizationId: ID!, $schemaId: ID!) {
    incidentSchema(organizationId: $organizationId, schemaId: $schemaId) {
      id
      name
      type
      currentVersion {
        id
        version
        states {
          id
          name
          order
          color
          icon
          isDefault
          isResolved
        }
        priorities {
          id
          name
          order
          color
          icon
        }
      }
    }
  }
`;

export const GET_INCIDENT_SCHEMA_VERSION_QUERY = gql`
  query GetIncidentSchemaVersion($organizationId: ID!, $schameVersionId: ID!) {
    incidentSchemaVersion(
      organizationId: $organizationId
      schemaVersionId: $schameVersionId
    ) {
      id
      version
      states {
        id
        name
        order
        color
        icon
        isDefault
        isResolved
      }
      priorities {
        id
        name
        order
        color
        icon
      }
    }
  }
`;

@Injectable()
export class IncidentSchemasService {
  private apollo = inject(Apollo);

  listIncidentSchemas(
    organizationId: string
  ): Observable<IdentifiableEntities<IncidentSchema>> {
    return this.apollo
      .query<{ incidentSchemas: { schemas: IncidentSchema[] } }>({
        query: LIST_INCIDENT_SCHEMAS_QUERY,
        variables: {
          organizationId: organizationId,
        },
      })
      .pipe(
        map(data => {
          return toIdentifiableEntities(
            data.data.incidentSchemas.schemas.map(schema => {
              return {
                ...schema,
                createdAt: new Date(
                  Date.parse(schema.createdAt as unknown as string)
                ),
              };
            }),
            'id'
          );
        })
      );
  }

  getIncidentSchema(
    organizationId: string,
    schemaId: string
  ): Observable<IncidentSchema> {
    return this.apollo
      .query<{ incidentSchema: IncidentSchema }>({
        query: GET_INCIDENT_SCHEMA_QUERY,
        variables: {
          organizationId: organizationId,
          schemaId: schemaId,
        },
      })
      .pipe(
        map(data => {
          return data.data.incidentSchema;
        })
      );
  }

  getIncidentSchemaVersion(
    organizationId: string,
    schemaVersionId: string
  ): Observable<IncidentSchemaVersion> {
    return this.apollo
      .query<{ incidentSchemaVersion: IncidentSchemaVersion }>({
        query: GET_INCIDENT_SCHEMA_VERSION_QUERY,
        variables: {
          organizationId: organizationId,
          schameVersionId: schemaVersionId,
        },
      })
      .pipe(
        map(data => {
          return mapGQLResponse<{
            incidentSchemaVersion: IncidentSchemaVersion;
          }>(data, 'incidentSchemaVersion');
        })
      );
  }
}
