<article class="v-card-bg p-3 rounded-lg">
  <header
    class="flex items-center justify-between cursor-pointer"
    [routerLink]="['/incidents/', incident?.id]">
    <div class="flex items-center overflow-hidden">
      <app-priority [priority]="incident?.priority!"></app-priority>
      <h4 class="p-0 m-0 ml-2 v-text-primary truncate font-medium">
        {{ incident?.title }}
      </h4>
    </div>
    <div>
      <app-incident-state [state]="incident?.state!"></app-incident-state>
    </div>
  </header>
  <footer
    class="flex border-top-1 border-white-alpha-10 pt-2 mt-3 justify-between">
    <article class="flex flex-1 flex-col">
      <header class="text-md text-gray-100 pb-2 font-medium">
        {{ 'components.activeIncidents.affectedServices' | translate }}
      </header>

      <div class="flex gap-2">
        @for (service of incident?.affectedServices; track service.service.id) {
          <div class="my-auto flex gap-2">
            <div class="bg-gray-700 px-2 py-1 rounded-lg text-sm font-medium">
              {{ service.service.name }}
            </div>
          </div>
        } @empty {
          <span class="text-gray-200">{{
            'components.activeIncidents.noAffectedServices' | translate
          }}</span>
        }
      </div>
    </article>
    <div class="flex gap-4">
      <article class="flex flex-1 flex-col">
        <header class="text-md text-gray-100 pb-2 font-medium">
          {{ 'components.activeIncidents.activeSince' | translate }}
        </header>
        <div class="my-auto">
          <div class="text-sm font-medium v-text-primary">
            {{ incident?.createdAt | intlRelativeTime: { numeric: 'auto' } }}
          </div>
        </div>
      </article>
      <article class="flex flex-col">
        <header class="text-md text-gray-100 pb-2 font-medium">
          {{ 'components.activeIncidents.statuspages' | translate }}
        </header>
        <div class="flex gap-1">
          @for (sp of incident?.statuspageLinks; track sp.statuspage.id) {
            <app-chip
              [link]="'https://' + sp.statuspage.domain"
              icon="pi-link"
              [label]="sp.statuspage.name"></app-chip>
          } @empty {
            <span class="text-gray-200">{{
              'components.activeIncidents.noStatuspagesLinked' | translate
            }}</span>
          }
        </div>
      </article>
    </div>
  </footer>
</article>
