import { Feature } from '../models/subscription-plan';

export const HasQuota = (feature: Feature): boolean => {
  if (!feature.quota) {
    return true;
  }

  if (feature.quota.quota === 0) {
    return false;
  }

  if (!feature.usage) {
    return true;
  }

  return feature.quota.quota > feature.usage.value;
};
