import { Injectable, inject } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { mapGQLResponse } from 'src/app/helpers/graphql';
import { User } from 'src/app/models/user';
import { ME_QUERY } from './user.queries';

@Injectable()
export class UserService {
  private apollo = inject(Apollo);

  /**
   * loadUser fetches the user currently authenticated by kratos using its kratos session cookie
   * @param organizationId an optional organization id to fetch permission for
   * @returns
   */
  loadUser(): Observable<User> {
    return this.apollo
      .query<{ me: User }>({
        query: ME_QUERY,
      })
      .pipe(
        map(data => {
          const user = mapGQLResponse(data, 'me');
          user.createdAt = new Date(user.createdAt);

          return user;
        })
      );
  }
}
