import { Injectable, inject } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import {
  mapGQLResponse,
  toIdentifiableEntities,
} from 'src/app/helpers/graphql';
import {
  Statuspage,
  StatuspageComponent,
  StatuspageComponentSpec,
  StatuspageComponentUpdateSpec,
  StatuspageSubscription,
  StatuspageVisibility,
  UpdateStatuspageSpec,
} from 'src/app/models/statuspage';
import {
  OffsetPaginatedRespose,
  OffsetPaginationOptions,
} from 'src/app/store/common/pagination';

const GET_STATUSPAGES_QUERY = gql`
  query GetStatuspages($organizationUUID: ID!) {
    statuspages(organizationUUID: $organizationUUID) {
      totalSize
      statuspages {
        id
        name
        slug
        domain
        visibility
        createdAt
        updatedAt
      }
    }
  }
`;

const GET_STATUSPAGE_QUERY = gql`
  query GetStatuspage($organizationUUID: ID!, $statuspageId: Int!) {
    statuspage(
      organizationUUID: $organizationUUID
      statuspageId: $statuspageId
    ) {
      id
      name
      slug
      customDomain
      domain
      visibility
      createdAt
      updatedAt
      components {
        id
        description
        component {
          ... on StatuspageComponentPayloadService {
            service {
              id
              name
              state
              description
            }
          }
        }
      }
      whitelabelConfig {
        colors {
          siteBackground
          sitePrimary
          siteCard
          siteOperational
          siteTextOperational
          siteDegraded
          siteTextDegraded
          siteDown
          siteTextDown
          siteText
          siteTextOnPrimary
          siteTextSubtle
        }
        logoUrl
        showPoweredBy
      }
    }
  }
`;

const CREATE_STATUSPAGE_MUTATION = gql`
  mutation CreateStatuspageMutation(
    $organizationUUID: ID!
    $createSpec: CreateStatuspageSpec!
  ) {
    createStatuspage(
      organizationUUID: $organizationUUID
      createSpec: $createSpec
    ) {
      id
      name
      slug
      domain
      visibility
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_STATUSPAGE_MUTATION = gql`
  mutation UpdateStatuspageMutation(
    $organizationUUID: ID!
    $statuspageId: Int!
    $updateSpec: UpdateStatuspageSpec!
  ) {
    updateStatuspage(
      organizationUUID: $organizationUUID
      statuspageId: $statuspageId
      updateSpec: $updateSpec
    ) {
      id
      name
      slug
      customDomain
      domain
      visibility
      createdAt
      updatedAt
    }
  }
`;

const DELETE_STATUSPAGE_MUTATION = gql`
  mutation DeleteStatuspageMutation(
    $organizationUUID: ID!
    $statuspageId: Int!
  ) {
    deleteStatuspage(
      organizationUUID: $organizationUUID
      statuspageId: $statuspageId
    )
  }
`;

const CREATE_STATUSPAGE_COMPONENT_MUTATION = gql`
  mutation CreateStatuspageComponentMutation(
    $organizationUUID: ID!
    $statuspageId: Int!
    $componentSpec: StatuspageComponentSpec!
  ) {
    createStatuspageComponent(
      organizationUUID: $organizationUUID
      statuspageId: $statuspageId
      componentSpec: $componentSpec
    ) {
      id
      position
      description
      type
      component {
        ... on StatuspageComponentPayloadService {
          service {
            id
            name
            state
            description
          }
        }
      }
    }
  }
`;

const UPDATE_STATUSPAGE_COMPONENT_MUTATION = gql`
  mutation UpdateStatuspageComponentMutation(
    $organizationUUID: ID!
    $statuspageId: Int!
    $componentId: ID!
    $updateSpec: StatuspageComponentUpdateSpec!
  ) {
    updateStatuspageComponent(
      organizationUUID: $organizationUUID
      statuspageId: $statuspageId
      componentId: $componentId
      updateSpec: $updateSpec
    ) {
      description
    }
  }
`;

const REMOVE_STATUSPAGE_COMPONENT_MUTATION = gql`
  mutation RemoveStatuspageComponentMutation(
    $organizationUUID: ID!
    $statuspageId: Int!
    $componentId: ID!
  ) {
    removeStatuspageComponent(
      organizationUUID: $organizationUUID
      statuspageId: $statuspageId
      componentId: $componentId
    )
  }
`;

const ORDER_STATUSPAGE_COMPONENTS_MUTATION = gql`
  mutation OrderStatuspageComponentsMutation(
    $organizationUUID: ID!
    $statuspageId: Int!
    $orderedComponentIds: [ID!]!
  ) {
    orderStatuspageComponents(
      organizationUUID: $organizationUUID
      statuspageId: $statuspageId
      orderedComponentIds: $orderedComponentIds
    )
  }
`;

const GET_SUBSCRIPTIONS_QUERY = gql`
  query GetSubscriptions(
    $organizationId: ID!
    $statuspageId: Int!
    $options: ListSubscriptionsOptions
  ) {
    statuspageSubscriptions(
      organizationId: $organizationId
      statuspageId: $statuspageId
      options: $options
    ) {
      totalSize
      subscriptions {
        id
        manageToken
        endpoints {
          providerType
          doiConfirmed
          doiConfirmedAt
          config {
            ... on ProviderAddressMetadata {
              address
            }
          }
        }
        createdAt
      }
    }
  }
`;

const UNSUBSCRIBE_FROM_STATUSPAGE_MUTATION = gql`
  mutation UnsubscribeFromStatuspage(
    $statuspageSlug: String!
    $manageToken: String!
  ) {
    unsubscribeFromStatuspage(
      statuspageSlug: $statuspageSlug
      manageToken: $manageToken
    )
  }
`;

@Injectable()
export class StatuspageService {
  private apollo = inject(Apollo);

  loadStatuspages(
    organizationId: string
  ): Observable<OffsetPaginatedRespose<Statuspage>> {
    return this.apollo
      .query<{ statuspages: { statuspages: Statuspage[]; totalSize: number } }>(
        {
          query: GET_STATUSPAGES_QUERY,
          variables: {
            organizationUUID: organizationId,
          },
        }
      )
      .pipe(
        map(data => {
          return {
            totalSize: data.data.statuspages.totalSize,
            items: toIdentifiableEntities(
              data.data.statuspages.statuspages,
              'id'
            ),
          };
        })
      );
  }

  loadStatuspage(
    organizationId: string,
    statuspageId: number
  ): Observable<Statuspage> {
    return this.apollo
      .query<{ statuspage: Statuspage }>({
        query: GET_STATUSPAGE_QUERY,
        variables: {
          organizationUUID: organizationId,
          statuspageId: statuspageId,
        },
      })
      .pipe(
        map(data => {
          return data.data.statuspage;
        })
      );
  }

  createStatuspage(
    organizationId: string,
    spec: {
      name: string;
      visibility: StatuspageVisibility;
    }
  ): Observable<Statuspage> {
    return this.apollo
      .mutate<{ createStatuspage: Statuspage }>({
        mutation: CREATE_STATUSPAGE_MUTATION,
        variables: {
          organizationUUID: organizationId,
          createSpec: {
            name: spec.name,
            visibility: spec.visibility,
          },
        },
      })
      .pipe(
        map(data => {
          return mapGQLResponse<{ createStatuspage: Statuspage }>(
            data,
            'createStatuspage'
          );
        })
      );
  }

  updateStatuspage(
    organizationId: string,
    statuspageId: number,
    spec: UpdateStatuspageSpec
  ): Observable<Statuspage> {
    return this.apollo
      .mutate<{ updateStatuspage: Statuspage }>({
        mutation: UPDATE_STATUSPAGE_MUTATION,
        variables: {
          organizationUUID: organizationId,
          statuspageId: statuspageId,
          updateSpec: spec,
        },
      })
      .pipe(
        map(data => {
          return mapGQLResponse<{ updateStatuspage: Statuspage }>(
            data,
            'updateStatuspage'
          );
        })
      );
  }

  deleteStatuspage(
    organizationId: string,
    statuspageId: number
  ): Observable<boolean> {
    return this.apollo
      .mutate<{ deleteStatuspage: boolean }>({
        mutation: DELETE_STATUSPAGE_MUTATION,
        variables: {
          organizationUUID: organizationId,
          statuspageId: statuspageId,
        },
      })
      .pipe(
        map(data => {
          return mapGQLResponse<{ deleteStatuspage: boolean }>(
            data,
            'deleteStatuspage'
          );
        })
      );
  }

  createStatuspageComponent(
    organizationId: string,
    statuspageId: number,
    componentSpec: StatuspageComponentSpec
  ): Observable<StatuspageComponent> {
    return this.apollo
      .mutate<{ createStatuspageComponent: StatuspageComponent }>({
        mutation: CREATE_STATUSPAGE_COMPONENT_MUTATION,
        variables: {
          organizationUUID: organizationId,
          statuspageId: statuspageId,
          componentSpec: componentSpec,
        },
      })
      .pipe(
        map(data => {
          return mapGQLResponse<{
            createStatuspageComponent: StatuspageComponent;
          }>(data, 'createStatuspageComponent');
        })
      );
  }

  updateStatuspageComponent(
    organizationId: string,
    statuspageId: number,
    componentId: string,
    updateSpec: StatuspageComponentUpdateSpec
  ): Observable<StatuspageComponent> {
    return this.apollo
      .mutate<{ updateStatuspageComponent: StatuspageComponent }>({
        mutation: UPDATE_STATUSPAGE_COMPONENT_MUTATION,
        variables: {
          organizationUUID: organizationId,
          statuspageId: statuspageId,
          componentId: componentId,
          updateSpec: updateSpec,
        },
      })
      .pipe(
        map(data => {
          return mapGQLResponse<{
            updateStatuspageComponent: StatuspageComponent;
          }>(data, 'updateStatuspageComponent');
        })
      );
  }

  deleteStatuspageComponent(
    organizationId: string,
    statuspageId: number,
    componentId: string
  ): Observable<boolean> {
    return this.apollo
      .mutate<{ removeStatuspageComponent: boolean }>({
        mutation: REMOVE_STATUSPAGE_COMPONENT_MUTATION,
        variables: {
          organizationUUID: organizationId,
          statuspageId: statuspageId,
          componentId: componentId,
        },
      })
      .pipe(
        map(data => {
          return mapGQLResponse<{
            removeStatuspageComponent: boolean;
          }>(data, 'removeStatuspageComponent');
        })
      );
  }

  orderStatuspageComponents(
    organizationId: string,
    statuspageId: number,
    orderedComponentIds: string[]
  ): Observable<boolean> {
    return this.apollo
      .mutate<{ orderStatuspageComponents: boolean }>({
        mutation: ORDER_STATUSPAGE_COMPONENTS_MUTATION,
        variables: {
          organizationUUID: organizationId,
          statuspageId: statuspageId,
          orderedComponentIds: orderedComponentIds,
        },
      })
      .pipe(
        map(data => {
          return mapGQLResponse<{
            orderStatuspageComponents: boolean;
          }>(data, 'orderStatuspageComponents');
        })
      );
  }

  loadSubscriptions(
    organizationId: string,
    statuspageId: number,
    pagination: OffsetPaginationOptions
  ): Observable<OffsetPaginatedRespose<StatuspageSubscription>> {
    return this.apollo
      .query<{
        statuspageSubscriptions: {
          subscriptions: StatuspageSubscription[];
          totalSize: number;
        };
      }>({
        query: GET_SUBSCRIPTIONS_QUERY,
        variables: {
          organizationId: organizationId,
          statuspageId: statuspageId,
          options: pagination,
        },
      })
      .pipe(
        map(data => {
          return {
            totalSize: data.data.statuspageSubscriptions.totalSize,
            items: toIdentifiableEntities(
              data.data.statuspageSubscriptions.subscriptions,
              'id'
            ),
          };
        })
      );
  }

  unsubscribeFromStatuspage(
    statuspageSlug: string,
    manageToken: string
  ): Observable<boolean> {
    return this.apollo
      .mutate<{ unsubscribeFromStatuspage: boolean }>({
        mutation: UNSUBSCRIBE_FROM_STATUSPAGE_MUTATION,
        variables: {
          statuspageSlug: statuspageSlug,
          manageToken: manageToken,
        },
      })
      .pipe(
        map(data => {
          return mapGQLResponse<{
            unsubscribeFromStatuspage: boolean;
          }>(data, 'unsubscribeFromStatuspage');
        })
      );
  }
}
