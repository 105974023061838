import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { TrackingService } from './tracking.service';
import { TrackingState } from './tracking.state';

@NgModule({
  declarations: [],
  imports: [NgxsModule.forFeature([TrackingState])],
  providers: [TrackingService],
  exports: [],
})
export class TrackingStateModule {}
