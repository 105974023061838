<div
  class="vops-message"
  [ngClass]="{
    'severity-neutral': severity === 'neutral',
    'severity-info': severity === 'info',
    'severity-warning': severity === 'warning',
    'severity-danger': severity === 'danger',
    'severity-success': severity === 'success'
  }">
  <ng-content></ng-content>
</div>
