<ul class="list-none m-0 p-0 flex items-center font-medium">
  <li class="pr-3">
    <a [routerLink]="['dashboard']" class="cursor-pointer">
      <i
        class="pi pi-home hover:text-white text-gray-300"
        [routerLink]="['dashboard']"></i>
    </a>
  </li>
  <ng-container
    *ngFor="let breadcrumb of menuService.getBreadcrumbs(); let last = last">
    <li class="px-2">
      <i class="pi pi-angle-right text-subtle"></i>
    </li>
    <li class="px-2">
      <div class="w-36 h-5" *ngIf="breadcrumb.loading">
        <p-skeleton
          width="10rem"
          height="1.5rem"
          borderRadius="1rem"></p-skeleton>
      </div>
      <a
        *ngIf="!breadcrumb.loading"
        [routerLink]="!last ? breadcrumb.routerLink : undefined"
        [ngClass]="{
          'hover:text-white cursor-pointer': !last,
          'hover:text-subtle': last
        }"
        class="text-subtle white-space-nowrap no-underline">
        {{ breadcrumb.label | translate }}
      </a>
    </li>
  </ng-container>
</ul>
