import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store, createModelSelector } from '@ngxs/store';
import { Observable, map } from 'rxjs';
import { SubscriptionStatus } from 'src/app/models/subscription-plan';
import { MenuService } from 'src/app/services/menu/menu.service';
import { MenuItem } from 'src/app/services/menu/menuItem';
import { MiscState } from 'src/app/store/misc/misc.state';
import { OrganizationDetailsState } from 'src/app/store/organization/organization.state';
import { SubscriptionState } from 'src/app/store/subscription/subscription.state';
import { UpdateUISettingAction } from 'src/app/store/ui-settings/ui-settings.actions';
import { UISettingsState } from 'src/app/store/ui-settings/ui-settings.state';
import {
  LogoutAction,
  SelectOrganizationAction,
} from 'src/app/store/user/user.actions';
import { UserState } from 'src/app/store/user/user.state';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  private store = inject(Store);
  public menuService = inject(MenuService);
  private router = inject(Router);

  @Select(MiscState.getRealtimeConnectionState)
  connectionState$!: Observable<string>;

  public subscription$ = this.store.select(SubscriptionState.subscription);

  public isCollapsed$ = this.store.select(
    UISettingsState.setting('isSidemenuCollapsed')
  );

  public isPinned$ = this.store.select(
    UISettingsState.setting('isSidemenuPinned')
  );

  SubscriptionStatus = SubscriptionStatus;

  public user$ = this.store
    .select(
      createModelSelector({
        user: UserState.getUser,
        selectedOrganization: OrganizationDetailsState.organization,
      })
    )
    .pipe(
      map(({ user, selectedOrganization }) => {
        if (!user) return;

        return {
          avatar: user?.avatar,
          name: user?.firstname + ' ' + user?.lastname,
          email: user?.email,
          selectedOrganization: selectedOrganization.data?.name || '',
        };
      })
    );

  userMenuItems$: Observable<MenuItem[]> = this.store
    .select(
      createModelSelector({
        user: UserState.getUser,
        currentOrg: UserState.getCurrentOrganizationID,
      })
    )
    .pipe(
      map(({ user, currentOrg }) => {
        const organizations: MenuItem[] =
          user && user.memberships.length > 1
            ? user?.memberships.map(membership => ({
                label:
                  membership.organizationId === currentOrg
                    ? membership.organization.name + ' (current)'
                    : membership.organization.name,
                icon: 'pi pi-building',
                onClicked: () => {
                  this.store.dispatch(
                    new SelectOrganizationAction(membership.organizationId)
                  );
                  window.location.reload();
                },
              }))
            : [];

        return [
          {
            label: 'menu.settings',
            icon: 'pi pi-user',
            routerLink: '/settings/account/general',
          },
          ...organizations,
          {
            label: 'actions.logout',
            icon: 'pi pi-sign-out',
            onClicked: () => {
              this.store.dispatch(new LogoutAction());
            },
          },
        ] as MenuItem[];
      })
    );

  goToSubscription() {
    this.router.navigate(['/settings/organization/subscription/upgrade']);
  }

  toggleCollapsed() {
    const isCollapsed = this.store.selectSnapshot(
      UISettingsState.setting('isSidemenuCollapsed')
    );

    this.store.dispatch(
      new UpdateUISettingAction('isSidemenuCollapsed', !isCollapsed)
    );
  }

  togglePinned() {
    const isPinned = this.store.selectSnapshot(
      UISettingsState.setting('isSidemenuPinned')
    );

    this.store.dispatch(
      new UpdateUISettingAction('isSidemenuPinned', !isPinned)
    );
  }
}
