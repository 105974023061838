import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { FeaturesService } from './features.service';
import { FeaturesState } from './features.state';

@NgModule({
  declarations: [],
  imports: [NgxsModule.forFeature([FeaturesState])],
  providers: [FeaturesService],
  exports: [],
})
export class FeaturesStateModule {}
