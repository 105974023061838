import {
  animate,
  AnimationEvent,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DialogRef } from '@angular/cdk/dialog';
import { Component, inject } from '@angular/core';

@Component({
  selector: 'v-dialog-wrapper',
  templateUrl: './wrapper.component.html',
  animations: [
    trigger('dialogBackdropAnimation', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 0.4 })),
      transition('void => *', animate('175ms ease-out')),
      transition('* => void', animate('125ms ease-in')),
    ]),
    trigger('dialogContainerAnimation', [
      transition('void => *', [
        animate(
          '250ms ease-out',
          keyframes([
            style({ transform: 'scale(0.75)', opacity: 0, offset: 0 }),
            style({ transform: 'scale(1.1)', opacity: 1, offset: 0.6 }),
            style({ transform: 'scale(1)', opacity: 1, offset: 1 }),
          ])
        ),
      ]),
      transition('* => void', [
        animate(
          '125ms ease-in',
          keyframes([
            style({ transform: 'scale(1)', opacity: 1, offset: 0 }),
            style({ transform: 'scale(0.75)', opacity: 0, offset: 1 }),
          ])
        ),
      ]),
    ]),
  ],
})
export class VDialogWrapperComponent {
  private dialogRef = inject(DialogRef);
  isVisible = true;
  private result: any;

  animationDone(event: AnimationEvent) {
    if (event.toState === 'void' && event.phaseName === 'done') {
      this.dialogRef.close(this.result);
    }
  }

  close(result: any) {
    this.result = result;
    this.isVisible = false;
  }
}
