import { Component, OnInit, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { MenuItem } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { KratosAuthenticationService } from 'src/app/services/authentication/kratos.service';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { LogoutAction } from 'src/app/store/user/user.actions';
import { UserState } from 'src/app/store/user/user.state';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
})
export class TopBarComponent implements OnInit {
  menuItems: MenuItem[] = [];

  private store = inject(Store);

  public user$ = this.store.select(UserState.getUser);

  constructor(
    public authService: KratosAuthenticationService,
    private translate: TranslationService
  ) {}

  async ngOnInit() {
    this.menuItems = [
      {
        label: await firstValueFrom(
          this.translate.translateKey('menu.settings')
        ),
        icon: 'pi pi-user',
        routerLink: '/settings/account/general',
      },
      {
        label: await firstValueFrom(
          this.translate.translateKey('actions.logout')
        ),
        icon: 'pi pi-sign-out',
        command: () => {
          this.store.dispatch(new LogoutAction());
        },
      },
    ];
  }
}
