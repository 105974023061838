import { UISettingsStateModel } from './ui-settings.state';

export class UpdateUISettingAction<K extends keyof UISettingsStateModel> {
  static readonly type = '[UISettings] Toggle Setting';

  constructor(
    public setting: K,
    public value: UISettingsStateModel[K]
  ) {}
}
