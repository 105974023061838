<div *ngIf="kratosForm" class="vops-kratos-form">
  <form [formGroup]="kratosForm" (ngSubmit)="submit()">
    <!-- Flow nodes -->
    <ng-container *ngFor="let node of getVisibleNodes(uiNodes)">
      <div class="vops-kratos-form-input-row">
        <!-- Input fields-->
        <ng-container *ngTemplateOutlet="inputNode; context: { node: node }">
        </ng-container>

        <!-- Messages (e.g. error)-->
        <ng-container *ngIf="node.messages.length > 0">
          <ng-container
            *ngTemplateOutlet="messages; context: { messages: node.messages }">
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <!-- Form Messages -->
    <ng-container *ngIf="formMessages.length > 0">
      <div class="mt-2 vops-kratos-form-messages">
        <ng-container
          *ngTemplateOutlet="messages; context: { messages: formMessages }">
        </ng-container>
      </div>
    </ng-container>

    <!-- Other Buttons -->
    <ng-container
      *ngFor="
        let node of filterUINodes({
          nodes: uiNodes,
          withoutDefaultAttributes: true,
          groups: groups,
          attributes: 'button'
        })
      ">
      <v-button
        [fullWidth]="true"
        size="large"
        [label]="getUiNodeLabel(node) | translate"
        [loading]="loading"
        [testId]="getTestId(node)"
        (press)="onBtnClick(node)"></v-button>
    </ng-container>

    <!-- Submit Button -->
    <ng-container
      *ngFor="
        let node of filterUINodes({
          nodes: uiNodes,
          withoutDefaultAttributes: true,
          groups: excludeGroups(groups, ['oidc']),
          attributes: 'submit'
        })
      ">
      <v-button
        [fullWidth]="true"
        [label]="getUiNodeLabel(node) | translate"
        type="submit"
        size="large"
        [disabled]="!kratosForm.valid"
        [loading]="loading"
        [testId]="getTestId(node)"
        class="w-full"></v-button>
    </ng-container>

    <!-- SSO -->
    @if (showOIDCGroups()) {
      <div class="flex flex-wrap justify-between gap-3">
        <ng-container
          *ngFor="
            let node of filterUINodes({
              nodes: uiNodes,
              withoutDefaultAttributes: true,
              groups: ['oidc'],
              attributes: 'submit'
            })
          ">
          @if (getOIDCProvider(node) === 'google') {
            <div class="flex-1">
              <v-sso-button
                provider="google"
                [fullWidth]="true"
                (press)="onBtnClick(node)"></v-sso-button>
            </div>
          }
          @if (getOIDCProvider(node) === 'github') {
            <div class="flex-1">
              <v-sso-button
                provider="github"
                [fullWidth]="true"
                (press)="onBtnClick(node)"></v-sso-button>
            </div>
          }
          @if (getOIDCProvider(node) === 'microsoft') {
            <div class="flex-1">
              <v-sso-button
                provider="microsoft"
                [fullWidth]="true"
                (press)="onBtnClick(node)"></v-sso-button>
            </div>
          }
        </ng-container>
      </div>
    }
  </form>
</div>

<ng-template #messages let-messages="messages">
  <div
    *ngIf="messages.length > 0"
    class="vops-kratos-messages"
    [ngClass]="{
      'kratos-info-message': messages[0].type === 'info',
      'kratos-error-message': messages[0].type === 'error'
    }">
    <ul>
      <li *ngFor="let message of messages">
        {{ message.text }}
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #inputNode let-node="node">
  <!-- Default input node -->
  <ng-container *ngIf="isInputNode(node)" [formGroup]="kratosForm!">
    <!--<label [htmlFor]="node.attributes.name">
      {{ getUiNodeLabel(node) | translate }}
    </label>-->

    <v-input-field
      [label]="getUiNodeLabel(node) | translate"
      [fControlName]="node.attributes.name">
      <v-input
        [type]="node.attributes.type"
        [placeholder]="getUiNodeLabel(node) | translate"
        [testId]="testIdPrefix + node.attributes.name"
        [fControlName]="node.attributes.name"></v-input>
    </v-input-field>

    <!--<input
      [placeholder]="getUiNodeLabel(node) | translate"
      [id]="node.attributes.name"
      [type]="node.attributes.type"
      [name]="node.attributes.name"
      [formControlName]="node.attributes.name"
      [autocomplete]="node.attributes.autocomplete"
      [required]="node.attributes.required"
    />!-->
  </ng-container>

  <ng-container *ngIf="isImageNode(node)">
    <label [htmlFor]="node.attributes.name">
      {{ getUiNodeLabel(node) | translate }}
    </label>

    <img
      [src]="node.attributes.src"
      [width]="node.attributes.width"
      [height]="node.attributes.height"
      alt="" />
  </ng-container>

  <ng-container *ngIf="isTextNode(node)">
    <label [htmlFor]="node.attributes.name">
      {{ getUiNodeLabel(node) | translate }}
    </label>

    <span class="font-bold">{{ node.attributes.text.text }}</span>
  </ng-container>
</ng-template>
