import { Component, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SharedCommonModule } from 'src/app/core/common.module';
import { VButtonComponent } from 'src/app/core/components/ui-kit/buttons/button/button.component';
import { VKitCardModule } from 'src/app/core/components/ui-kit/card/card.module';
import { VInputComponent } from 'src/app/core/components/ui-kit/input/input.component';
import { KratosAuthenticationService } from 'src/app/services/authentication/kratos.service';
import { LoadUserAction } from 'src/app/store/user/user.actions';

@Component({
  selector: 'app-verification',
  standalone: true,
  imports: [
    SharedCommonModule,
    VKitCardModule,
    VInputComponent,
    VButtonComponent,
  ],
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss'],
})
export class VerificationPageComponent {
  private auth = inject(KratosAuthenticationService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private store = inject(Store);

  verificationGroup = new FormGroup({
    code: new FormControl(),
  });

  verificationFlowId?: string;

  verificationError?: string;

  constructor() {
    this.route.queryParams.subscribe(params => {
      this.verificationFlowId = params['flow'];

      if (params['code']) {
        this.verificationGroup.controls.code.setValue(params['code']);
      }
    });
  }

  onVerificationFormSubmit() {
    this.verificationError = undefined;
    this.auth
      .completeVerificationFlow(
        this.verificationFlowId!,
        this.verificationGroup.controls.code.value
      )
      .then(() => {
        this.store.dispatch(new LoadUserAction());
        this.router.navigate(['/']);
      })
      .catch(err => {
        this.verificationError = err.toString();
      });
  }
}
