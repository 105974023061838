import { StateContext, StateOperator } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Observable, catchError, of, tap } from 'rxjs';
import { Entity } from './entity';

export type IdentifiableEntities<T> = {
  [key: string]: Entity<T>;
};

export interface ListEntities<T> {
  // isLoading is set to true when data is being loaded
  isLoading?: boolean;
  // if there was an error while loading data this field is set
  loadingError?: string;
  // isCreating is set to true when a new entity is being added
  isCreating?: boolean;
  // if the creation failed this field is set
  creationError?: string;
  // id of the last entry that has been created
  latestCreatedId?: string | number;
  // list of entities
  entities: IdentifiableEntities<T>;
}

// The IteratableListEntities interface can be used to iterate over the entities of a list
export interface IteratableListEntities<T>
  extends Omit<ListEntities<T>, 'entities'> {
  entities: Entity<T>[];
}

export function ListEntitiesToIterable<T>(
  listEntities: ListEntities<T>
): IteratableListEntities<T> {
  return {
    ...listEntities,
    entities: Object.values(listEntities.entities),
  };
}

export interface LoadEntitiesOptions {
  clear?: boolean;
}

export const DefaultListEntitiesState = {
  isLoading: false,
  isSaving: false,
  isDeleting: false,
  entities: {},
};

export function NewListEntities<T>(): ListEntities<T> {
  return DefaultListEntitiesState;
}

/**
 *
 * updateListEntitiy replaces or updates an entity within a list entity from a given observable
 *
 * @param ctx state context
 * @param key key of the list entity in a state model
 * @param id the id of an entity to find it in the list
 * @param observable an observable which returns an updated entity or a partial update
 * @param shouldPatch specifies if the entity should be replaced or patched
 * @returns the wrapped input observable which will update the state when its subscribed to
 */
export function updateListEntity<T, K>(
  ctx: StateContext<T>,
  key: keyof T,
  id: string | number,
  observable: Observable<Partial<K>>,
  shouldPatch = false
): Observable<any> {
  ctx.setState(
    setFields(key, {
      entities: patch({
        [id]: patch({ isSaving: true, savingError: undefined } as Entity<K>),
      }),
    })
  );

  return observable.pipe(
    tap((data: Partial<K>) => {
      ctx.setState(
        setFields(key, {
          entities: patch({
            [id]: patch({
              data: shouldPatch ? patch(data) : data,
              isSaving: false,
            } as Entity<K>),
          }),
        })
      );
    }),
    catchError((error: any) => {
      ctx.setState(
        setFields(key, {
          entities: patch({
            [id]: patch({
              isSaving: true,
              savingError: error.toString(),
            } as Entity<K>),
          }),
        })
      );

      throw error;
    })
  );
}

/**
 *
 * saveListEntity replaces or updates an entity within a list entity from a given observable
 *
 * @param ctx state context
 * @param key key of the list entity in a state model
 * @param idKey the id of an entity to find it in the list
 * @param observable an observable which returns an updated entity or a partial update
 * @param shouldPatch specifies if the entity should be replaced or patched
 * @returns the wrapped input observable which will update the state when its subscribed to
 */
export function addListEntity<T, K>(
  ctx: StateContext<T>,
  key: keyof T,
  idKey: keyof K,
  observable: Observable<Partial<K>>
): Observable<any> {
  ctx.setState(setFields(key, { isCreating: true, creationError: undefined }));

  return observable.pipe(
    tap((data: Partial<K>) => {
      ctx.setState(
        setFields(key, {
          latestCreatedId: data[idKey] as any,
          isCreating: false,
          entities: patch({
            [data[idKey] as any]: {
              data: data,
            },
          }),
        })
      );
    }),
    catchError((error: any) => {
      ctx.setState(
        setFields(key, { isCreating: false, creationError: error.toString() })
      );
      throw error;
    })
  );
}

export function removeListEntity<T, K>(
  ctx: StateContext<T>,
  key: keyof T,
  id: string | number,
  filterFn: (entity: K) => boolean,
  observable: Observable<boolean>
): Observable<any> {
  // check if element exists in entities list
  const state = ctx.getState();

  const entities = (state[key] as ListEntities<T>)?.entities;
  if (!entities[id]) {
    ctx.setState(
      setFields(key, {
        entities: patch({
          [id]: {},
        }),
      })
    );
  }

  ctx.setState(
    setFields(key, {
      entities: patch({
        [id]: patch({
          isDeleting: true,
          deletingError: undefined,
        } as Entity<K>),
      }),
    })
  );

  return observable.pipe(
    tap(() => {
      const state = ctx.getState();

      const filtered = filterEntities(
        state[key] as ListEntities<any>,
        (entity: K) => {
          if (entity === undefined) return false;

          return filterFn(entity);
        }
      );

      ctx.setState(
        setFields(key, {
          ...filtered,
        })
      );
    }),
    catchError((error: any) => {
      ctx.setState(
        setFields(key, {
          entities: patch({
            [id]: patch({
              isDeleting: false,
              deletingError: error.toString(),
            } as Entity<K>),
          }),
        })
      );

      throw error;
    })
  );
}

/**
 *
 * loadEntities should be used to set all entities of a list when initially loading it
 *
 * @param ctx state context
 * @param key key of list entity in a state model
 * @param observable an observable which returns a list of entities
 * @returns the wrapped input observable which set the entities of a list
 */
export function loadEntities<T>(
  ctx: StateContext<T>,
  key: keyof T,
  observable: Observable<IdentifiableEntities<any>>,
  options?: LoadEntitiesOptions
): Observable<any> {
  ctx.setState(setFields(key, { isLoading: true, loadingError: undefined }));

  if (options && options.clear) {
    ctx.setState(setFields(key, { entities: {} }));
  }

  return observable.pipe(
    tap((entites: IdentifiableEntities<any>) => {
      ctx.setState(setFields(key, { isLoading: false, entities: entites }));
    }),
    catchError((error: any) => {
      ctx.setState(
        setFields(key, { isLoading: false, loadingError: error.toString() })
      );
      return of();
    })
  );
}

export function filterEntities<T>(
  listEntity: ListEntities<T>,
  filterFn: (entity: T) => boolean
): ListEntities<T> {
  const filtered = Object.fromEntries(
    Object.entries(listEntity.entities || {}).filter(([key]) => {
      return filterFn(listEntity.entities![key].data!);
    })
  );

  return {
    ...listEntity,
    entities: filtered,
  };
}

type ListEntitiesFields<T> = {
  [P in keyof ListEntities<T>]?: ListEntities<T>[P] | StateOperator<any[P]>;
};

function setFields<T>(
  key: keyof T,
  fields: ListEntitiesFields<any>
): StateOperator<T> {
  return patch<any>({
    [key]: patch<ListEntities<any>>({
      ...fields,
    }),
  });
}
