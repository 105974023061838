<div class="flex w-full flex-col">
  <form [formGroup]="newIncidentFormGroup" (ngSubmit)="onFormSubmit()">
    <v-input-field
      [label]="'pages.createIncident.schema' | translate"
      fControlName="schemaVersionId"
      *ngIf="schemas$ | async as schemas">
      <v-dropdown [options]="schemas" fControlName="schemaVersionId" />
    </v-input-field>

    @if (schemaVersion$ | async; as schemaVersion) {
      @if (schemaVersion.isLoading) {
        Loading..
      } @else {
        @if (schemaVersion.data) {
          <div class="formgrid grid grid-cols-12 gap-x-4 gap-y-2 mt-3">
            <div class="col-span-3">
              <v-input-field
                [label]="'pages.createIncident.priority' | translate"
                fControlName="priority">
                <v-dropdown
                  [options]="schemaVersion.data.priorities || []"
                  fControlName="priority"
                  optionLabel="name"
                  optionValue="id" />
              </v-input-field>
            </div>
            <div class="col-span-9">
              <v-input-field
                [label]="'pages.createIncident.title' | translate"
                fControlName="title">
                <v-input fControlName="title" />
              </v-input-field>
            </div>

            <div class="col-span-12">
              <v-input-field
                [label]="'pages.createIncident.summary' | translate"
                fControlName="summary">
                <v-textarea fControlName="summary" />
              </v-input-field>
            </div>

            <div class="col-span-12">
              <v-input-field
                [label]="'pages.createIncident.affectedServices' | translate"
                fControlName="affectedServices">
                <v-multiselect
                  [options]="(services$ | async) || []"
                  [placeholder]="
                    'pages.createIncident.affectedServicesPlaceholder'
                      | translate
                  "
                  optionLabel="name"
                  optionValue="id"
                  appendDropdownTo="body"
                  fControlName="affectedServices" />
              </v-input-field>
            </div>

            <div class="col-span-12">
              <v-input-field
                [label]="'pages.createIncident.statuspages' | translate"
                fControlName="statuspages">
                <v-multiselect
                  [options]="(statuspages$ | async) || []"
                  [placeholder]="
                    'pages.createIncident.statuspagesPlaceholder' | translate
                  "
                  optionLabel="name"
                  optionValue="id"
                  fControlName="statuspages"
                  appendDropdownTo="body" />
              </v-input-field>
            </div>
          </div>
        }
      }
    }

    <div class="flex mt-4 justify-between">
      <v-button
        [label]="'misc.cancelBtn' | translate"
        variant="secondary"
        class="mr-2"
        type="button"
        (press)="close()"></v-button>
      <v-button
        [label]="'pages.createIncident.createIncidentBtn' | translate"
        icon="pi pi-plus"
        type="submit"
        [disabled]="!newIncidentFormGroup.valid"></v-button>
    </div>
  </form>
</div>
