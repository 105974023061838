import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions } from '@ngxs/store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private actions = inject(Actions);
  private router = inject(Router);
}
