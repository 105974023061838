<v-card [center]="true" padding="lg">
  <v-card-image-header
    slot="header"
    [text]="'pages.resetPassword.header' | translate"
    image="/assets/images/logos/incidite-full-white.svg"
    imageClass="!h-7"></v-card-image-header>

  <ng-container slot="body">
    <div class="vops-krator-recovery mt-4" *ngIf="stage === 'confirm-code'">
      <app-kratos-form
        *ngIf="recoveryFlow"
        [groups]="['code']"
        [flow]="recoveryFlow"
        (kratosFormSubmit)="submitFlow($event)"
        [loading]="isCompletingRegistrationFlow"></app-kratos-form>
    </div>

    <div class="vops-krator-recovery mt-6" *ngIf="stage === 'reset-password'">
      <app-kratos-form
        *ngIf="settingsFlow"
        [groups]="['password']"
        [flow]="settingsFlow"
        (kratosFormSubmit)="submitSettingsFlow($event)"
        [loading]="isCompletingSettingsFlow"></app-kratos-form>
    </div>
  </ng-container>

  <div class="mt-6 text-center text-600" slot="footer">
    {{ 'pages.resetPassword.accountExists' | translate }}
    <br />
    <a tabindex="0" class="font-medium text-blue-500" [routerLink]="['/login']">
      {{ 'actions.login' | translate }}
    </a>
  </div>
</v-card>
