import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { SubscriptionState } from './subscription.state';
import { SubscriptionService } from './subscription.service';

@NgModule({
  declarations: [],
  imports: [NgxsModule.forFeature([SubscriptionState])],
  providers: [SubscriptionService],
  exports: [],
})
export class SubscriptionStateModule {}
