import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { createModelSelector, Store } from '@ngxs/store';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { map } from 'rxjs';
import { IntercomService } from 'src/app/services/intercom/intercom.service';
import { UserState } from 'src/app/store/user/user.state';
import { environment } from 'src/environments/environment';

export enum Tracker {
  GTM = 'GTM',
  INTERCOM = 'INTERCOM',
}

export interface TrackingConfig {
  trackers: Tracker[];
}

export interface TrackingEvent {
  event: string;
  data?: any;
}

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  private gtmService = inject(GoogleTagManagerService);
  private store = inject(Store);
  private router = inject(Router);
  private intercom = inject(IntercomService);

  constructor() {
    if (environment.gtm.enabled && environment.gtm.id) {
      this.init();
    }
  }

  private async init() {
    await this.gtmService.addGtmToDom();

    this.store
      .select(
        createModelSelector({
          user: UserState.getUser,
        })
      )
      .pipe(
        map(async ({ user }) => {
          if (!user) return;
          await this.trackEvent(
            {
              event: 'login',
              data: { userId: user.id },
            },
            { trackers: [Tracker.GTM] }
          );
        })
      )
      .subscribe();

    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        this.trackEvent(
          {
            event: 'page',
            data: { pageName: item.url },
          },
          { trackers: [Tracker.GTM] }
        );
      }
    });
  }

  public async trackEvent(event: TrackingEvent, config: TrackingConfig) {
    try {
      if (config.trackers.includes(Tracker.GTM)) {
        await this.gtmService.pushTag({
          event: event.event,
          ...event.data,
        });
      }

      if (config.trackers.includes(Tracker.INTERCOM)) {
        await this.intercom.trackEvent(event.event, event.data);
      }
    } catch (ex) {
      console.warn('Failed to track event', ex);
    }
  }
}
