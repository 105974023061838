<div
  class="v-description-list"
  [ngClass]="{
    'v-description-list-sm': size === 'sm',
    'v-description-list-lg': size === 'lg',
    'v-description-list-grid': layout === 'grid'
  }">
  @if (layout === 'grid') {
    <dl class="flex flex-wrap">
      @for (entry of entries; track entry.label) {
        <div class="flex flex-col bg-gray-600/20 rounded-lg">
          <dt class="font-medium leading-6 text-primary">
            {{ entry.label }}
          </dt>
          <dd class="leading-6 text-subtle">
            {{ entry.value }}
          </dd>
        </div>
      }
    </dl>
  } @else if (layout === 'list') {
    <dl class="divide-y divide-white/5">
      @for (entry of entries; track entry.label) {
        <div class="grid grid-cols-3">
          <dt class="font-medium leading-6 text-primary">
            {{ entry.label }}
          </dt>
          <dd class="mt-1 text leading-6 text-subtle sm:col-span-2 sm:mt-0">
            @if (entry.value) {
              {{ entry.value }}
            }
          </dd>
        </div>
      }
    </dl>
  }
</div>
