import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { UpdateRegistrationFlowBody } from '@ory/client';
import { SharedCommonModule } from 'src/app/core/common.module';
import { KratosFormComponent } from 'src/app/core/components/kratos/form.component';
import { VButtonComponent } from 'src/app/core/components/ui-kit/buttons/button/button.component';
import { VKitCardModule } from 'src/app/core/components/ui-kit/card/card.module';
import { ErrBrowserLocationChangeRequired } from 'src/app/services/authentication/kratos.errors';
import {
  Invitation,
  KratosAuthenticationService,
} from 'src/app/services/authentication/kratos.service';
import { FeaturesState } from 'src/app/store/features/features.state';
import { FLAGS } from 'src/app/store/features/flags';
import { LogoutAction } from 'src/app/store/user/user.actions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  imports: [
    SharedCommonModule,
    KratosFormComponent,
    VKitCardModule,
    VButtonComponent,
  ],
  standalone: true,
})
export class RegisterPageComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private auth = inject(KratosAuthenticationService);
  private router = inject(Router);
  private store = inject(Store);

  disableRegistration$ = this.store.select(
    FeaturesState.boolFlag(FLAGS.DISABLE_REGISTRATION)
  );

  registrationFlow?: any;
  isCompletingRegistrationFlow = false;

  isLoading = false;

  isInvite = false;
  invitation?: Invitation;
  loadInvitationError?: string;

  initialFlowLoading = true;

  showAlreadyLoggedIn = false;

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const invitationToken = params['token'];
      const flowId = params['flow'];

      if (flowId) {
        this.loadRegistrationFlow(flowId);
      } else {
        if (invitationToken) {
          this.isInvite = true;
          this.loadInvitation(invitationToken).then(invitation => {
            this.invitation = invitation;
            this.createRegistrationFlow();
          });
        } else {
          this.createRegistrationFlow();
        }
      }
    });
  }

  private loadRegistrationFlow(flowId: string) {
    this.auth.getRegistrationFlow(flowId).then(flow => {
      this.registrationFlow = flow;
      this.initialFlowLoading = false;
    });
  }

  private createRegistrationFlow() {
    const returnTo = `${environment.frontendUrl}/login?check=whoami`;

    this.auth
      .createRegistrationFlow(returnTo)
      .then(flow => {
        // preset email
        if (this.invitation) {
          flow?.ui.nodes.find((n: any) => {
            if (n.attributes.name === 'traits.email') {
              n.attributes.value = this.invitation?.email;
            }
          });
        }

        this.registrationFlow = flow;
      })
      .catch(() => {
        this.showAlreadyLoggedIn = true;

        if (this.isInvite) {
          this.router.navigate(['/organizations/join'], {
            queryParams: { token: this.invitation?.token },
          });
        }
      })
      .finally(() => {
        this.initialFlowLoading = false;
      });
  }

  loadInvitation(token: string): Promise<Invitation> {
    return new Promise((resolve, reject) => {
      return this.auth.getInvitation(token).subscribe({
        next: invitation => {
          resolve(invitation);
        },
        error: error => {
          this.loadInvitationError = error;
          reject(error);
        },
      });
    });
  }

  async submitFlow(data: UpdateRegistrationFlowBody) {
    this.isCompletingRegistrationFlow = true;

    if (data.method === 'oidc') {
      try {
        // remove traits as they will be provided by the OIDC provider
        delete data.traits;

        await this.auth.completeRegistrationFlowV2(
          this.registrationFlow!,
          data
        );
      } catch (err) {
        if (err instanceof ErrBrowserLocationChangeRequired) {
          window.location.href = err.redirectUri;
        }
      }
    } else if (data.method === 'password') {
      try {
        await this.auth.completeRegistrationFlowV2(
          this.registrationFlow!,
          data
        );
        if (this.isInvite) {
          this.router.navigate(['/organizations/join'], {
            queryParams: { token: this.invitation?.token },
          });
        } else {
          this.router.navigate(['/']);
        }
      } catch (error: any) {
        this.registrationFlow = error.response.data;
      } finally {
        this.isCompletingRegistrationFlow = false;
      }
    }
  }

  logout() {
    this.store.dispatch(new LogoutAction());
  }

  goToDashboard() {
    this.router.navigate(['/dashboard']);
  }
}
