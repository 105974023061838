<div
  class="relative v-dialog"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true">
  <div
    [@dialogBackdropAnimation]="isVisible ? '*' : 'void'"
    (@dialogBackdropAnimation.done)="animationDone($event)"
    class="fixed inset-0 bg-black"
    aria-hidden="true"></div>

  <div
    class="fixed inset-0 w-screen overflow-y-auto v-dialog-container"
    aria-hidden="true">
    <div
      (click)="close(false)"
      class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div
        (click)="$event.stopPropagation()"
        [@dialogContainerAnimation]="isVisible ? '*' : 'void'"
        class="relative overflow-hidden rounded-lg shadow-xl sm:w-full sm:max-w-lg">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
