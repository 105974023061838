import { TrackingConfig, TrackingEvent } from './tracking.service';

export class TrackGenericEventAction {
  static readonly type = '[Tracking] Track Generic Event';
  constructor(
    public event: TrackingEvent,
    public config: TrackingConfig
  ) {}
}

export class TrackUserRegisteredEventAction {
  static readonly type = '[Tracking] Track User Registered Event';
  constructor(public identityId: string) {}
}

export class TrackUserLoggedInEventAction {
  static readonly type = '[Tracking] Track User Logged In Event';
  constructor(public identityId: string) {}
}

export class TrackSubscriptionChangedEventAction {
  static readonly type = '[Tracking] Track Subscription Changed';
  constructor(
    public planName: string,
    public priceId: string,
    public isTrial?: boolean,
    public discountCodes?: string[]
  ) {}
}

export class TrackSubscriptionCanceledEventAction {
  static readonly type = '[Tracking] Track Subscription Canceled';
  constructor(public planName: string) {}
}

export class TrackSubscriptionUnCanceledEventAction {
  static readonly type = '[Tracking] Track Subscription Uncanceled';
  constructor(public planName: string) {}
}

export class TrackSubscriptionDowngradedCanceledEventAction {
  static readonly type = '[Tracking] Track Subscription Downgrade Canceled';
  constructor(public planName: string) {}
}
