import { NgModule } from '@angular/core';
import { SharedCommonModule } from 'src/app/core/common.module';
import { ComponentsModule } from 'src/app/core/components/components.module';
import { StatsCardComponent } from 'src/app/core/components/stats-card/stats-card.component';
import { OrganizationStateModule } from 'src/app/store/organization/organization.state.module';
import { SubscriptionStateModule } from 'src/app/store/subscription/subscription.state.module';
import { HomeModule } from './home/home.module';
import { IncidentStateComponent } from './incidents/components/incident-state/incident-state.component';
import { InternalLayoutComponent } from './internal.component';

@NgModule({
  declarations: [InternalLayoutComponent],
  imports: [
    SharedCommonModule,
    HomeModule,
    StatsCardComponent,
    IncidentStateComponent,
    ComponentsModule,
    SubscriptionStateModule,
    OrganizationStateModule,
  ],
})
export class InternalModule {}
