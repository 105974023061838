import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'v-card-image-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './image-header.component.html',
  styleUrl: './image-header.component.scss',
})
export class VCardImageHeaderComponent {
  /**
   * The text to display in the header
   */
  @Input() text: string = 'Header';

  /**
   * The image to display in the header
   */
  @Input() image: string = '';

  @Input() imageClass: string = '';
}
