import { inject, Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { OAuth2ConsentInfo } from 'src/app/models/oauth2';

const CONFIRM_OAUTH2_LOGIN_CHALLENGE_MUTATION = gql`
  mutation OAuth2ConfirmLoginChallenge($challenge: String!) {
    confirmOAuth2LoginChallenge(challenge: $challenge) {
      redirectUri
    }
  }
`;

const CONFIRM_OAUTH2_CONSENT_CHALLENGE_MUTATION = gql`
  mutation OAuth2ConfirmConsentChallenge($challenge: String!) {
    confirmOAuth2ConsentChallenge(challenge: $challenge) {
      redirectUri
    }
  }
`;

const GET_OAUTH2_CONSENT_INFO_QUERY = gql`
  query GetOAuth2ConsentChallenge($challenge: String!) {
    oauth2ConsentChallenge(challenge: $challenge) {
      client {
        id
        name
        logoUri
      }
      requestedScopes
      skip
    }
  }
`;

@Injectable()
export class OAuth2Service {
  private apollo = inject(Apollo);

  confirmLoginChallenge(challenge: string): Observable<string> {
    return this.apollo
      .mutate<{ confirmOAuth2LoginChallenge: { redirectUri: string } }>({
        mutation: CONFIRM_OAUTH2_LOGIN_CHALLENGE_MUTATION,
        variables: {
          challenge: challenge,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.confirmOAuth2LoginChallenge.redirectUri!;
        })
      );
  }

  confirmConsentChallenge(challenge: string): Observable<string> {
    return this.apollo
      .mutate<{ confirmOAuth2ConsentChallenge: { redirectUri: string } }>({
        mutation: CONFIRM_OAUTH2_CONSENT_CHALLENGE_MUTATION,
        variables: {
          challenge: challenge,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.confirmOAuth2ConsentChallenge.redirectUri!;
        })
      );
  }

  getConsentChallenge(challenge: string): Observable<OAuth2ConsentInfo> {
    return this.apollo
      .query<{ oauth2ConsentChallenge: OAuth2ConsentInfo }>({
        query: GET_OAUTH2_CONSENT_INFO_QUERY,
        variables: {
          challenge: challenge,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.oauth2ConsentChallenge!;
        })
      );
  }
}
