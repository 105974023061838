import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PaginatorModule } from 'primeng/paginator';
import { VDataDisplayComponent } from './data-display.component';
import { VBasicDataItemComponent } from './items/basic/basic-data-item.component';
import { VDataItemDirective } from './items/data-item.directive';

@NgModule({
  declarations: [VDataItemDirective],
  imports: [
    CommonModule,
    VBasicDataItemComponent,
    VDataDisplayComponent,
    PaginatorModule,
  ],
  exports: [VDataDisplayComponent, VBasicDataItemComponent, VDataItemDirective],
})
export class VDataDisplayModule {}
