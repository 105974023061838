<div class="@container">
  @if (formArray.controls.length > 0) {
    <div class="mb-2">
      <form [formArrayName]="fControlName">
        <div class="flex flex-col gap-2">
          @for (control of formArray.controls; track control; let i = $index) {
            <div
              class="grid items-center gap-2 grid-cols-11 @lg:grid-cols-12"
              [formGroup]="control">
              <div class="col-span-4 @lg:col-span-5">
                <v-input
                  fControlName="key"
                  placeholder="Key"
                  size="small"></v-input>
              </div>
              <div class="col-span-4 @lg:col-span-5">
                <v-input
                  fControlName="value"
                  placeholder="Value"
                  [type]="valueType"
                  size="small"></v-input>
              </div>
              <div class="col-span-3 @lg:col-span-2">
                <v-button
                  type="button"
                  label="Remove"
                  variant="secondary"
                  icon="pi pi-trash"
                  [fullWidth]="true"
                  (press)="removeEntry(i)"></v-button>
              </div>
            </div>
          }
        </div>
      </form>
    </div>
  }
  <form [formGroup]="newEntryFG" (ngSubmit)="addEntry()">
    <div class="grid w-full items-center gap-2 grid-cols-11 @lg:grid-cols-12">
      <div class="col-span-4 @lg:col-span-5">
        <v-input fControlName="key" placeholder="Key" size="small"></v-input>
      </div>
      <div class="col-span-4 @lg:col-span-5">
        <v-input
          fControlName="value"
          placeholder="Value"
          [type]="valueType"
          size="small"></v-input>
      </div>
      <div class="col-span-3 @lg:col-span-2">
        <v-button
          type="submit"
          label="Add"
          icon="pi pi-plus"
          [disabled]="!newEntryFG.valid"
          [fullWidth]="true"></v-button>
      </div>
    </div>
  </form>
</div>
