import { inject, Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import {
  mapGQLResponse,
  toIdentifiableEntities,
} from 'src/app/helpers/graphql';
import { Alert } from 'src/app/models/alert';
import {
  Incident,
  IncidentEvent,
  IncidentEventActor,
  IncidentRoleAssignment,
  IncidentSpec,
} from 'src/app/models/incident';
import {
  CursorPaginationOptions,
  OffsetPaginatedRespose,
  OffsetPaginationOptions,
} from 'src/app/store/common/pagination';

export enum ImpactTimings {
  Started = 'impactStarted',
  Detected = 'impactDetected',
  Resolved = 'impactResolved',
}

export const EVENT_FIELDS = `
    id
    type
    createdAt
    payload {
      ... on IncidentEventPayloadComment {
        comment
      }
      ... on IncidentEventPayloadState {
        oldState {
          id
          name
          color
          icon
        }
        newState {
          id
          name
          color
          icon
        }
      }
      ... on IncidentEventPayloadPriority {
        oldPriority {
          id
          name
          color
          icon
        }
        newPriority {
          id
          name
          color
          icon
        }
      }
      ... on IncidentEventPayloadResponder {
        responder {
          firstname
          lastname
          avatar
        }
      }
      ... on IncidentEventPayloadRole {
        role {
          id
          name
          description
        }
        assignee {
          firstname
          lastname
          avatar
        }
      }
      ... on IncidentEventPayloadFieldUpdated {
        field
        oldValue {
          ... on FieldStringValue {
            stringValue
          }
          ... on FieldIntValue {
            intValue
          }
        }
        newValue {
          ... on FieldStringValue {
            stringValue
          }
          ... on FieldIntValue {
            intValue
          }
        }
      }
      ... on IncidentEventPayloadAffectedService {
        service {
          name
          description
          isDeleted
          deletedAt
        }
        impact
      }
      ... on IncidentEventPayloadStatuspage {
        statuspage {
          name
        }
      }
      ... on IncidentEventPayloadRelatedAlert {
        alert {
          name
        }
      }
    }
    actor {
      ... on OrgMemberActor {
        user{
          firstname
          lastname
          avatar
        }
      }
      ... on ServiceAccountActor {
        name
      }
    }
`;

export const EVENTS_FRAGMENT = gql`
  fragment EventFields on IncidentEventList {
    events {
      ${EVENT_FIELDS}
    }
  }
`;

export const LIST_INCIDENTS_QUERY = gql`
  query GetIncidents(
    $organizationUUID: ID!
    $listIncidentsRequest: ListIncidentsRequest
  ) {
    incidents(
      organizationUUID: $organizationUUID
      listIncidentsRequest: $listIncidentsRequest
    ) {
      totalSize
      incidents {
        id
        title
        summary
        createdAt
        priority {
          id
          name
          order
          color
          icon
        }
        state {
          id
          name
          order
          color
          icon
          isDefault
          isResolved
        }
        statuspageLinks {
          statuspage {
            id
            name
            customDomain
            domain
            slug
          }
        }
        affectedServices {
          service {
            name
          }
        }
      }
    }
  }
`;

export const GET_INCIDENT_QUERY = gql`
  ${EVENTS_FRAGMENT}
  query GetIncident(
    $organizationUUID: ID!
    $id: Int!
    $listEventOptions: ListEventOptions
  ) {
    incident(organizationUUID: $organizationUUID, id: $id) {
      id
      schemaVersion {
        id
        version
        schema {
          id
          name
          type
          createdAt
        }
        states {
          id
          name
          order
          color
          icon
          isDefault
          isResolved
        }
        priorities {
          id
          name
          order
          color
          icon
        }
      }
      title
      priority {
        id
        name
        order
        color
        icon
      }
      createdAt
      summary
      state {
        id
        name
        order
        color
        icon
        isDefault
        isResolved
      }
      affectedServices {
        service {
          id
          name
          isDeleted
          deletedAt
        }
        impact
      }
      statuspageLinks {
        statuspage {
          id
          name
          customDomain
          domain
          slug
        }
      }
      timings {
        impactStarted
        impactDetected
        impactResolved
      }
      roles {
        role {
          id
          name
          description
          createdAt
          updatedAt
        }
        assignments {
          id
          memberId
          user {
            firstname
            lastname
            avatar
          }
          createdAt
          updatedAt
        }
      }
      broadcastMessages {
        id
        channel {
          endpoint {
            providerType
            config {
              ... on ProviderChannelMetadata {
                name
              }
            }
          }
        }
      }
      events(listOptions: $listEventOptions) {
        nextPageToken
        totalSize
        ...EventFields
      }
    }
  }
`;

export const GET_INCIDENT_RELATED_ALERTS = gql`
  query GetIncidentRelatedAlerts(
    $organizationUUID: ID!
    $id: Int!
    $listRelatedAlertsOptions: ListRelatedAlertsOptions
  ) {
    incident(organizationUUID: $organizationUUID, id: $id) {
      relatedAlerts(listOptions: $listRelatedAlertsOptions) {
        totalSize
        alerts {
          id
          state
          name
          description
          metadata {
            labels
            annotations
          }
          alertedAt
          startedAt
          resolvedAt
          createdAt
        }
      }
    }
  }
`;

const UPDATE_INCIDENT_TITLE_MUTATION = gql`
  mutation UpdateIncidentTitle(
    $organizationUUID: ID!
    $incidentId: Int!
    $newTitle: String!
  ) {
    updateIncidentTitle(
      organizationUUID: $organizationUUID
      updateIncidentTitleSpec: { incidentId: $incidentId, newTitle: $newTitle }
    ) {
      title
    }
  }
`;

const CREATE_INCIDENT_MUTATION = gql`
  mutation CreateIncident($organizationUUID: ID!, $spec: NewIncidentRequest!) {
    createIncident(
      organizationUUID: $organizationUUID
      newIncidentSpec: $spec
    ) {
      id
    }
  }
`;

const UPDATE_INCIDENT_SUMMARY_MUTATION = gql`
  mutation UpdateIncidentSummary(
    $organizationUUID: ID!
    $incidentId: Int!
    $newSummary: String!
  ) {
    updateIncidentSummary(
      organizationUUID: $organizationUUID
      updateIncidentSummarySpec: {
        incidentId: $incidentId
        newSummary: $newSummary
      }
    ) {
      summary
    }
  }
`;

const UPDATE_INCIDENT_PRIORITY_MUTATION = gql`
  mutation UpdateIncidentPriority(
    $organizationUUID: ID!
    $incidentId: Int!
    $newPriorityId: ID!
  ) {
    updateIncidentPriority(
      organizationUUID: $organizationUUID
      updateIncidentPrioritySpec: {
        incidentId: $incidentId
        newPriorityId: $newPriorityId
      }
    ) {
      priority {
        id
        name
        order
        color
        icon
      }
    }
  }
`;

const UPDATE_INCIDENT_STATE_MUTATION = gql`
  mutation UpdateIncidentState(
    $organizationUUID: ID!
    $incidentId: Int!
    $newStateId: ID!
  ) {
    updateIncidentState(
      organizationUUID: $organizationUUID
      updateIncidentStateSpec: {
        incidentId: $incidentId
        newStateId: $newStateId
      }
    ) {
      state {
        id
        name
        order
        color
        icon
        isDefault
        isResolved
      }
    }
  }
`;

const UPDATE_INCIDENT_SERVICE_IMPACT_MUTATION = gql`
  mutation UpdateIncidentServiceImpact(
    $organizationUUID: ID!
    $incidentId: Int!
    $serviceId: Int!
    $newImpact: String!
  ) {
    updateServiceImpact(
      organizationUUID: $organizationUUID
      updateServiceImpactSpec: {
        incidentId: $incidentId
        serviceId: $serviceId
        newImpact: $newImpact
      }
    ) {
      affectedServices {
        service {
          id
          name
        }
        impact
      }
    }
  }
`;

const LINK_SERVICE_MUTATION = gql`
  mutation LinkService(
    $organizationUUID: ID!
    $incidentId: Int!
    $serviceId: Int!
  ) {
    linkService(
      organizationUUID: $organizationUUID
      linkServiceSpec: { incidentId: $incidentId, serviceId: $serviceId }
    ) {
      affectedServices {
        service {
          id
          name
        }
        impact
      }
    }
  }
`;

const UNLINK_SERVICE_MUTATION = gql`
  mutation UnLinkService(
    $organizationUUID: ID!
    $incidentId: Int!
    $serviceId: Int!
  ) {
    unLinkService(
      organizationUUID: $organizationUUID
      unLinkServiceSpec: { incidentId: $incidentId, serviceId: $serviceId }
    ) {
      affectedServices {
        service {
          id
          name
        }
        impact
      }
    }
  }
`;

const LINK_STATUSPAGE_MUTATION = gql`
  mutation LinkStatuspage(
    $organizationUUID: ID!
    $incidentId: Int!
    $publicStatuspageId: Int!
  ) {
    linkStatuspage(
      organizationUUID: $organizationUUID
      linkStatuspageSpec: {
        incidentId: $incidentId
        statuspageId: $publicStatuspageId
      }
    ) {
      statuspageLinks {
        statuspage {
          id
          name
          customDomain
          domain
          slug
        }
      }
    }
  }
`;

const UNLINK_STATUSPAGE_MUTATION = gql`
  mutation UnlinkStatuspage(
    $organizationUUID: ID!
    $incidentId: Int!
    $publicStatuspageId: Int!
  ) {
    unlinkStatuspage(
      organizationUUID: $organizationUUID
      unlinkStatuspageSpec: {
        incidentId: $incidentId
        statuspageId: $publicStatuspageId
      }
    ) {
      statuspageLinks {
        statuspage {
          id
          name
          customDomain
          domain
          slug
        }
      }
    }
  }
`;

const ADD_COMMENT_TO_INCIDENT_MUTATION = gql`
  mutation AddComment(
    $organizationUUID: ID!
    $incidentId: Int!
    $comment: String!
  ) {
    addCommentToIncident(
      organizationUUID: $organizationUUID
      incidentId: $incidentId
      commentSpec: { comment: $comment }
    ) {
      id
    }
  }
`;

const ASSIGN_RESPONDER_ROLE_MUTATION = gql`
  mutation AssignResponderRole(
    $organizationUUID: ID!
    $memberId: ID!
    $incidentId: Int!
    $roleId: ID!
  ) {
    assignResponderRole(
      organizationUUID: $organizationUUID
      memberId: $memberId
      incidentId: $incidentId
      roleId: $roleId
    ) {
      id
      memberId
      user {
        firstname
        lastname
        avatar
      }
      createdAt
      updatedAt
    }
  }
`;

const UNASSIGN_RESPONDER_ROLE_MUTATION = gql`
  mutation UnAssignResponderRole(
    $organizationUUID: ID!
    $incidentId: Int!
    $assignmentId: ID!
  ) {
    unAssignResponderRole(
      organizationUUID: $organizationUUID
      incidentId: $incidentId
      assignmentId: $assignmentId
    )
  }
`;

const DELETE_EVENT_MUTATION = gql`
  mutation DeleteIncidentEvent(
    $organizationUUID: ID!
    $incidentId: Int!
    $eventId: ID!
  ) {
    deleteIncidentEvent(
      organizationUUID: $organizationUUID
      incidentId: $incidentId
      eventId: $eventId
    )
  }
`;

const DELETE_INCIDENT_MUTATION = gql`
  mutation DeleteIncident($organizationUUID: ID!, $incidentId: Int!) {
    deleteIncident(organizationUUID: $organizationUUID, incidentId: $incidentId)
  }
`;

@Injectable()
export class IncidentsService {
  private apollo = inject(Apollo);

  listIncidents(
    organizationId: string,
    pagination: OffsetPaginationOptions,
    query?: string
  ): Observable<OffsetPaginatedRespose<Incident>> {
    return this.apollo
      .query<{ incidents: { incidents: Incident[]; totalSize: number } }>({
        query: LIST_INCIDENTS_QUERY,
        variables: {
          organizationUUID: organizationId,
          listIncidentsRequest: {
            page: pagination.page,
            pageSize: pagination.pageSize,
            query: query,
          },
        },
      })
      .pipe(
        map(data => {
          const res = {
            items: toIdentifiableEntities(
              data.data.incidents.incidents.map(incident => {
                return {
                  ...incident,
                  createdAt: new Date(
                    Date.parse(incident.createdAt as unknown as string)
                  ),
                };
              }),
              'id'
            ),
            totalSize: data.data.incidents.totalSize,
          };

          return res;
        })
      );
  }

  loadIncident(
    organizationId: string,
    publicIncidentId: number,
    eventPagination?: CursorPaginationOptions
  ): Observable<Incident> {
    return this.apollo
      .query<{ incident: Incident }>({
        query: GET_INCIDENT_QUERY,
        variables: {
          organizationUUID: organizationId,
          id: publicIncidentId,
          listEventOptions: {
            pageSize: eventPagination?.pageSize || 10,
            pageToken: eventPagination?.pageToken || '',
          },
        },
      })
      .pipe(
        map(data => {
          const res = mapGQLResponse<{ incident: Incident }>(data, 'incident');

          if (res.events) {
            res.events = {
              ...res.events,
              events: res.events
                ? res.events.events.map(this.mapEventPayload)
                : [],
            };
          }

          return res;
        })
      );
  }

  loadRelatedAlerts(
    organizationId: string,
    publicIncidentId: number,
    relatedAlertPagination?: OffsetPaginationOptions
  ): Observable<OffsetPaginatedRespose<Alert>> {
    return this.apollo
      .query<{
        incident: { relatedAlerts: { alerts: Alert[]; totalSize: number } };
      }>({
        query: GET_INCIDENT_RELATED_ALERTS,
        variables: {
          organizationUUID: organizationId,
          id: publicIncidentId,
          listRelatedAlertsOptions: {
            pageSize: relatedAlertPagination?.pageSize || 5,
            page: relatedAlertPagination?.page || 1,
          },
        },
      })
      .pipe(
        map(data => {
          const res = mapGQLResponse<{
            incident: { relatedAlerts: { alerts: Alert[]; totalSize: number } };
          }>(data, 'incident');

          return {
            items: toIdentifiableEntities(res.relatedAlerts!.alerts, 'id'),
            totalSize: res.relatedAlerts!.totalSize,
          };
        })
      );
  }

  createIncident(
    organizationId: string,
    spec: IncidentSpec
  ): Observable<number> {
    return this.apollo
      .mutate<{ createIncident: { id: number } }>({
        mutation: CREATE_INCIDENT_MUTATION,
        variables: {
          organizationUUID: organizationId,
          spec: {
            schemaVersionId: spec.schemaVersionId,
            priorityId: spec.priorityId,
            title: spec.title,
            summary: spec.summary,
            affectedServiceIds: spec.affectedServiceIds,
            statuspageIds: spec.statuspageIds,
          },
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.createIncident.id;
        })
      );
  }

  updateIncidentTitle(
    organizationId: string,
    publicIncidentId: number,
    newTitle: string
  ): Observable<Pick<Incident, 'title'>> {
    return this.apollo
      .mutate<{ updateIncidentTitle: Incident }>({
        mutation: UPDATE_INCIDENT_TITLE_MUTATION,
        variables: {
          organizationUUID: organizationId,
          incidentId: publicIncidentId,
          newTitle: newTitle,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.updateIncidentTitle;
        })
      );
  }

  updateIncidentSummary(
    organizationId: string,
    publicIncidentId: number,
    newSummary: string
  ): Observable<Pick<Incident, 'summary'>> {
    return this.apollo
      .mutate<{ updateIncidentSummary: Incident }>({
        mutation: UPDATE_INCIDENT_SUMMARY_MUTATION,
        variables: {
          organizationUUID: organizationId,
          incidentId: publicIncidentId,
          newSummary: newSummary,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.updateIncidentSummary;
        })
      );
  }

  updateIncidentPriority(
    organizationId: string,
    publicIncidentId: number,
    newPriorityId: string
  ): Observable<Pick<Incident, 'priority'>> {
    return this.apollo
      .mutate<{ updateIncidentPriority: Incident }>({
        mutation: UPDATE_INCIDENT_PRIORITY_MUTATION,
        variables: {
          organizationUUID: organizationId,
          incidentId: publicIncidentId,
          newPriorityId: newPriorityId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.updateIncidentPriority;
        })
      );
  }

  updateIncidentState(
    organizationId: string,
    publicIncidentId: number,
    newStateId: string
  ): Observable<Pick<Incident, 'state'>> {
    return this.apollo
      .mutate<{ updateIncidentState: Incident }>({
        mutation: UPDATE_INCIDENT_STATE_MUTATION,
        variables: {
          organizationUUID: organizationId,
          incidentId: publicIncidentId,
          newStateId: newStateId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.updateIncidentState;
        })
      );
  }

  updateIncidentServiceImpact(
    organizationId: string,
    publicIncidentId: number,
    serviceId: number,
    newImpact: string
  ): Observable<Pick<Incident, 'affectedServices'>> {
    return this.apollo
      .mutate<{ updateServiceImpact: Incident }>({
        mutation: UPDATE_INCIDENT_SERVICE_IMPACT_MUTATION,
        variables: {
          organizationUUID: organizationId,
          incidentId: publicIncidentId,
          serviceId: serviceId,
          newImpact: newImpact,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.updateServiceImpact;
        })
      );
  }

  updateIncidentImpactTiming(
    organizationId: string,
    publicIncidentId: number,
    timing: ImpactTimings,
    datetime: Date,
    setNull: boolean = false
  ): Observable<Pick<Incident, 'timings'>> {
    return this.apollo
      .mutate<{ updateIncidentTimings: Incident }>({
        mutation: gql`
          mutation UpdateIncidentImpactTimings(
            $organizationUUID: ID!
            $incidentId: Int!
            ${!setNull ? '$datetime: Time' : ''}
          ) {
            updateIncidentTimings(
              organizationUUID: $organizationUUID
              updateIncidentTimingsSpec: {
                incidentId: $incidentId
                ${timing}: {
                    ${setNull ? 'setNull: true' : ''}
                    ${!setNull ? 'datetime: $datetime' : ''}
                }
              }
            ) {
              timings {
                impactStarted
                impactDetected
                impactResolved
              }
            }
          }
        `,
        variables: {
          organizationUUID: organizationId,
          incidentId: publicIncidentId,
          datetime: datetime,
          setNull: setNull,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.updateIncidentTimings;
        })
      );
  }

  linkService(
    organizationId: string,
    publicIncidentId: number,
    serviceId: number
  ): Observable<Pick<Incident, 'affectedServices'>> {
    return this.apollo
      .mutate<{ linkService: Incident }>({
        mutation: LINK_SERVICE_MUTATION,
        variables: {
          organizationUUID: organizationId,
          incidentId: publicIncidentId,
          serviceId: serviceId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.linkService;
        })
      );
  }

  unLinkService(
    organizationId: string,
    publicIncidentId: number,
    serviceId: number
  ): Observable<Pick<Incident, 'affectedServices'>> {
    return this.apollo
      .mutate<{ unLinkService: Incident }>({
        mutation: UNLINK_SERVICE_MUTATION,
        variables: {
          organizationUUID: organizationId,
          incidentId: publicIncidentId,
          serviceId: serviceId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.unLinkService;
        })
      );
  }

  linkStatuspage(
    organizationId: string,
    publicIncidentId: number,
    publicStatuspageId: number
  ): Observable<Pick<Incident, 'statuspageLinks'>> {
    return this.apollo
      .mutate<{ linkStatuspage: Incident }>({
        mutation: LINK_STATUSPAGE_MUTATION,
        variables: {
          organizationUUID: organizationId,
          incidentId: publicIncidentId,
          publicStatuspageId: publicStatuspageId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.linkStatuspage;
        })
      );
  }

  unlinkStatuspage(
    organizationId: string,
    publicIncidentId: number,
    publicStatuspageId: number
  ): Observable<Pick<Incident, 'statuspageLinks'>> {
    return this.apollo
      .mutate<{ unlinkStatuspage: Incident }>({
        mutation: UNLINK_STATUSPAGE_MUTATION,
        variables: {
          organizationUUID: organizationId,
          incidentId: publicIncidentId,
          publicStatuspageId: publicStatuspageId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.unlinkStatuspage;
        })
      );
  }

  addCommentToIncident(
    organizationId: string,
    publicIncidentId: number,
    comment: string
  ): Observable<IncidentEvent> {
    return this.apollo
      .mutate<{ addCommentToIncident: IncidentEvent }>({
        mutation: ADD_COMMENT_TO_INCIDENT_MUTATION,
        variables: {
          organizationUUID: organizationId,
          incidentId: publicIncidentId,
          comment: comment,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.addCommentToIncident;
        })
      );
  }

  assignResponderRole(
    organizationId: string,
    publicIncidentId: number,
    memberId: string,
    roleId: string
  ): Observable<IncidentRoleAssignment> {
    return this.apollo
      .mutate<{ assignResponderRole: IncidentRoleAssignment }>({
        mutation: ASSIGN_RESPONDER_ROLE_MUTATION,
        variables: {
          organizationUUID: organizationId,
          incidentId: publicIncidentId,
          memberId: memberId,
          roleId: roleId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.assignResponderRole;
        })
      );
  }

  unassignResponderRole(
    organizationId: string,
    publicIncidentId: number,
    assignmentId: string
  ): Observable<boolean> {
    return this.apollo
      .mutate<{ unAssignResponderRole: boolean }>({
        mutation: UNASSIGN_RESPONDER_ROLE_MUTATION,
        variables: {
          organizationUUID: organizationId,
          incidentId: publicIncidentId,
          assignmentId: assignmentId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.unAssignResponderRole;
        })
      );
  }

  deleteIncidentEvent(
    organizationId: string,
    publicIncidentId: number,
    eventId: string
  ): Observable<boolean> {
    return this.apollo
      .mutate<{ deleteIncidentEvent: boolean }>({
        mutation: DELETE_EVENT_MUTATION,
        variables: {
          organizationUUID: organizationId,
          incidentId: publicIncidentId,
          eventId: eventId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.deleteIncidentEvent;
        })
      );
  }

  deleteIncident(
    organizationId: string,
    publicIncidentId: number
  ): Observable<boolean> {
    return this.apollo
      .mutate<{ deleteIncident: boolean }>({
        mutation: DELETE_INCIDENT_MUTATION,
        variables: {
          organizationUUID: organizationId,
          incidentId: publicIncidentId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.deleteIncident;
        })
      );
  }

  private mapEventPayload(gqlData: any): IncidentEvent {
    return {
      id: gqlData.id,
      type: gqlData.type,
      payload: gqlData.payload,
      createdAt: new Date(Date.parse(gqlData.createdAt)),
      actor: {
        type:
          gqlData.actor['__typename'] === 'ServiceAccountActor'
            ? IncidentEventActor.SYSTEM
            : IncidentEventActor.USER,
        ...gqlData.actor,
      },
    };
  }
}
