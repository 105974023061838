<v-card [center]="true" padding="lg">
  <v-card-image-header
    slot="header"
    [text]="'pages.verification.header' | translate"
    image="/assets/images/logos/incidite-full-white.svg"
    imageClass="!h-7"></v-card-image-header>

  <div slot="body" class="mt-4">
    <form
      [formGroup]="verificationGroup"
      (ngSubmit)="onVerificationFormSubmit()">
      <v-input-field
        [label]="'pages.verification.code' | translate"
        fControlName="code">
        <v-input type="text" placeholder="000000" fControlName="code"></v-input>
      </v-input-field>

      <div class="mt-4 flex justify-content-end w-full">
        <v-button
          type="submit"
          [label]="'pages.verification.verifyBtn' | translate"></v-button>
      </div>

      <p-message
        severity="danger"
        styleClass="w-full mt-4"
        [text]="verificationError"
        *ngIf="verificationError"></p-message>
    </form>
  </div>
</v-card>
