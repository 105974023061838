import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, map, Observable, throwError } from 'rxjs';
import { toIdentifiableEntities } from 'src/app/helpers/graphql';
import { Service } from 'src/app/models/service';
import { ToastService } from 'src/app/services/toast/toast.service';
import {
  ListEntities,
  loadEntities,
  NewListEntities,
  removeListEntity,
} from 'src/app/store/common/list-entity';
import { UserState } from 'src/app/store/user/user.state';
import { DeleteServiceAction, LoadServices } from './services.actions';
import { ServicesService } from './services.service';

export interface ServicesStateModel {
  services: ListEntities<Service>;
}

@State<ServicesStateModel>({
  name: 'services',
  defaults: {
    services: NewListEntities(),
  },
})
@Injectable()
export class ServicesState {
  store = inject(Store);
  servicesService = inject(ServicesService);
  toastService = inject(ToastService);

  @Selector()
  static services(state: ServicesStateModel) {
    return state.services;
  }

  @Action(LoadServices)
  loadServicesToState(ctx: StateContext<ServicesStateModel>) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return loadEntities(
      ctx,
      'services',
      this.servicesService.loadServices(organizationID).pipe(
        map(services => {
          return toIdentifiableEntities(services, 'id');
        })
      )
    );
  }

  @Action(DeleteServiceAction)
  deleteService(
    ctx: StateContext<ServicesStateModel>,
    { serviceId }: DeleteServiceAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return removeListEntity(
      ctx,
      'services',
      serviceId,
      (service: Service) => {
        return service.id !== serviceId;
      },
      this.servicesService.deleteService(organizationID, serviceId).pipe(
        map(val => {
          if (val) {
            this.toastService.showInfo(
              'Service Deleted',
              'Service has been deleted'
            );
          }

          return val;
        })
      )
    );
  }

  // Generic function to call the incidents service
  private execute<K extends keyof Service, T extends any[]>(
    ctx: StateContext<ServicesStateModel>,
    serviceMethod: (
      organizationId: string,
      ...args: T
    ) => Observable<Pick<Service, K>>,
    ...args: T
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return serviceMethod(organizationID, ...args).pipe(
      catchError((error: any) => {
        this.toastService.showError(error);

        return throwError(() => error);
      })
    );
  }
}
