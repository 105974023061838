import { Component } from '@angular/core';
import { SharedCommonModule } from 'src/app/core/common.module';

@Component({
  standalone: true,
  imports: [SharedCommonModule],
  selector: 'app-dialog-update-subscription',
  templateUrl: './update-subscription.component.html',
})
export class UpdateSubscriptionDialogComponent {}
