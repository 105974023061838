import { inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  Configuration,
  FrontendApi,
  LoginFlow,
  RecoveryFlow,
  RegistrationFlow,
  Session,
  SettingsFlow,
  UpdateLoginFlowBody,
  UpdateRecoveryFlowWithCodeMethod,
  UpdateRegistrationFlowBody,
  UpdateSettingsFlowBody,
} from '@ory/client';
import { Apollo, gql } from 'apollo-angular';
import { CookieService } from 'ngx-cookie-service';
import { firstValueFrom, map, Observable, Subscription } from 'rxjs';
import {
  Organization,
  OrganizationMemberRole,
} from 'src/app/models/organization';
import { TrackUserRegisteredEventAction } from 'src/app/store/tracking/tracking.actions';
import { SetSessionAction } from 'src/app/store/user/user.actions';
import { UserState } from 'src/app/store/user/user.state';
import { environment } from 'src/environments/environment';
import {
  ErrAlreadyLoggedIn,
  ErrBrowserLocationChangeRequired,
  ErrReauthenticationNeeded,
  ErrTwoFARequired,
} from './kratos.errors';

const GET_INVITATION_QUERY = gql`
  query invitation($token: String!) {
    invitation(token: $token) {
      id
      email
      token
      organization {
        id
        name
      }
    }
  }
`;

export interface Invitation {
  id: string;
  email: string;
  role: OrganizationMemberRole;
  token: string;
  validUntil: Date;
  organization: Organization;
}

export interface RegistrationOpts {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
}

export interface UserProfileTraits {
  firstname: string;
  lastname: string;
}

@Injectable({
  providedIn: 'root',
})
export class KratosAuthenticationService {
  private authFrontend: FrontendApi;

  private meRef?: Subscription;

  private store = inject(Store);
  private cookies = inject(CookieService);

  constructor(private apollo: Apollo) {
    this.authFrontend = new FrontendApi(
      new Configuration({
        basePath: environment.kratosEndpoint,
        baseOptions: {
          // send cookies
          withCredentials: true,
        },
      })
    );
  }

  public isSessionValid(): boolean {
    return this.cookies.check('ory_kratos_session');
  }

  public async whoami(): Promise<Session> {
    try {
      const res = await this.authFrontend.toSession();
      return res.data;
    } catch (err) {
      this.convertKratosError(err);
      throw err;
    }
  }

  public async setSessionFromWhoami() {
    const session = await this.whoami();
    this.setSession(session);
    return session;
  }

  public getInvitation(token: string): Observable<Invitation> {
    return this.apollo
      .query<{ invitation: Invitation }>({
        query: GET_INVITATION_QUERY,
        variables: {
          token: token,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.invitation;
        })
      );
  }

  private convertKratosError(err: any) {
    if (err.response?.data?.error) {
      switch (err.response.data.error.id) {
        case 'session_refresh_required':
          throw new ErrReauthenticationNeeded();
        case 'session_already_available':
          throw new ErrAlreadyLoggedIn();
        case 'session_aal2_required':
          throw new ErrTwoFARequired();
        case 'browser_location_change_required':
          throw new ErrBrowserLocationChangeRequired(
            err.response.data.redirect_browser_to
          );
      }
    }
  }

  async createLoginFlow(aal = 'aal1', returnTo?: string): Promise<LoginFlow> {
    const flow = await this.authFrontend.createBrowserLoginFlow({
      refresh: true,
      aal: aal,
      returnTo: returnTo,
    });

    return flow.data;
  }

  async getLoginFlow(flowId: string): Promise<LoginFlow> {
    const flow = await this.authFrontend.getLoginFlow({
      id: flowId,
    });

    return flow.data;
  }

  async getSettingsFlow(): Promise<SettingsFlow> {
    try {
      const result = await this.authFrontend.createBrowserSettingsFlow();
      return result.data;
    } catch (ex) {
      this.convertKratosError(ex);
      throw ex;
    }
  }

  async completeSettingsFlow(
    flowId: string,
    method: string,
    settings: UpdateSettingsFlowBody
  ) {
    try {
      await this.authFrontend.updateSettingsFlow({
        flow: flowId,
        updateSettingsFlowBody: {
          ...settings,
          method: method,
        },
      } as any);
    } catch (ex: any) {
      this.convertKratosError(ex);

      throw ex;
    }
  }

  async resendVerificationMail(): Promise<any> {
    const flow = await this.authFrontend.createBrowserVerificationFlow({});

    const csrfTokenNode = flow.data.ui.nodes.find(
      n => (n.attributes as any).name == 'csrf_token'
    );

    const user = await firstValueFrom(this.store.selectOnce(UserState.getUser));
    if (!user) {
      throw new Error('No user');
    }

    const res = await this.authFrontend.updateVerificationFlow({
      flow: flow.data.id,
      updateVerificationFlowBody: {
        email: user.email,
        method: 'code',
        csrf_token: (csrfTokenNode!.attributes as any).value,
      },
    });

    return res;
  }

  async getRecoveryFlow(): Promise<RecoveryFlow | undefined> {
    try {
      const flow = await this.authFrontend.createBrowserRecoveryFlow();
      return flow.data;
    } catch (ex: any) {
      this.convertKratosError(ex);
      throw ex;
    }
  }

  async completeRecoveryFlow(
    flow: RecoveryFlow,
    body: UpdateRecoveryFlowWithCodeMethod
  ): Promise<RecoveryFlow> {
    try {
      const res = await this.authFrontend.updateRecoveryFlow({
        flow: flow.id,
        updateRecoveryFlowBody: {
          ...body,
          method: 'code',
        },
      });

      return res.data;
    } catch (ex) {
      this.convertKratosError(ex);
      throw ex;
    }
  }

  async createRegistrationFlow(
    returnTo?: string
  ): Promise<RegistrationFlow | undefined> {
    try {
      const flow = await this.authFrontend.createBrowserRegistrationFlow({
        returnTo: returnTo,
      });
      return flow.data;
    } catch (ex: any) {
      this.convertKratosError(ex);

      return undefined;
    }
  }

  async getRegistrationFlow(flowId: string): Promise<LoginFlow> {
    const flow = await this.authFrontend.getRegistrationFlow({
      id: flowId,
    });

    return flow.data;
  }

  async completeRegistrationFlowV2(
    flow: RegistrationFlow,
    body: UpdateRegistrationFlowBody
  ) {
    try {
      const res = await this.authFrontend.updateRegistrationFlow({
        flow: flow.id,
        updateRegistrationFlowBody: body,
      });

      // set current session
      this.setSession(res.data.session!);

      this.store.dispatch(
        new TrackUserRegisteredEventAction(res.data.identity.id)
      );
    } catch (ex) {
      this.convertKratosError(ex);
      throw ex;
    }
  }

  async completeLoginFlowV2(
    flow: LoginFlow,
    method: string,
    body: UpdateLoginFlowBody
  ): Promise<Session> {
    try {
      await this.authFrontend.updateLoginFlow({
        flow: flow.id,
        updateLoginFlowBody: {
          ...body,
          method: method as any,
        },
      });

      const session = await this.whoami();
      this.setSession(session);

      return session;
    } catch (ex: any) {
      this.convertKratosError(ex);
      throw ex;
    }
  }

  async logout() {
    try {
      const logoutFlow = await this.authFrontend.createBrowserLogoutFlow();
      await this.authFrontend.updateLogoutFlow({
        token: logoutFlow.data.logout_token,
      });
    } catch (err) {
      console.warn('failed to logout', err);
    }

    // clear apollo cache
    await this.apollo.client.clearStore();

    this.clear();
  }

  async clear() {
    // clear reference
    this.meRef?.unsubscribe();
    this.meRef = undefined;
  }

  async completeVerificationFlow(flowId: string, code: string) {
    await this.authFrontend.updateVerificationFlow({
      flow: flowId,
      updateVerificationFlowBody: {
        code: code,
      },
    } as any);
  }

  private setSession(session: Session) {
    this.store.dispatch(
      new SetSessionAction({
        id: session.id,
        issuedAt: new Date(Date.parse(session.issued_at!)),
        expiresAt: new Date(Date.parse(session.expires_at!)),
        authenticatedAt: new Date(Date.parse(session.authenticated_at!)),
        assuranceLevel: session.authenticator_assurance_level!,
        fullSession: session,
      })
    );
  }
}
