<div
  class="vops-service-state"
  [ngClass]="{
    operational: state === 'OPERATIONAL',
    degraded: state === 'DEGRADED',
    down: state === 'DOWN'
  }">
  <div class="indicator"></div>
  <span>{{ serviceStateText | translate }}</span>
</div>
