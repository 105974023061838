import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoginFlow, Session, UpdateLoginFlowBody } from '@ory/client';
import { SharedCommonModule } from 'src/app/core/common.module';
import { KratosFormComponent } from 'src/app/core/components/kratos/form.component';
import { VKitCardModule } from 'src/app/core/components/ui-kit/card/card.module';
import {
  ErrBrowserLocationChangeRequired,
  ErrTwoFARequired,
} from 'src/app/services/authentication/kratos.errors';
import { KratosAuthenticationService } from 'src/app/services/authentication/kratos.service';
import { OAuth2ConfirmLoginChallengeAction } from 'src/app/store/oauth2/oauth2.actions';
import { TrackUserLoggedInEventAction } from 'src/app/store/tracking/tracking.actions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-page',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [SharedCommonModule, KratosFormComponent, VKitCardModule],
  standalone: true,
})
export class LoginPageComponent implements OnInit {
  private auth = inject(KratosAuthenticationService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private store = inject(Store);

  aal: 'aal1' | 'aal2' = 'aal1';
  returnTo = '/';

  loginFlow?: LoginFlow;
  twoFAFlow?: LoginFlow;

  initialFlowLoading = true;

  isOAuth2Flow = false;
  oauth2LoginChallenge?: string;

  // when a user logs in with sso he will be redirected to kratos self service first
  // to complete the login flow. After that he will be redirected back to our login page.
  // the check parameter is used to check if the user is then logged in.
  oauth2ReturnUrl = `${environment.frontendUrl}/login?check=whoami`;

  isCompletingLoginFlow = false;
  isCompletingTwoFAFlow = false;

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      // redirect to returnTo parameter after successful login
      if (params['returnTo']) {
        this.returnTo = params['returnTo'];
      }

      if (params['login_challenge']) {
        this.isOAuth2Flow = true;
        this.oauth2LoginChallenge = params['login_challenge'];

        // in case we are acting as an oauth2 provider we need to persist the login_challenge
        // accross the redirection process to the kratos self service
        this.oauth2ReturnUrl = `${environment.frontendUrl}/login?login_challenge=${params['login_challenge']}&check=whoami`;
      }

      if (params['check'] === 'whoami') {
        this.auth.setSessionFromWhoami().then(session => {
          this.onLoginCompleted(session);
        });
      } else {
        if (params['flow']) {
          this.auth.getLoginFlow(params['flow']).then(flow => {
            if (flow.requested_aal === 'aal2') {
              this.aal = 'aal2';
              this.twoFAFlow = flow;

              // when the user has enabled two factor authentication kratos self service
              // will redirect to /login?flow=xxx as the login flow is not yet completed.
              // however in this case there will be a return_to parameter which contains the
              // original oauth2 login challenge. we switch to the oauth2 flow in this case.
              if (flow.return_to) {
                const url = new URL(flow.return_to);
                const loginChallenge = url.searchParams.get('login_challenge');
                if (loginChallenge) {
                  this.isOAuth2Flow = true;
                  this.oauth2LoginChallenge = loginChallenge;
                }
              }
            } else {
              this.loginFlow = flow;
            }

            this.initialFlowLoading = false;
          });
        } else {
          // check wether we need assurance level 1 (password) or level 2 (two factor)
          if (params['aal'] && params['aal'] === 'aal2') {
            this.aal = 'aal2';

            // get two factor flow
            this.auth.createLoginFlow('aal2').then(twoFAFlow => {
              this.initialFlowLoading = false;
              this.twoFAFlow = twoFAFlow;
            });
          } else {
            // get password flow
            this.auth
              .createLoginFlow('aal1', this.oauth2ReturnUrl)
              .then(flow => {
                this.initialFlowLoading = false;
                this.loginFlow = flow;
              });
          }
        }
      }
    });
  }

  async submitFlow(data: UpdateLoginFlowBody) {
    this.isCompletingLoginFlow = true;
    await this.auth
      .completeLoginFlowV2(this.loginFlow!, data.method, data)
      .then(session => {
        this.onLoginCompleted(session);
      })
      .catch(err => {
        if (err instanceof ErrTwoFARequired) {
          this.router.navigate(['/login'], { queryParams: { aal: 'aal2' } });
        } else if (err instanceof ErrBrowserLocationChangeRequired) {
          window.location.href = err.redirectUri;
        } else {
          this.loginFlow = err.response.data;
        }
      })
      .finally(() => {
        this.isCompletingLoginFlow = false;
      });
  }

  async submitTwoFAFlow(data: UpdateLoginFlowBody) {
    this.isCompletingTwoFAFlow = true;
    await this.auth
      .completeLoginFlowV2(this.twoFAFlow!, data.method, data)
      .then(session => {
        this.onLoginCompleted(session);
      })
      .catch(err => {
        this.twoFAFlow = err.response.data;
      })
      .finally(() => {
        this.isCompletingTwoFAFlow = false;
      });
  }

  onLoginCompleted(session: Session) {
    this.store.dispatch(new TrackUserLoggedInEventAction(session.identity!.id));

    if (this.isOAuth2Flow) {
      this.store.dispatch(
        new OAuth2ConfirmLoginChallengeAction(this.oauth2LoginChallenge!)
      );
    } else {
      this.router.navigate([this.returnTo]);
    }
  }
}
