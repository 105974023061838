import { gql } from 'apollo-angular';

export const PROBE_CONFIGURATION_FRAGMENT = gql`
  fragment ProbeConfiguration on ProbeConfiguration {
    ... on ICMPProbeConfiguration {
      timeoutSeconds
      address
    }
    ... on HTTPProbeConfiguration {
      address
      headers
      validStatusCodes
      timeoutSeconds
      method
    }
  }
`;

export const PROBE_RESULT_FRAGMENT = gql`
  fragment ProbeResult on ProbeResultUnion {
    ... on ICMPProbeResult {
      packetLossPercent
      minRttMs
      avgRttMs
      maxRttMs
      host
    }
    ... on HTTPProbeResult {
      address
      durationMs
      statusCode
      redirectCount
      isSSL
      ipProtocolVersion
      httpVersion
      tlsInformation {
        version
        earliestCertExpiry
        certificateInformation {
          issuer
          subject
          subjectAlternativeNames
        }
      }
      timingInformation {
        resolveMs
        connectMs
        tlsHandshakeMs
        ttfbMs
        transferMs
      }
    }
  }
`;

export const MONITOR_DETAILS_FRAGMENT = gql`
  fragment MonitorDetails on Monitor {
    id
    name
    service {
      id
      name
    }
    scheduling {
      intervalSeconds
      retries
    }
    probeType
    probeConfiguration {
      ...ProbeConfiguration
    }
    probeResults(pageSize: 20) {
      metadata {
        id
        timestamp
        error
        succeeded
      }
      result {
        ...ProbeResult
      }
    }
    statistics(options: $statisticsOptions) {
      availability {
        windowStart
        windowEnd
        uptimeSeconds
        downtimeSeconds
        availabilityPercentage
        totalMonitoredSeconds
        totalWindowSeconds
      }
    }
  }

  ${PROBE_CONFIGURATION_FRAGMENT}
  ${PROBE_RESULT_FRAGMENT}
`;
