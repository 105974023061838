import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  ControlContainer,
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormErrorComponent } from '../utils/form-error/form-error.component';

@Component({
  selector: 'v-switch',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    FormErrorComponent,
    InputSwitchModule,
  ],
  templateUrl: './switch.component.html',
  styleUrl: './switch.component.scss',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class VSwitchInputComponent {
  /**
   * Optional form control name
   */
  @Input()
  fControlName?: string;

  /**
   * Optionally sets the test id for the input
   */
  @Input()
  testId?: string;
}
