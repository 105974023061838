import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StepsComponent } from './steps.component';
import { StepComponent } from './step.component';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [StepsComponent, StepComponent],
  imports: [CommonModule, ButtonModule, TranslateModule],
  exports: [StepsComponent, StepComponent],
})
export class StepsModule {}
