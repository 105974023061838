import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

export type DataListEntry = {
  label: string;
  value?: any;
};

export type DataListLayout = 'grid' | 'list';

@Component({
  selector: 'v-description-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './description-list.component.html',
  styleUrl: './description-list.component.scss',
})
export class VDescriptionListComponent {
  @Input() size: 'sm' | 'md' | 'lg' = 'md';

  @Input() layout: DataListLayout = 'list';

  @Input() entries: DataListEntry[] = [];
}
