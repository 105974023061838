import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { VCardComponent } from '../card/card.component';

@Component({
  selector: 'v-danger-zone',
  standalone: true,
  imports: [CommonModule, VCardComponent],
  templateUrl: './danger-zone.component.html',
})
export class VDangerZoneComponent {
  /**
   * How much padding to apply to the card
   */
  @Input() padding: 'sm' | 'md' | 'lg' = 'md';
}
