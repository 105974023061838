import { Injectable } from '@angular/core';
import { captureException } from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root',
})
export class ObservabilityService {
  public captureException(error: Error) {
    captureException(error);
  }
}
