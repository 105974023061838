import { inject, Injectable } from '@angular/core';
import {
  Action,
  createSelector,
  NgxsOnInit,
  State,
  StateContext,
} from '@ngxs/store';
import {
  EnvironmentService,
  ScreenSize,
} from 'src/app/services/environment/environment.service';
import { UpdateUISettingAction } from './ui-settings.actions';

export interface UISettingsStateModel {
  isSidemenuCollapsed?: boolean;
  isSidemenuPinned?: boolean;
}

@State<UISettingsStateModel>({
  name: 'uisettings',
  defaults: {},
})
@Injectable()
export class UISettingsState implements NgxsOnInit {
  environmentService = inject(EnvironmentService);

  static setting(settingName: keyof UISettingsStateModel) {
    return createSelector([UISettingsState], (state: UISettingsStateModel) => {
      return state[settingName];
    });
  }

  @Action(UpdateUISettingAction)
  updateUISetting(
    ctx: StateContext<UISettingsStateModel>,
    { setting, value }: UpdateUISettingAction<keyof UISettingsStateModel>
  ) {
    ctx.patchState({
      [setting]: value,
    });
  }

  ngxsOnInit(ctx: StateContext<UISettingsStateModel>) {
    this.environmentService.settings$.subscribe(settings => {
      if (!ctx.getState().isSidemenuPinned) {
        const isCollapsed = settings.screenSize <= ScreenSize.SmallDesktop;

        ctx.patchState({
          isSidemenuCollapsed: isCollapsed,
        });
      }
    });
  }
}
