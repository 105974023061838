import { Component, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { SharedCommonModule } from 'src/app/core/common.module';
import { ComponentsModule } from 'src/app/core/components/components.module';
import { VButtonComponent } from 'src/app/core/components/ui-kit/buttons/button/button.component';
import { VKitCardModule } from 'src/app/core/components/ui-kit/card/card.module';
import { UserMetadata } from 'src/app/core/components/ui-kit/sidemenu/sidemenu.component';
import {
  Invitation,
  KratosAuthenticationService,
} from 'src/app/services/authentication/kratos.service';
import {
  LoadUserAction,
  LogoutAction,
  SelectOrganizationAction,
} from 'src/app/store/user/user.actions';
import { UserState } from 'src/app/store/user/user.state';

interface VM {
  isLoading: boolean;
  user?: UserMetadata;
  isAlreadyMember?: boolean;
}

const JOIN_ORGANIZATION_MUTATION = gql`
  mutation JoinOrganization($token: String!) {
    joinOrganization(token: $token) {
      id
    }
  }
`;

@Component({
  selector: 'app-page-join-organization',
  standalone: true,
  imports: [
    SharedCommonModule,
    VKitCardModule,
    VButtonComponent,
    ComponentsModule,
  ],
  templateUrl: './join-organization.component.html',
})
export class JoinOrganizationPageComponent {
  private auth = inject(KratosAuthenticationService);
  private apollo = inject(Apollo);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private store = inject(Store);

  invitation?: Invitation;
  isJoining = false;

  constructor() {
    this.route.queryParams.subscribe(params => {
      this.loadInvitation(params['token']);
    });
  }

  vm$: Observable<VM> = this.store.select(UserState.getUser).pipe(
    map(user => {
      if (!user) {
        return {
          isLoading: true,
        };
      }

      return {
        isLoading: false,
        user: {
          avatar: user.avatar,
          name: user.firstname + ' ' + user?.lastname,
          email: user.email,
        },
        isAlreadyMember:
          user.memberships.find(membership => {
            return (
              membership.organizationId === this.invitation?.organization.id
            );
          }) !== undefined,
      };
    })
  );

  joinOrgForm = new FormGroup({});
  joinOrganizationError?: string;

  loadInvitation(token: string) {
    this.auth.getInvitation(token).subscribe({
      next: invitation => {
        this.invitation = invitation;
      },
    });

    this.store.dispatch(new LoadUserAction());
  }

  onFormSubmit() {
    if (!this.joinOrgForm.valid) return;

    this.joinOrganizationError = undefined;
    this.joinOrgForm.disable();

    this.isJoining = true;

    this.apollo
      .mutate({
        mutation: JOIN_ORGANIZATION_MUTATION,
        variables: {
          token: this.invitation?.token,
        },
      })
      .subscribe({
        next: () => {
          this.store.dispatch(new LoadUserAction()).subscribe({
            next: () => {
              this.router.navigate(['/']);
            },
          });
        },
        error: err => {
          this.isJoining = false;
          this.joinOrganizationError = err.toString();
          this.joinOrgForm.enable();
        },
      });
  }

  goToDashboard() {
    this.store
      .dispatch(new SelectOrganizationAction(this.invitation!.organization.id))
      .subscribe({
        next: () => {
          this.router.navigate(['/']);
        },
      });
  }

  logout() {
    this.store.dispatch(
      new LogoutAction('/register', {
        token: this.invitation?.token,
      })
    );
  }
}
