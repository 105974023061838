<div class="vops-chip" [class.deletable]="showDelete">
  <div>
    <span>
      <div>
        <i [class]="'pi ' + icon" *ngIf="icon"></i>
        <img [src]="image" *ngIf="image" alt="avatar" />
      </div>
      <a [href]="link" target="_blank" *ngIf="link">{{ label }}</a>
      <a [routerLink]="link" *ngIf="routerLink">{{ label }}</a>
      <span *ngIf="!link && !routerLink">{{ label }}</span>
    </span>
    <button *ngIf="showDelete" (click)="onClickDelete()">
      <i class="pi pi-times-circle"></i>
    </button>
  </div>
</div>
