import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import {
  ControlContainer,
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';

@Component({
  selector: 'v-multiselect',
  standalone: true,
  imports: [CommonModule, MultiSelectModule, FormsModule, ReactiveFormsModule],
  styleUrl: './multiselect.component.scss',
  templateUrl: './multiselect.component.html',
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class VMultiselectComponent {
  /**
   * Options to display in the dropdown
   */
  @Input()
  public options: any[] = [];

  /**
   * Placeholder text to display
   */
  @Input()
  public placeholder: string = 'Select';

  /**
   * Optional form control name
   */
  @Input()
  fControlName?: string;

  /**
   * The key to use for the option label
   */
  @Input()
  optionLabel: string = 'label';

  /**
   * The key to use for the option value
   */
  @Input()
  optionValue: string = 'value';

  @Input()
  appendDropdownTo?: any;
}
