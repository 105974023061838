import { StatusIndicatorStatus } from '../core/components/ui-kit/status-indicator/status-indicator.component';
import { Incident } from './incident';
import { Monitor } from './monitor';
import { Statistic } from './statistics';
import { Team } from './team';

export interface Service {
  id: number;
  name: string;
  description: string;
  incidents?: {
    totalSize: number;
    incidents: Incident[];
  };
  monitors?: {
    totalSize: number;
    monitors: Monitor[];
  };
  owner?: Team;
  state?: ServiceState;
  isDeleted: boolean;
  deletedAt?: Date;
}

export interface ServiceStatistics {
  impactDuration: Statistic;
  mttr: Statistic;
  mtta: Statistic;
}

export enum ServiceState {
  OPERATIONAL = 'OPERATIONAL',
  DEGRADED = 'DEGRADED',
  DOWN = 'DOWN',
}

export const ServiceStateToStatus = (
  state: ServiceState
): StatusIndicatorStatus => {
  switch (state) {
    case ServiceState.OPERATIONAL:
      return 'success';
    case ServiceState.DEGRADED:
      return 'warning';
    case ServiceState.DOWN:
      return 'error';
  }
};

export const ServiceStateToText = (state: ServiceState): string => {
  switch (state) {
    case ServiceState.OPERATIONAL:
      return 'Operational';
    case ServiceState.DEGRADED:
      return 'Degraded';
    case ServiceState.DOWN:
      return 'Down';
    default:
      return 'Unknown';
  }
};
