import { Injector, NgModule, Optional, SkipSelf } from '@angular/core';

import { MessageService } from 'primeng/api';
import { VBadgeComponent } from './badge/badge.component';
import { VButtonsModule } from './buttons/buttons.module';
import { VKitCardModule } from './card/card.module';
import { VCopyFieldComponent } from './copy-field/copy-field.component';
import { VDangerZoneComponent } from './danger-zone/danger-zone.component';
import { VDataDisplayModule } from './data-display/data-display.module';
import { VDescriptionListComponent } from './description-list/description-list.component';
import { VKitDialogModule } from './dialogs/dialog.module';
import { VDropdownComponent } from './dropdown/dropdown.component';
import { VKitHeadersModule } from './headers/headers.module';
import { VInputFieldComponent } from './input-field/input-field.component';
import { VInputComponent } from './input/input.component';
import { VKeyValueEditorComponent } from './kv-editor/kv-editor.component';
import { VLayoutFullscreenComponent } from './layouts/fullscreen/fullscreen.component';
import { VLayoutMasterDetailComponent } from './layouts/master-detail/master-detail.component';
import { VMenuComponent } from './menu/menu.component';
import { VMessageComponent } from './message/message.component';
import { VMultiselectComponent } from './multiselect/multiselect.component';
import { VPaginatorComponent } from './paginator/paginator.component';
import { VRadioGroupComponent } from './radio-group/radio-group.component';
import { VSecretInputComponent } from './secret-input/secret-input.component';
import { VToastService } from './services/toast.service';
import { VSideBarComponent } from './sidebar/sidebar.component';
import { VSideMenuComponent } from './sidemenu/sidemenu.component';
import { VSSOButtonComponent } from './sso-button/sso-button.component';
import { VStatusIndicatorComponent } from './status-indicator/status-indicator.component';
import { VSwitchInputComponent } from './switch/switch.component';
import { VTextareaComponent } from './textarea/textarea.component';
import { VUserCardComponent } from './user-card/user-card.component';

@NgModule({
  imports: [
    VInputComponent,
    VTextareaComponent,
    VInputFieldComponent,
    VDropdownComponent,
    VMultiselectComponent,
    VButtonsModule,
    VSSOButtonComponent,
    VKitCardModule,
    VSideMenuComponent,
    VMenuComponent,
    VBadgeComponent,
    VUserCardComponent,
    VMessageComponent,
    VCopyFieldComponent,
    VSecretInputComponent,
    VSwitchInputComponent,
    VLayoutMasterDetailComponent,
    VLayoutFullscreenComponent,
    VKitHeadersModule,
    VDataDisplayModule,
    VStatusIndicatorComponent,
    VDangerZoneComponent,
    VDescriptionListComponent,
    VSideBarComponent,
    VPaginatorComponent,
    VRadioGroupComponent,
    VKeyValueEditorComponent,
    VKitDialogModule,
  ],
  exports: [
    VInputComponent,
    VTextareaComponent,
    VInputFieldComponent,
    VDropdownComponent,
    VMultiselectComponent,
    VButtonsModule,
    VSSOButtonComponent,
    VKitCardModule,
    VSideMenuComponent,
    VMenuComponent,
    VBadgeComponent,
    VUserCardComponent,
    VMessageComponent,
    VCopyFieldComponent,
    VSecretInputComponent,
    VSwitchInputComponent,
    VLayoutMasterDetailComponent,
    VLayoutFullscreenComponent,
    VKitHeadersModule,
    VDataDisplayModule,
    VStatusIndicatorComponent,
    VDangerZoneComponent,
    VDescriptionListComponent,
    VSideBarComponent,
    VPaginatorComponent,
    VRadioGroupComponent,
    VKeyValueEditorComponent,
  ],
  providers: [
    VToastService,
    // only create a new instance of MessageService in case
    // that there is none in the root module.
    {
      provide: MessageService,
      useFactory: (
        parentInjector: Injector,
        messageService?: MessageService
      ) => {
        if (!messageService) {
          const injector = Injector.create({
            providers: [{ provide: MessageService }],
            parent: parentInjector,
          });
          messageService = injector.get(MessageService);
        }

        return messageService;
      },
      deps: [Injector, [new Optional(), new SkipSelf(), MessageService]],
    },
  ],
})
export class VKitModule {}
