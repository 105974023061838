<div class="v-input-field">
  <header>
    <label for="explicit-label-name" class="v-input-label">{{ label }}</label>
    <ng-teplate
      *ngIf="headerComponent"
      [ngTemplateOutlet]="headerComponent"></ng-teplate>
  </header>
  <ng-content></ng-content>
  <v-form-error-component [control]="control"></v-form-error-component>
</div>
