import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { map } from 'rxjs';
import { toIdentifiableEntities } from 'src/app/helpers/graphql';
import { Statistic } from 'src/app/models/statistics';
import { ToastService } from 'src/app/services/toast/toast.service';
import {
  ListEntities,
  loadEntities,
  NewListEntities,
} from 'src/app/store/common/list-entity';
import { UserState } from 'src/app/store/user/user.state';
import { LoadIncidentsStatisticsAction } from './statistics.actions';
import { StatisticsService } from './statistics.service';

export interface StatisticsStateModel {
  incidentsStatistics: ListEntities<Statistic>;
}

@State<StatisticsStateModel>({
  name: 'statistics',
  defaults: {
    incidentsStatistics: NewListEntities(),
  },
})
@Injectable()
export class StatisticsState {
  store = inject(Store);
  toast = inject(ToastService);
  statisticsService = inject(StatisticsService);

  @Selector()
  static isLoading(state: StatisticsStateModel) {
    return state.incidentsStatistics.isLoading || false;
  }

  @Selector()
  static activeIncidents(state: StatisticsStateModel) {
    return state.incidentsStatistics.entities['active-incidents'];
  }

  @Selector()
  static resolvedIncidents(state: StatisticsStateModel) {
    return state.incidentsStatistics.entities['resolved-incidents'];
  }

  @Selector()
  static totalImpactDuration(state: StatisticsStateModel) {
    return state.incidentsStatistics.entities['impact-duration'];
  }

  @Selector()
  static mtta(state: StatisticsStateModel) {
    return state.incidentsStatistics.entities['mtta'];
  }

  @Selector()
  static mttr(state: StatisticsStateModel) {
    return state.incidentsStatistics.entities['mttr'];
  }

  @Action(LoadIncidentsStatisticsAction)
  loadStatuspages(
    ctx: StateContext<StatisticsStateModel>,
    { days }: LoadIncidentsStatisticsAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    const observable = this.statisticsService.loadStatistics(
      organizationID,
      days
    );

    return loadEntities(
      ctx,
      'incidentsStatistics',
      observable.pipe(
        map((statistics: Statistic[]) => {
          return toIdentifiableEntities(statistics, 'name');
        })
      ),
      {
        clear: true,
      }
    );
  }
}
