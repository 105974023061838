import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { OAuth2Service } from './oauth2.service';
import { OAuth2State } from './oauth2.state';

@NgModule({
  declarations: [],
  imports: [NgxsModule.forFeature([OAuth2State])],
  providers: [OAuth2Service],
  bootstrap: [],
  exports: [],
})
export class OAuth2StateModule {}
