import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { SharedCommonModule } from '../core/common.module';
import { ComponentsModule } from '../core/components/components.module';
import { DialogsModule } from '../core/dialogs/dialogs.module';
import { InternalModule } from './internal/internal.module';
import { PublicModule } from './public/public.module';
import { routes } from './routes';

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, { enableViewTransitions: true }),
    SharedCommonModule,
    PublicModule,
    InternalModule,
    ComponentsModule,
    DialogsModule,
  ],
  providers: [DialogService],
})
export class PagesModule {}
