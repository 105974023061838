import { NgModule } from '@angular/core';

import { VCardImageHeaderComponent } from './card-headers/image-header.component';
import { VCardComponent } from './card.component';

@NgModule({
  imports: [VCardComponent, VCardImageHeaderComponent],
  exports: [VCardComponent, VCardImageHeaderComponent],
})
export class VKitCardModule {}
