export class LoadOrganizationAction {
  static readonly type = '[Organization] Load Organization';
  constructor() {}
}

export class LoadOrganizationMembersAction {
  static readonly type = '[Organization] Load Members';
  constructor() {}
}

export class LoadOrganizationInvitationsAction {
  static readonly type = '[Organization] Load Invitations';
  constructor() {}
}

export class CreateInvitationAction {
  static readonly type = '[Organization] Create Invitation';
  constructor(
    public email: string,
    public roleId: string,
    public validUntilDuration: string
  ) {}
}

export class DeleteInvitationAction {
  static readonly type = '[Organization] Delete Invitation';
  constructor(public invitationId: string) {}
}

export class UpdateOrganizationMemberRoleAction {
  static readonly type = '[Organization] Update Organization Member Role';
  constructor(
    public membershipId: string,
    public newRoleId: string
  ) {}
}

export class DeleteMembershipAction {
  static readonly type = '[Organization] Delete Organization Member';
  constructor(public membershipId: string) {}
}
