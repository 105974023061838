<div
  class="w-full grid items-center justify-between"
  [ngClass]="{
    'grid-cols-3': showActions,
    'grid-cols-2': !showActions
  }">
  <div class="col-span-1">
    <h1 class="text-2xl font-semibold text-primary">
      {{ title }}
    </h1>
    <p class="text-sm text-subtle">{{ subtitle }}</p>
  </div>
  <div class="col-span-1 flex justify-center">
    <div class="bg-card p-2.5 rounded-2xl">
      <v-menu [items]="items" direction="horizontal" size="small"></v-menu>
    </div>
  </div>
  @if (showActions) {
    <div class="flex gap-x-2">
      <ng-content></ng-content>
    </div>
  }
</div>
