import { ApolloQueryResult } from '@apollo/client/core';
import { IdentifiableEntities } from '../store/common/list-entity';
import { MutationResult } from 'apollo-angular';
import { ApolloLink } from '@apollo/client/core';

export function mapGQLResponse<T>(
  result: ApolloQueryResult<T> | MutationResult<T>,
  key: keyof T
) {
  if (result.errors) {
    result.errors.forEach(err => {
      if (err.path?.length == 1) {
        throw new Error(err.message);
      }

      // if there is a nested error (path length > 1) set the nested object
      // to the error. The error needs to be extracted manually later on.
      if (err.path) {
        updateNestedObject(result.data, err.path, new Error(err.message));
      }
    });
  }

  if (!result.data) throw Error('no data');

  return result.data[key];
}

export function toIdentifiableEntities<T>(
  items: T[],
  identifier: keyof T
): IdentifiableEntities<T> {
  return items.reduce((entities, item) => {
    entities[item[identifier] as string] = {
      data: item,
    };
    return entities;
  }, {} as IdentifiableEntities<T>);
}

const typePatcher = (data: any) => {
  // This is your chance to walk through your result data and convert scalar types.
  if (data && typeof data === 'object') {
    Object.entries(data).forEach(([key, value]) => {
      if (key === 'createdAt' && typeof value === 'string') {
        // Replace the original string value with a JS Date object.
        data[key] = new Date(Date.parse(value));
      } else {
        typePatcher(value);
      }
    });
  }
};

export const CustomScalarLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const { data } = response;
    typePatcher(data);
    return response;
  });
});

export const updateNestedObject = (
  obj: any,
  keys: readonly (string | number)[],
  newValue: any
) => {
  let current = obj;
  for (let i = 0; i < keys.length; i++) {
    if (i === keys.length - 1) {
      // If we're at the last key in the array, update the value
      current[keys[i]] = newValue;
    } else if (current[keys[i]] !== undefined) {
      // If the key exists, move to the next part of the object
      if (current[keys[i]] == null) {
        current[keys[i]] = {};
      }

      current = current[keys[i]];
    }
  }
};
