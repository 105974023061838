import { inject, Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class VToastService {
  private messageService = inject(MessageService);

  showError(error: Error) {
    this.messageService.add({
      key: 'topright',
      severity: 'error',
      summary: 'Error',
      detail: error.toString(),
    });
  }

  showInfo(header: string, message: string) {
    this.messageService.add({
      key: 'topright',
      severity: 'info',
      summary: header,
      detail: message,
    });
  }
}
