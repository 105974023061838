import { DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VButtonsModule } from '../buttons/buttons.module';
import { VKitDialogService } from './dialog.service';
import { VConfirmationDialogComponent } from './dialogs/confirmation.component';
import { VDialogWrapperComponent } from './wrapper.component';

@NgModule({
  declarations: [VDialogWrapperComponent, VConfirmationDialogComponent],
  imports: [CommonModule, DialogModule, VButtonsModule],
  exports: [],
  providers: [VKitDialogService],
})
export class VKitDialogModule {}
