import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

export type MessageSeverity =
  | 'info'
  | 'warning'
  | 'danger'
  | 'success'
  | 'neutral';

@Component({
  selector: 'v-message',
  standalone: true,
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  imports: [CommonModule],
})
export class VMessageComponent {
  @Input() severity?: MessageSeverity;
}
