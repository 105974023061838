import {
  ChangeSubscriptionOptions,
  Usage,
} from 'src/app/models/subscription-plan';

export class LoadSubscriptionAction {
  static readonly type = '[Subscription] Load';
  constructor(public withDetails = false) {}
}

export class CancelSubscriptionAction {
  static readonly type = '[Subscription] Cancel';
  constructor() {}
}

export class CancelSubscriptionDowngradeAction {
  static readonly type = '[Subscription] Cancel Downgrade';
  constructor() {}
}

export class UnCancelSubscriptionAction {
  static readonly type = '[Subscription] UnCancel';
  constructor() {}
}

export class ChangeSubscriptionAction {
  static readonly type = '[Subscription] Change';
  constructor(public options: ChangeSubscriptionOptions) {}
}

export class LoadAvailablePlansAction {
  static readonly type = '[Subscription] Get Available Plans';
  constructor() {}
}

export class UsageUpdatedAction {
  static readonly type = '[Subscription] Usage Updated';
  constructor(
    public featureName: string,
    public newUsage: Usage
  ) {}
}
