import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  OAuth2ConfirmConsentChallengeAction,
  OAuth2ConfirmLoginChallengeAction,
  OAuth2GetConsentInfoAction,
} from './oauth2.actions';
import { OAuth2Service } from './oauth2.service';
import { catchError, tap } from 'rxjs';
import { OAuth2ConsentInfo } from 'src/app/models/oauth2';
import { Entity, loadEntity, NewEntity } from '../common/entity';
import { ToastService } from 'src/app/services/toast/toast.service';

export interface OAuth2StateModel {
  isAcceptingConsent: boolean;
  currentConsentRequestInfo: Entity<OAuth2ConsentInfo>;
}

@State<OAuth2StateModel>({
  name: 'oauth2',
  children: [],
  defaults: {
    isAcceptingConsent: false,
    currentConsentRequestInfo: NewEntity(),
  },
})
@Injectable()
export class OAuth2State {
  store = inject(Store);
  oauth2Service = inject(OAuth2Service);
  toast = inject(ToastService);

  @Selector()
  static currentConsent(
    state: OAuth2StateModel
  ): Entity<OAuth2ConsentInfo> | undefined {
    return state.currentConsentRequestInfo;
  }

  @Selector()
  static isAcceptingConsent(state: OAuth2StateModel): boolean {
    return state.isAcceptingConsent;
  }

  @Action(OAuth2ConfirmLoginChallengeAction)
  confirmLoginChallenge(
    ctx: StateContext<OAuth2StateModel>,
    { challenge }: OAuth2ConfirmLoginChallengeAction
  ) {
    return this.oauth2Service.confirmLoginChallenge(challenge).pipe(
      tap(redirectUri => {
        window.location.href = redirectUri;
      })
    );
  }

  @Action(OAuth2ConfirmConsentChallengeAction)
  confirmConsentChallenge(
    ctx: StateContext<OAuth2StateModel>,
    { challenge }: OAuth2ConfirmConsentChallengeAction
  ) {
    ctx.patchState({ isAcceptingConsent: true });
    return this.oauth2Service.confirmConsentChallenge(challenge).pipe(
      tap(redirectUri => {
        window.location.href = redirectUri;
      }),
      catchError(err => {
        ctx.patchState({ isAcceptingConsent: false });
        this.toast.showError(new Error(`failed to accept consent: ${err}`));
        throw err;
      })
    );
  }

  @Action(OAuth2GetConsentInfoAction)
  getConsentInfo(
    ctx: StateContext<OAuth2StateModel>,
    { challenge }: OAuth2GetConsentInfoAction
  ) {
    return loadEntity(
      ctx,
      'currentConsentRequestInfo',
      this.oauth2Service.getConsentChallenge(challenge)
    ).pipe(
      tap((consentInfo: OAuth2ConsentInfo) => {
        // if the consent was already accepted or the client does
        // not require consents due to us trusting him, we can safely
        // skip the consent screen here.
        if (consentInfo.skip) {
          this.store.dispatch(
            new OAuth2ConfirmConsentChallengeAction(challenge)
          );
        }
      })
    );
  }
}
