import { NgModule } from '@angular/core';
import { SharedCommonModule } from '../common.module';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { ChipComponent } from './chip/chip.component';
import { EditableTextComponent } from './editable-text/editable-text.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { FeatureWrapperModule } from './feature-wrapper/feature-wrapper.module';
import { ServiceImpactSwitchComponent } from './impact-switch/impact-switch.component';
import { PriorityComponent } from './priority/priority.component';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { ServiceStateComponent } from './service-state/service-state.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { StepsModule } from './steps/steps.module';
import { TopBarComponent } from './topbar/topbar.component';
import { UserChipComponent } from './user-chip/user-chip.component';

@NgModule({
  imports: [
    SharedCommonModule,
    RadioGroupComponent,
    StepsModule,
    ServiceStateComponent,
    FeatureWrapperModule,
  ],
  declarations: [
    SidebarComponent,
    TopBarComponent,
    BreadcrumbsComponent,
    PriorityComponent,
    EditableTextComponent,
    UserChipComponent,
    ChipComponent,
    ServiceImpactSwitchComponent,
    EmptyStateComponent,
  ],
  exports: [
    SidebarComponent,
    TopBarComponent,
    BreadcrumbsComponent,
    PriorityComponent,
    EditableTextComponent,
    UserChipComponent,
    ChipComponent,
    ServiceImpactSwitchComponent,
    RadioGroupComponent,
    StepsModule,
    ServiceStateComponent,
    EmptyStateComponent,
    FeatureWrapperModule,
  ],
})
export class ComponentsModule {}
