<ul class="list-none p-0 m-0">
  <li
    class="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-5 p-2 rounded-md hover:surface-hover cursor-pointer"
    *ngFor="let option of options"
    (click)="selectOption(option)"
    aria-hidden="true">
    <div class="flex items-center">
      <span
        class="mr-3 surface-ground border-radius: 10px inline-flex justify-center items-center"
        style="width: 48px; height: 48px; border-radius: 10px">
        <img
          *ngIf="option.image"
          src="assets/images/{{ option.icon }}"
          style="width: 24px; height: 24px"
          [alt]="option.label" />
        <i *ngIf="option.icon" class="pi {{ option.icon }}"></i>
      </span>
      <div>
        <div class="text-900 font-medium text-lg mb-1">
          {{ option.label }}
        </div>
        <div class="flex items-center">
          <span class="text-600">{{ option.description }}</span>
        </div>
      </div>
    </div>
    <div class="mt-3 md:mt-0">
      <i
        class="pi {{
          option.selectIcon ? option.selectIcon : 'pi-plus'
        }} text-xl text-600"></i>
    </div>
  </li>
</ul>
