import { StateContext, StateOperator } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Observable, catchError, of, tap } from 'rxjs';

export interface Entity<T> {
  isLoading?: boolean;
  loadingError?: string;
  isSaving?: boolean;
  savingError?: string;
  isDeleting?: boolean;
  deletingError?: undefined;
  data?: T;
}

export function NewEntity<T>(): Entity<T> {
  return {
    isLoading: false,
    isSaving: false,
    isDeleting: false,
  };
}

export function saveEntity<T>(
  ctx: StateContext<T>,
  key: keyof T,
  observable: Observable<any>,
  // patch defines wether the entity is replaced or patched
  shouldPatch = false
): Observable<any> {
  ctx.setState(setFields(key, { isSaving: true, savingError: undefined }));

  return observable.pipe(
    tap((entity: any) => {
      if (shouldPatch) {
        ctx.setState(
          setFields(key, {
            isSaving: false,
            data: patch(entity),
          })
        );
      } else {
        ctx.setState(setFields(key, { isSaving: false, data: entity }));
      }
    }),
    catchError((error: any) => {
      ctx.setState(
        setFields(key, { isSaving: false, savingError: error.toString() })
      );
      throw error;
    })
  );
}

export function loadEntity<T>(
  ctx: StateContext<T>,
  key: keyof T,
  observable: Observable<any>,
  refresh = false
): Observable<any> {
  ctx.setState(
    setFields(key, {
      isLoading: true,
      loadingError: undefined,
      ...(refresh ? {} : { data: undefined }),
    })
  );

  return observable.pipe(
    tap((entity: any) => {
      ctx.setState(setFields(key, { isLoading: false, data: entity }));
    }),
    catchError((error: any) => {
      ctx.setState(
        setFields(key, { isLoading: false, loadingError: error.toString() })
      );
      return of(undefined);
    })
  );
}

export function deleteEntity<T>(
  ctx: StateContext<T>,
  key: keyof T,
  observable: Observable<any>,
  refresh = false
): Observable<any> {
  ctx.setState(
    setFields(key, {
      isDeleting: true,
      deletingError: undefined,
      ...(refresh ? {} : { data: undefined }),
    })
  );

  return observable.pipe(
    tap(() => {
      ctx.setState(patch<any>({ [key]: undefined }));
    }),
    catchError((error: any) => {
      ctx.setState(
        setFields(key, { isDeleting: false, deletingError: error.toString() })
      );
      return of(undefined);
    })
  );
}

type EntityFields<T> = {
  [P in keyof Entity<T>]?: Entity<T>[P] | StateOperator<any[P]>;
};

function setFields<T>(
  key: keyof T,
  fields: EntityFields<any>
): StateOperator<T> {
  return patch<any>({
    [key]: patch<EntityFields<any>>({
      ...fields,
    }),
  });
}
