import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { BroascastChannelsStateModule } from '../communication/broadcast-channels.state.module';
import { OrganizationService } from './organization.service';
import { OrganizationDetailsState } from './organization.state';

@NgModule({
  declarations: [],
  imports: [
    BroascastChannelsStateModule,
    NgxsModule.forFeature([OrganizationDetailsState]),
  ],
  providers: [OrganizationService],
  bootstrap: [],
  exports: [],
})
export class OrganizationStateModule {}
