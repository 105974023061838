import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { StatisticsState } from './statistics.state';
import { StatisticsService } from './statistics.service';

@NgModule({
  declarations: [],
  imports: [NgxsModule.forFeature([StatisticsState])],
  providers: [StatisticsService],
  exports: [],
})
export class StatisticsStateModule {}
