<v-card [center]="true" padding="lg" *ngIf="vm$ | async as vm">
  <v-card-image-header
    slot="header"
    [text]="'pages.consent.header' | translate"
    image="/assets/images/logos/incidite-full-white.svg"
    imageClass="!h-7"></v-card-image-header>

  <div slot="body" *ngIf="vm.currentConsent.data as consent">
    @if (consent.skip) {
      <!-- Only show a spinner -->
      <div class="flex flex-col items-center justify-center h-10rem">
        <i class="pi pi-spin pi-spinner text-2xl"></i>
      </div>
    } @else {
      <!-- Show more detailed information about the consent request -->
      <div class="text-center flex flex-col items-center v-text-primary">
        <h3 class="text-2xl">{{ consent.client.name || consent.client.id }}</h3>
        <div>{{ 'pages.consent.message' | translate }}</div>

        <div>
          <ul>
            @for (scope of consent.requestedScopes; track scope) {
              <li>{{ scope }}</li>
            }
          </ul>
        </div>
      </div>
    }
  </div>

  <div slot="footer">
    @if (!vm.currentConsent.data?.skip) {
      <div class="flex justify-end">
        <v-button
          (press)="onConsent()"
          [label]="'pages.consent.consentBtn' | translate"
          [loading]="vm.isLoading"></v-button>
      </div>
    }
  </div>
</v-card>
