import { Routes } from '@angular/router';
import { OAuth2ConsentPageComponent } from './consent/consent.component';
import { JoinOrganizationPageComponent } from './join-organization/join-organization.component';
import { LoginPageComponent } from './login/login.component';
import { MaintenancePageComponent } from './maintenance/maintenance.component';
import { NewOrganizationPageComponent } from './new-organization/new-organization.component';
import { PublicPageComponent } from './public.component';
import { RecoveryPageComponent } from './recovery/recovery.component';
import { RegisterPageComponent } from './register/register.component';
import { VerificationPageComponent } from './verification/verification.component';

export const PublicRoutes: Routes = [
  {
    path: '',
    component: PublicPageComponent,
    children: [
      {
        path: 'maintenance',
        component: MaintenancePageComponent,
      },
      {
        path: 'login',
        component: LoginPageComponent,
      },
      {
        path: 'register',
        component: RegisterPageComponent,
      },
      {
        path: 'recovery',
        component: RecoveryPageComponent,
      },
      {
        path: 'self-service/verification',
        component: VerificationPageComponent,
      },
      {
        path: 'organizations/new',
        component: NewOrganizationPageComponent,
        data: {
          title: 'New Organization',
          icon: 'pi-home',
        },
      },
      {
        path: 'organizations/join',
        component: JoinOrganizationPageComponent,
      },
      {
        path: 'consent',
        component: OAuth2ConsentPageComponent,
      },
    ],
  },
];
