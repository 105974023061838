import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  FeatureFlags,
  FeaturesState,
} from 'src/app/store/features/features.state';

export const RequiredFeatureAnnotation = 'requiredFeature';

// FeatureFlagGuard checks wether a user has the required feature enabled to access a route.
export const FeatureFlagGuard = async (snapshot: ActivatedRouteSnapshot) => {
  const store = inject(Store);

  if (!snapshot.data || !snapshot.data[RequiredFeatureAnnotation]) return true;

  const flags = store.selectSnapshot(FeaturesState.flags) || {};

  return HasFlag(flags, snapshot.data[RequiredFeatureAnnotation]);
};

export const HasFlag = (flags: FeatureFlags, requiredFeatures: string[]) => {
  // check wether a user has all of the required features enabled
  for (const rp of requiredFeatures) {
    if (flags[rp]) {
      return true;
    }
  }

  return false;
};
