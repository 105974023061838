import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  RecoveryFlow,
  SettingsFlow,
  UpdateRecoveryFlowBody,
  UpdateSettingsFlowWithPasswordMethod,
} from '@ory/client';
import { SharedCommonModule } from 'src/app/core/common.module';
import { KratosFormComponent } from 'src/app/core/components/kratos/form.component';
import { VKitCardModule } from 'src/app/core/components/ui-kit/card/card.module';
import {
  ErrAlreadyLoggedIn,
  ErrBrowserLocationChangeRequired,
  ErrTwoFARequired,
} from 'src/app/services/authentication/kratos.errors';
import { KratosAuthenticationService } from 'src/app/services/authentication/kratos.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-recovery-page',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss'],
  imports: [SharedCommonModule, KratosFormComponent, VKitCardModule],
  standalone: true,
})
export class RecoveryPageComponent implements OnInit {
  private auth = inject(KratosAuthenticationService);
  private router = inject(Router);
  private toast = inject(ToastService);

  recoveryFlow?: RecoveryFlow;
  settingsFlow?: SettingsFlow;
  isCompletingRegistrationFlow = false;
  isCompletingSettingsFlow = false;

  stage: 'confirm-code' | 'reset-password' = 'confirm-code';

  initialFlowLoading = true;

  ngOnInit(): void {
    this.loadRecoveryFlow();
  }

  // Starts the recovery process by loading a flow which sends a code to the
  // email address a user entered.

  private loadRecoveryFlow() {
    this.auth
      .getRecoveryFlow()
      .then(flow => {
        this.recoveryFlow = flow;
      })
      .catch(ex => {
        if (ex instanceof ErrAlreadyLoggedIn) {
          this.stage = 'reset-password';
          this.loadSettingsFlow();
        }
      })
      .finally(() => {
        this.initialFlowLoading = false;
      });
  }

  async submitFlow(data: UpdateRecoveryFlowBody) {
    this.isCompletingRegistrationFlow = true;
    await this.auth
      .completeRecoveryFlow(this.recoveryFlow!, data)
      .then(newFlow => {
        this.recoveryFlow = newFlow;
      })
      .catch(err => {
        if (err instanceof ErrBrowserLocationChangeRequired) {
          this.stage = 'reset-password';
          this.loadSettingsFlow();
        } else {
          this.recoveryFlow = err.response.data;
        }
      })
      .finally(() => {
        this.isCompletingRegistrationFlow = false;
      });
  }

  // Settings flow is used to reset the password after successful validation
  // of email.

  private loadSettingsFlow() {
    this.auth
      .getSettingsFlow()
      .then(flow => {
        this.settingsFlow = flow;
      })
      .catch(ex => {
        if (ex instanceof ErrTwoFARequired) {
          this.router.navigate(['/login'], {
            queryParams: { aal: 'aal2', returnTo: '/recovery' },
          });
        }
      });
  }

  async submitSettingsFlow(data: UpdateSettingsFlowWithPasswordMethod) {
    this.isCompletingSettingsFlow = true;
    await this.auth
      .completeSettingsFlow(this.settingsFlow!.id, 'password', {
        ...data,
        method: 'password',
      })
      .then(() => {
        this.toast.showInfo(
          'Password Updated!',
          'Please use your new password to log into your account.'
        );
        this.router.navigate(['/login']);
      })
      .catch(err => {
        this.settingsFlow = err.response.data;
      })
      .finally(() => {
        this.isCompletingSettingsFlow = false;
      });
  }
}
