import { inject, Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import {
  Organization,
  OrganizationMember,
  OrganizationMemberRole,
} from 'src/app/models/organization';
import { Invitation } from 'src/app/services/authentication/kratos.service';

const GET_ORGANIZATION_QUERY = gql`
  query GetOrganization($organizationUUID: ID!) {
    organization(organizationUUID: $organizationUUID) {
      id
      name
      subscription {
        plan {
          name
        }
      }
    }
  }
`;

const GET_ORGANIZATION_MEMBERS_QUERY = gql`
  query GetOrganizationMembers($organizationUUID: ID!) {
    organization(organizationUUID: $organizationUUID) {
      members {
        id
        identityId
        joinedAt
        meta {
          firstname
          lastname
          email
          verified
        }
        role {
          name
          id
        }
      }
    }
  }
`;

const GET_INVITATIONS_QUERY = gql`
  query GetInvites($organizationUUID: ID!) {
    invitations(organizationUUID: $organizationUUID) {
      id
      token
      createdAt
      email
      role {
        id
        name
      }
      validUntil
    }
  }
`;

const CREATE_INVITATION_MUTATION = gql`
  mutation CreateInvitation(
    $organizationUUID: ID!
    $email: String!
    $roleId: ID!
    $validUntil: String!
  ) {
    createInvitation(
      organizationUUID: $organizationUUID
      createInvitationSpec: {
        email: $email
        roleId: $roleId
        validUntil: $validUntil
      }
    ) {
      id
      token
      createdAt
      email
      role {
        id
        name
      }
      validUntil
    }
  }
`;

const DELETE_INVITATION_MUTATION = gql`
  mutation DeleteInvitation($organizationUUID: ID!, $invitationId: ID!) {
    deleteInvitation(
      organizationUUID: $organizationUUID
      invitationID: $invitationId
    )
  }
`;

const UPDATE_ORG_MEMBER_ROLE_MUTATION = gql`
  mutation UpdateOrganizationMemberRole(
    $organizationID: ID!
    $membershipID: ID!
    $newRoleId: ID!
  ) {
    updateOrganizationMemberRole(
      organizationUUID: $organizationID
      updateRoleRequest: { membershipID: $membershipID, newRoleId: $newRoleId }
    ) {
      name
      id
    }
  }
`;

const DELETE_ORGANIZATION_MEMBER_MUTATION = gql`
  mutation DeleteOrganizationMember($organizationID: ID!, $membershipID: ID!) {
    deleteOrganizationMember(
      organizationUUID: $organizationID
      membershipID: $membershipID
    )
  }
`;

@Injectable()
export class OrganizationService {
  private apollo = inject(Apollo);

  loadOrganization(organizationId: string): Observable<Organization> {
    return this.apollo
      .query<{ organization: Organization }>({
        query: GET_ORGANIZATION_QUERY,
        variables: {
          organizationUUID: organizationId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data.organization;
        })
      );
  }

  getOrganizationMembers(
    organizationId: string
  ): Observable<OrganizationMember[]> {
    return this.apollo
      .query<{ organization: { members: OrganizationMember[] } }>({
        query: GET_ORGANIZATION_MEMBERS_QUERY,
        variables: {
          organizationUUID: organizationId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data.organization.members;
        })
      );
  }

  getInvitations(organizationId: string): Observable<Invitation[]> {
    return this.apollo
      .query<{ invitations: Invitation[] }>({
        query: GET_INVITATIONS_QUERY,
        variables: {
          organizationUUID: organizationId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data.invitations;
        })
      );
  }

  createInvitation(
    organizationId: string,
    email: string,
    roleId: string,
    validUntil: string
  ): Observable<Invitation> {
    return this.apollo
      .mutate<{ createInvitation: Invitation }>({
        mutation: CREATE_INVITATION_MUTATION,
        variables: {
          organizationUUID: organizationId,
          email: email,
          roleId: roleId,
          validUntil: validUntil,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.createInvitation;
        })
      );
  }

  deleteInvitation(
    organizationId: string,
    invitationId: string
  ): Observable<boolean> {
    return this.apollo
      .mutate<{ deleteInvitation: boolean }>({
        mutation: DELETE_INVITATION_MUTATION,
        variables: {
          organizationUUID: organizationId,
          invitationId: invitationId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.deleteInvitation;
        })
      );
  }

  updateOrganizationMemberRole(
    organizationId: string,
    membershipId: string,
    newRoleId: string
  ): Observable<OrganizationMemberRole> {
    return this.apollo
      .mutate<{ updateOrganizationMemberRole: OrganizationMemberRole }>({
        mutation: UPDATE_ORG_MEMBER_ROLE_MUTATION,
        variables: {
          organizationID: organizationId,
          membershipID: membershipId,
          newRoleId: newRoleId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.updateOrganizationMemberRole;
        })
      );
  }

  deleteOrganizationMember(
    organizationId: string,
    membershipId: string
  ): Observable<boolean> {
    return this.apollo
      .mutate<{ deleteOrganizationMember: boolean }>({
        mutation: DELETE_ORGANIZATION_MEMBER_MUTATION,
        variables: {
          organizationID: organizationId,
          membershipID: membershipId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.deleteOrganizationMember;
        })
      );
  }
}
