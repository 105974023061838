import { inject, Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { BroadcastChannel } from 'src/app/models/broadcast-channel';

const LIST_BROADCAST_CHANNELS_QUERY = gql`
  query ListBroadcastChannels($organizationId: ID!) {
    broadcastChannels(organizationId: $organizationId) {
      id
      iql
      endpoint {
        id
        providerType
        providerProfileId
        config {
          ... on ProviderChannelMetadata {
            id
            name
          }
        }
      }
    }
  }
`;

const CREATE_BROADCAST_CHANNEL_MUTATION = gql`
  mutation CreateBroadcastChannel(
    $organizationId: ID!
    $spec: BroadcastChannelSpec!
  ) {
    createBroadcastChannel(organizationId: $organizationId, spec: $spec) {
      id
      iql
      endpoint {
        id
        providerType
        providerProfileId
        config {
          ... on ProviderChannelMetadata {
            id
            name
          }
        }
      }
    }
  }
`;

const UPDATE_BROADCAST_CHANNEL_MUTATION = gql`
  mutation UpdateBroadcastChannel(
    $organizationId: ID!
    $broadcastChannelId: ID!
    $spec: BroadcastChannelSpec!
  ) {
    updateBroadcastChannel(
      organizationId: $organizationId
      broadcastChannelId: $broadcastChannelId
      spec: $spec
    ) {
      id
      iql
      endpoint {
        id
        providerType
        providerProfileId
        config {
          ... on ProviderChannelMetadata {
            id
            name
          }
        }
      }
    }
  }
`;

const DELETE_BROADCAST_CHANNEL_MUTATION = gql`
  mutation DeleteBroadcastChannel(
    $organizationId: ID!
    $broadcastChannelId: ID!
  ) {
    deleteBroadcastChannel(
      organizationId: $organizationId
      broadcastChannelId: $broadcastChannelId
    )
  }
`;

@Injectable()
export class BroadcastChannelsService {
  private apollo = inject(Apollo);

  listBroadcastChannels(
    organizationId: string
  ): Observable<BroadcastChannel[]> {
    return this.apollo
      .query<{ broadcastChannels: BroadcastChannel[] }>({
        query: LIST_BROADCAST_CHANNELS_QUERY,
        variables: {
          organizationId: organizationId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.broadcastChannels;
        })
      );
  }

  createBroadcastChannel(
    organizationId: string,
    iql: string,
    recipientEndpointId: string,
    providerProfileId: string
  ): Observable<BroadcastChannel> {
    return this.apollo
      .mutate<{ createBroadcastChannel: BroadcastChannel }>({
        mutation: CREATE_BROADCAST_CHANNEL_MUTATION,
        variables: {
          organizationId: organizationId,
          spec: {
            iql: iql,
            recipientEndpointId: recipientEndpointId,
            providerProfileId: providerProfileId,
          },
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.createBroadcastChannel;
        })
      );
  }

  updateBroadcastChannel(
    organizationId: string,
    broadcastChannelId: string,
    iql: string,
    providerProfileId: string,
    recipientEndpointId: string
  ): Observable<BroadcastChannel> {
    return this.apollo
      .mutate<{ updateBroadcastChannel: BroadcastChannel }>({
        mutation: UPDATE_BROADCAST_CHANNEL_MUTATION,
        variables: {
          organizationId: organizationId,
          broadcastChannelId: broadcastChannelId,
          spec: {
            iql: iql,
            providerProfileId: providerProfileId,
            recipientEndpointId: recipientEndpointId,
          },
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.updateBroadcastChannel;
        })
      );
  }

  deleteBroadcastChannel(
    organizationId: string,
    id: string
  ): Observable<boolean> {
    return this.apollo
      .mutate<{ deleteBroadcastChannel: boolean }>({
        mutation: DELETE_BROADCAST_CHANNEL_MUTATION,
        variables: {
          organizationId: organizationId,
          broadcastChannelId: id,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.deleteBroadcastChannel;
        })
      );
  }
}
