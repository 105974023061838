import { FeatureAvailability } from '../store/subscription/subscription.state';
import { PreviewInvoice } from './billing';

export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  INCOMPLETE = 'INCOMPLETE',
  UNPAID = 'UNPAID',
  TRIALING = 'TRIALING',
  CANCELED = 'CANCELED',
}

export interface ChangeSubscriptionOptions {
  planName: string;
  priceId: string;
  isTrial?: boolean;
  discountCodes?: string[];
}

export interface SubscriptionPlan {
  id: string;
  canStartTrial: boolean;
  status: SubscriptionStatus;
  plan: Plan;
  details: SubscriptionDetails;
}

export type BillingCycle = 'day' | 'month' | 'quarter' | 'year';

export interface Plan {
  id: string;
  name: string;
  order: number;
  features: Feature[];
  prices: Price[];
}

export interface Price {
  id: string;
  currency: string;
  interval: BillingCycle;
  intervalCount: number;
  trialPeriodDays: number;
  amount: number;
}

export interface Feature {
  id: string;
  name: string;
  tier: number;
  quota?: Quota;
  usage?: Usage;
  availability?: FeatureAvailability;
}

export interface Usage {
  unit: string;
  value: number;
}

export interface Quota {
  type: string;
  quota: number;
}

export interface SubscriptionDetails {
  currentPlan: SubscriptionPlanDetails;
  changeAt?: Date;
  nextPlan?: SubscriptionPlanDetails;
  currentPeriodStart: Date;
  currentPeriodEnd: Date;
  cancelAt: Date;
  upcomingInvoice?: PreviewInvoice;
}

export interface SubscriptionPlanDetails {
  plan: Plan;
  price: number;
  currency: string;
  billingCycle: BillingCycle;
}
