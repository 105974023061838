import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { VButtonComponent } from '../ui-kit/buttons/button/button.component';
import { VKitModule } from '../ui-kit/uikit.module';
import { NotAvailableInPlanComponent } from './components/not-available-in-plan.component';
import { FeatureBadgeComponent } from './feature-badge.component';
import { FeatureWrapperComponent } from './feature-wrapper.component';

@NgModule({
  imports: [CommonModule, TranslateModule, VButtonComponent, VKitModule],
  declarations: [
    FeatureWrapperComponent,
    FeatureBadgeComponent,
    NotAvailableInPlanComponent,
  ],
  exports: [FeatureWrapperComponent, FeatureBadgeComponent],
})
export class FeatureWrapperModule {}
