import { DialogRef } from '@angular/cdk/dialog';
import { Component, inject, ViewChild } from '@angular/core';
import { VDialogWrapperComponent } from '../wrapper.component';

export interface ConfirmationDialogConfig {
  header: string;
  description: string;
  variant: 'danger' | 'warning';
  confirmText: string;
  confirmIcon: string;
  cancelText: string;
  cancelIcon: string;
}

@Component({
  templateUrl: './confirmation.component.html',
})
export class VConfirmationDialogComponent {
  @ViewChild(VDialogWrapperComponent) dialogWrapper!: VDialogWrapperComponent;

  private dialogRef = inject(DialogRef);

  public variant: 'danger' | 'warning' = 'danger';
  public header = 'Are you sure?';
  public description = 'Are you sure that you want to proceed?';
  public confirmText = 'Confirm';
  public confirmIcon = 'pi pi-check';
  public cancelText = 'Cancel';
  public cancelIcon = 'pi pi-times';

  constructor() {
    const config = this.dialogRef.config
      .data as Partial<ConfirmationDialogConfig>;

    if (config.variant) this.variant = config.variant;
    if (config.header) this.header = config.header;
    if (config.description) this.description = config.description;
    if (config.confirmText) this.confirmText = config.confirmText;
    if (config.confirmIcon) this.confirmIcon = config.confirmIcon;
    if (config.cancelText) this.cancelText = config.cancelText;
    if (config.cancelIcon) this.cancelIcon = config.cancelIcon;
  }
}
