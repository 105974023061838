import { Injectable, OnDestroy, inject } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
  Routes,
} from '@angular/router';
import { Store, createModelSelector, createSelector } from '@ngxs/store';
import { Subscription, filter } from 'rxjs';
import {
  HasFlag,
  RequiredFeatureAnnotation,
} from 'src/app/core/guards/features.guard';
import { AuthorizedMenuItem } from 'src/app/models/permission';
import {
  FeatureFlags,
  FeaturesState,
  FeaturesStateModel,
} from 'src/app/store/features/features.state';
import { UserState, UserStateModel } from 'src/app/store/user/user.state';
import {
  HasPermissionForRoute,
  RequiredRolesAnnotation,
} from '../../core/guards/permission.guard';
import { MenuItem } from './menuItem';

@Injectable({
  providedIn: 'root',
})
export class MenuService implements OnDestroy {
  private menuItems: MenuItem[] = [];
  private breadcrumbs: MenuItem[] = [];

  private mainLayoutRoutePath = '';

  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private store = inject(Store);

  private menuSubscription: Subscription;

  constructor() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
      });

    this.menuSubscription = this.store
      .select(
        createModelSelector({
          flags: FeaturesState.flags,
          roles: UserState.getPermissionRoles,
        })
      )
      .subscribe(({ flags, roles }) => {
        if (!flags || !roles) return;

        this.generateMenuItems(this.router.config, roles, flags).then(items => {
          this.menuItems = items;
        });
      });
  }

  ngOnDestroy(): void {
    this.menuSubscription.unsubscribe();
  }

  private async generateMenuItems(
    routes: Routes,
    permissionRoles: string[],
    flags: FeatureFlags
  ): Promise<MenuItem[]> {
    const mainLayoutRoute = routes.find(
      r => r.path === this.mainLayoutRoutePath
    );
    if (!mainLayoutRoute) {
      throw Error(
        `Could not find mainLayoutRoute route with path ${this.mainLayoutRoutePath}. You probably don't want that`
      );
    }

    if (!mainLayoutRoute.children) return [];

    const items = await Promise.all(
      mainLayoutRoute.children.map(async route => {
        if (!route.data || !route.data['title']) {
          return false;
        }

        if (!HasPermissionForRoute(permissionRoles, route)) return false;

        if (
          route.data[RequiredFeatureAnnotation] &&
          !HasFlag(flags, route.data[RequiredFeatureAnnotation])
        ) {
          return false;
        }

        return {
          label: route.data['title'],
          routerLink: route.path,
          icon: route.data['icon'],
        } as MenuItem;
      })
    );

    return items.filter(menuItem => menuItem !== false) as MenuItem[];
  }

  updatePageTitle(page: ActivatedRoute, title: string) {
    page.snapshot.data['title'] = title;
    delete page.snapshot.data['dynamicTitle'];

    this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute);
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: MenuItem[] = []
  ): MenuItem[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      // generate router url for child
      const routeURL: string = child.snapshot.url
        .map(segment => segment.path)
        .join('/');

      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      // if the route url is empty and there are no children we are on a leaf
      // node and do not need to process further
      if (routeURL === '' && child.children.length === 0) {
        const menuItem = this.getBreadcrumbForSnapshot(child.snapshot, url);
        if (menuItem) {
          breadcrumbs.push(menuItem);
        }

        return breadcrumbs;
      }

      const menuItem = this.getBreadcrumbForSnapshot(child.snapshot, url);
      if (menuItem) {
        breadcrumbs.push(menuItem);
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }

    return [];
  }

  getBreadcrumbForSnapshot(
    snapshot: ActivatedRouteSnapshot,
    url: string
  ): MenuItem | undefined {
    if (snapshot.data['dynamicTitle']) {
      return {
        label: '',
        routerLink: [url],
        loading: true,
      };
    } else {
      const label = snapshot.data['title'];

      if (label) {
        return {
          label: label,
          routerLink: [url],
        };
      }
    }

    return;
  }

  getMenuItems() {
    return this.menuItems;
  }

  getBreadcrumbs() {
    return this.breadcrumbs;
  }

  // filter helper functions

  static filteredMenuItems(
    allItems: AuthorizedMenuItem[],
    filterSubitems = true
  ) {
    return createSelector(
      [UserState, FeaturesState],
      (state: UserStateModel, features: FeaturesStateModel) => {
        const membership = state.user?.memberships.find(m => {
          return m.organizationId === state.selectedOrganizationId;
        });

        if (!membership) return [];

        const items = this.filterMenuItems(
          membership.role.permissionRoles,
          features.flags,
          allItems
        );

        if (!filterSubitems) return items;

        // remove top level items without any sub items which were probably
        // removed by the permission check
        const filteredItems = items.filter(i => {
          if (!i.items || i.items.length === 0) return false;
          return true;
        });

        return filteredItems;
      }
    );
  }

  // helper function to recursively validate permissions on menu items
  private static filterMenuItems(
    userRoles: string[],
    flags: FeatureFlags,
    items: AuthorizedMenuItem[]
  ): AuthorizedMenuItem[] {
    return items.filter(item => {
      if (item[RequiredRolesAnnotation]) {
        for (const rp of item[RequiredRolesAnnotation]) {
          if (!userRoles.includes(rp)) return false;
        }
      }

      if (item[RequiredFeatureAnnotation]) {
        if (!HasFlag(flags, item[RequiredFeatureAnnotation])) return false;
      }

      if (item.items) {
        item.items = this.filterMenuItems(userRoles, flags, item.items);
      }

      return true;
    });
  }
}
