import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  ControlContainer,
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { FormErrorComponent } from '../utils/form-error/form-error.component';

@Component({
  selector: 'v-secret-input',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, FormsModule, FormErrorComponent],
  templateUrl: './secret-input.component.html',
  styleUrl: './secret-input.component.scss',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class VSecretInputComponent {
  type = 'password';

  /**
   * Placeholder text to display
   */
  @Input()
  placeholder: string = 'Enter text';

  /**
   * Optional form control name
   */
  @Input()
  fControlName?: string;

  /**
   * Optionally sets the test id for the input
   */
  @Input()
  testId?: string;

  toggleVisibility() {
    if (this.type === 'password') {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
  }
}
