import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SkeletonModule } from 'primeng/skeleton';
import { SharedCommonModule } from 'src/app/core/common.module';
import { ServiceStateComponent } from 'src/app/core/components/service-state/service-state.component';
import { StatsCardComponent } from 'src/app/core/components/stats-card/stats-card.component';
import { IncidentsStateModule } from 'src/app/store/incidents/incidents.state.module';
import { ServicesStateModule } from 'src/app/store/services/services.state.module';
import { StatisticsStateModule } from 'src/app/store/statistics/statistics.state.module';
import { StatuspagesStateModule } from 'src/app/store/statuspages/statuspages.state.module';
import { ActiveIncidentComponent } from './components/active-incident/active-incident.component';
import { HomePageComponent } from './home.component';

@NgModule({
  declarations: [HomePageComponent],
  imports: [
    StatuspagesStateModule,
    StatisticsStateModule,
    ActiveIncidentComponent,
    CommonModule,
    StatsCardComponent,
    TranslateModule,
    SkeletonModule,
    SharedCommonModule,
    ServiceStateComponent,
    ServicesStateModule,
    IncidentsStateModule,
  ],
  providers: [],
  bootstrap: [],
  exports: [],
})
export class HomeModule {}
