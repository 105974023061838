import { NgModule } from '@angular/core';
import { SharedCommonModule } from '../common.module';
import { ComponentsModule } from '../components/components.module';
import { KratosFormComponent } from '../components/kratos/form.component';
import { ReAuthenticateDialogComponent } from './reauthenticate/reauthenticate.component';

@NgModule({
  declarations: [ReAuthenticateDialogComponent],
  imports: [SharedCommonModule, ComponentsModule, KratosFormComponent],
  providers: [],
  exports: [ReAuthenticateDialogComponent],
})
export class DialogsModule {}
