import { inject, Injectable, NgZone } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private messageAPI = inject(MessageService);

  private zone = inject(NgZone);

  showError(error: Error) {
    this.zone.run(() => {
      this.messageAPI.add({
        key: 'topright',
        severity: 'error',
        summary: 'Error',
        detail: error.toString(),
      });
    });
  }

  showInfo(header: string, message: string) {
    this.zone.run(() => {
      this.messageAPI.add({
        key: 'topright',
        severity: 'info',
        summary: header,
        detail: message,
      });
    });
  }
}
