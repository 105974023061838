import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IntlModule } from 'angular-ecmascript-intl';
import { ComponentsModule } from 'src/app/core/components/components.module';
import { Incident } from 'src/app/models/incident';
import { IncidentStateComponent } from 'src/app/pages/internal/incidents/components/incident-state/incident-state.component';

@Component({
  selector: 'app-active-incident',
  templateUrl: './active-incident.component.html',
  standalone: true,
  imports: [
    RouterModule,
    IncidentStateComponent,
    TranslateModule,
    ComponentsModule,
    CommonModule,
    IntlModule,
  ],
})
export class ActiveIncidentComponent {
  @Input() incident?: Incident;
}
