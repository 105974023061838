import { Component, Input } from '@angular/core';
import { UserMetadata } from '../sidemenu/sidemenu.component';

@Component({
  selector: 'v-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
  standalone: true,
})
export class VUserCardComponent {
  @Input({ required: true }) user!: UserMetadata;
}
