import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { ServiceDetailsState } from './service-details.state';
import { ServicesService } from './services.service';
import { ServicesState } from './services.state';

@NgModule({
  declarations: [],
  imports: [NgxsModule.forFeature([ServicesState, ServiceDetailsState])],
  providers: [ServicesService],
  bootstrap: [],
  exports: [],
})
export class ServicesStateModule {}
