import { Component, inject, Input, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
} from '@angular/forms';
import { VButtonComponent } from '../buttons/button/button.component';
import { VInputComponent } from '../input/input.component';

@Component({
  selector: 'v-kv-editor',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    VInputComponent,
    VButtonComponent,
  ],
  templateUrl: './kv-editor.component.html',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class VKeyValueEditorComponent implements OnInit {
  private controlContainer = inject(ControlContainer);

  newEntryFG!: FormGroup;

  /**
   * Optional form control name
   */
  @Input({ required: true })
  fControlName!: string;

  @Input()
  valueType: 'text' | 'number' = 'number';

  @Input()
  keyValidators: ValidatorFn[] = [];

  @Input()
  valueValidators: ValidatorFn[] = [];

  constructor() {}

  ngOnInit(): void {
    const fb = new FormBuilder();
    this.newEntryFG = fb.group({
      key: fb.control('', this.keyValidators),
      value: fb.control('', this.valueValidators),
    });
  }

  get formGroup(): FormGroup {
    return this.controlContainer.control as FormGroup;
  }

  get formArray(): FormArray<FormGroup> {
    return this.formGroup.get(this.fControlName) as FormArray;
  }

  addEntry() {
    const fArray = this.formGroup.get(this.fControlName) as FormArray;

    fArray.push(
      new FormGroup({
        key: new FormControl(
          this.newEntryFG.get('key')?.value,
          this.keyValidators
        ),
        value: new FormControl(
          this.newEntryFG.get('value')?.value,
          this.valueValidators
        ),
      })
    );
    fArray.markAsDirty();

    this.newEntryFG.reset();
  }

  removeEntry(index: number) {
    const fArray = this.formGroup.get(this.fControlName) as FormArray;

    fArray.removeAt(index);
    fArray.markAsDirty();
  }
}
