<div class="v-copy-field">
  <input
    *ngIf="fControlName"
    [type]="type"
    [formControlName]="fControlName"
    class="v-input"
    [placeholder]="placeholder"
    [attr.data-testid]="testId" />
  <button (click)="toggleVisibility()">
    <i
      class="pi"
      [ngClass]="{
        'pi-eye': this.type === 'password',
        'pi-eye-slash': this.type === 'text'
      }"></i>
  </button>
</div>
