import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { forkJoin, map } from 'rxjs';
import { Incident } from 'src/app/models/incident';
import { Monitor } from 'src/app/models/monitor';
import { Service, ServiceStatistics } from 'src/app/models/service';
import { ToastService } from 'src/app/services/toast/toast.service';
import {
  Entity,
  loadEntity,
  NewEntity,
  saveEntity,
} from 'src/app/store/common/entity';
import { UserState } from 'src/app/store/user/user.state';
import {
  IterablePaginatedListEntitiesState,
  PaginatedListEntities,
  PaginatedListEntitiesState,
  TransformPaginatedEntitiesToIterableEntities,
} from '../common/paginated-list-entity';
import { OffsetPagination, OffsetPaginationState } from '../common/pagination';
import {
  LoadActiveIncidentsForServiceAction,
  LoadMonitorsForServiceAction,
  LoadRecentIncidentsForServiceAction,
  LoadServiceAction,
  LoadServiceStatisticsAction,
  UpdateServiceAction,
} from './services.actions';
import { ServicesService } from './services.service';

export interface ServiceDetailsStateModel {
  service: Entity<Service>;
  monitors: PaginatedListEntitiesState<Monitor, OffsetPaginationState>;
  activeIncidents: PaginatedListEntitiesState<Incident, OffsetPaginationState>;
  recentIncidents: PaginatedListEntitiesState<Incident, OffsetPaginationState>;
  statistics: Entity<ServiceStatistics>;
}

@State<ServiceDetailsStateModel>({
  name: 'serviceDetails',
  defaults: {
    service: NewEntity(),
    monitors: OffsetPagination.GetDefaultState(3),
    activeIncidents: OffsetPagination.GetDefaultState(3),
    recentIncidents: OffsetPagination.GetDefaultState(3),
    statistics: NewEntity(),
  },
})
@Injectable()
export class ServiceDetailsState {
  store = inject(Store);
  servicesService = inject(ServicesService);
  toast = inject(ToastService);

  monitors = new PaginatedListEntities<
    ServiceDetailsStateModel,
    OffsetPagination
  >('monitors', new OffsetPagination());

  activeIncidents = new PaginatedListEntities<
    ServiceDetailsStateModel,
    OffsetPagination
  >('activeIncidents', new OffsetPagination());

  recentIncidents = new PaginatedListEntities<
    ServiceDetailsStateModel,
    OffsetPagination
  >('recentIncidents', new OffsetPagination());

  @Selector()
  static serviceDetails(state: ServiceDetailsStateModel): Entity<Service> {
    return state.service;
  }

  @Selector()
  static statistics(
    state: ServiceDetailsStateModel
  ): Entity<ServiceStatistics> {
    return state.statistics;
  }

  @Selector()
  static monitors(
    state: ServiceDetailsStateModel
  ): IterablePaginatedListEntitiesState<Monitor> {
    return TransformPaginatedEntitiesToIterableEntities(state.monitors);
  }

  @Selector()
  static activeIncidents(
    state: ServiceDetailsStateModel
  ): IterablePaginatedListEntitiesState<Incident> {
    return TransformPaginatedEntitiesToIterableEntities(state.activeIncidents);
  }

  @Selector()
  static recentIncidents(
    state: ServiceDetailsStateModel
  ): IterablePaginatedListEntitiesState<Incident> {
    return TransformPaginatedEntitiesToIterableEntities(state.recentIncidents);
  }
  @Action(LoadServiceAction)
  loadServiceDetails(
    ctx: StateContext<ServiceDetailsStateModel>,
    { id }: LoadServiceAction
  ) {
    const organizationId = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationId) throw new Error('No membership');

    const observable = this.servicesService.loadService(organizationId, id);

    return forkJoin([loadEntity(ctx, 'service', observable)]);
  }

  @Action(LoadMonitorsForServiceAction)
  loadMonitorsForService(
    ctx: StateContext<ServiceDetailsStateModel>,
    { id, pagination }: LoadMonitorsForServiceAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return this.monitors.loadEntititesPaginated(
      ctx,
      pagination,
      this.servicesService.loadMonitorsForService(
        organizationID,
        id,
        pagination,
        {
          windowStart: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7),
        }
      )
    );
  }

  @Action(LoadActiveIncidentsForServiceAction)
  loadActiveIncidentsForService(
    ctx: StateContext<ServiceDetailsStateModel>,
    { id, pagination }: LoadActiveIncidentsForServiceAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return this.activeIncidents.loadEntititesPaginated(
      ctx,
      pagination,
      this.servicesService.loadIncidentsForService(
        organizationID,
        id,
        'resolved = false',
        pagination
      )
    );
  }

  @Action(LoadRecentIncidentsForServiceAction)
  loadRecentIncidentsForService(
    ctx: StateContext<ServiceDetailsStateModel>,
    { id, pagination }: LoadRecentIncidentsForServiceAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return this.recentIncidents.loadEntititesPaginated(
      ctx,
      pagination,
      this.servicesService.loadIncidentsForService(
        organizationID,
        id,
        'resolved = true',
        pagination
      )
    );
  }

  @Action(UpdateServiceAction)
  UpdateService(
    ctx: StateContext<ServiceDetailsStateModel>,
    { serviceId, updateSpec }: UpdateServiceAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return saveEntity(
      ctx,
      'service',
      this.servicesService
        .updateService(organizationID, serviceId, updateSpec)
        .pipe(
          map(res => {
            const state = ctx.getState();

            this.toast.showInfo('Service Updated', 'Service has been updated');

            const newObject = {
              ...state.service.data,
              ...res,
            };

            return newObject;
          })
        )
    );
  }

  @Action(LoadServiceStatisticsAction)
  loadServiceStatistics(
    ctx: StateContext<ServiceDetailsStateModel>,
    { serviceId, windowStart, windowEnd }: LoadServiceStatisticsAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return loadEntity(
      ctx,
      'statistics',
      this.servicesService.loadServiceStatistics(
        organizationID,
        serviceId,
        windowStart,
        windowEnd
      )
    );
  }
}
