import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
})
export class ChipComponent {
  @Input() label: string = '';
  @Input() icon?: string;
  @Input() image?: string;
  @Input() link?: string;
  @Input() routerLink?: string;

  @Input() showDelete = false;
  @Output() deleted = new EventEmitter();

  constructor() {}

  onClickDelete() {
    this.deleted.emit();
  }
}
