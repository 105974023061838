import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Route } from '@angular/router';
import { Store } from '@ngxs/store';
import { UserState } from 'src/app/store/user/user.state';

export const RequiredRolesAnnotation = 'requiredRoles';

// PermissionGuard checks wether a user has the requiredPermissions to access a route.
export const PermissionGuard = async (snapshot: ActivatedRouteSnapshot) => {
  const store = inject(Store);

  if (!snapshot.data || !snapshot.data[RequiredRolesAnnotation]) return true;

  const permissionRoles = store.selectSnapshot(UserState.getPermissionRoles);

  return HasPermissionForRoute(permissionRoles, snapshot);
};

export const HasPermissionForRoute = (
  permissionRoles: string[],
  route: Route | ActivatedRouteSnapshot
) => {
  if (!route.data || !route.data[RequiredRolesAnnotation]) return true;

  // check wether a user has one of the required permission
  for (const rp of route.data[RequiredRolesAnnotation]) {
    if (permissionRoles?.includes(rp)) {
      return true;
    }
  }

  return false;
};
