import { Component, Input } from '@angular/core';
import { IncidentSchemaPriority } from 'src/app/models/incident-schema';

@Component({
  selector: 'app-priority',
  templateUrl: './priority.component.html',
  styleUrls: ['./priority.component.scss'],
})
export class PriorityComponent {
  constructor() {}

  @Input() priority?: IncidentSchemaPriority;
}
