import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { BroadcastChannelState } from './broadcast-channels.state';
import { BroadcastChannelsService } from './broadcast-channels.service';

@NgModule({
  declarations: [],
  imports: [NgxsModule.forFeature([BroadcastChannelState])],
  providers: [BroadcastChannelsService],
  bootstrap: [],
  exports: [],
})
export class BroascastChannelsStateModule {}
