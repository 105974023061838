import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

const MOBILE_MAX_WIDTH = 767;
const TABLET_MAX_WIDTH = 1023;
const SMALL_DESKTOP_MAX_WIDTH = 1279;

export enum ScreenSize {
  Mobile = MOBILE_MAX_WIDTH,
  Tablet = TABLET_MAX_WIDTH,
  SmallDesktop = SMALL_DESKTOP_MAX_WIDTH,
  Desktop = Number.MAX_SAFE_INTEGER, // Represents any width larger than SMALL_DESKTOP_MAX_WIDTH
}

export interface EnvironmentOptions {
  screenSize: ScreenSize;
}

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private settingsSubject: BehaviorSubject<EnvironmentOptions>;

  constructor() {
    const initialSettings: EnvironmentOptions = {
      screenSize: this.getNormalizedScreenWidth(),
    };

    this.settingsSubject = new BehaviorSubject<EnvironmentOptions>(
      initialSettings
    );
    this.startListeningToResize();
  }

  get settings$(): Observable<EnvironmentOptions> {
    return this.settingsSubject.asObservable();
  }

  private startListeningToResize(): void {
    window.addEventListener('resize', () => {
      const newSettings = {
        ...this.settingsSubject.value,
        screenSize: this.getNormalizedScreenWidth(),
      };
      this.settingsSubject.next(newSettings);
    });
  }

  private getNormalizedScreenWidth(): ScreenSize {
    const width = window.innerWidth;

    if (width <= ScreenSize.Mobile) {
      return ScreenSize.Mobile;
    } else if (width <= ScreenSize.Tablet) {
      return ScreenSize.Tablet;
    } else if (width <= ScreenSize.SmallDesktop) {
      return ScreenSize.SmallDesktop;
    } else {
      return ScreenSize.Desktop;
    }
  }
}
