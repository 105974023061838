import { inject, Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { toIdentifiableEntities } from 'src/app/helpers/graphql';
import { Incident } from 'src/app/models/incident';
import { Monitor } from 'src/app/models/monitor';
import { Service, ServiceStatistics } from 'src/app/models/service';
import { mapStatistics, StatisticOptions } from 'src/app/models/statistics';
import { MONITOR_DETAILS_FRAGMENT } from 'src/app/store/monitors/gql/fragments';
import {
  OffsetPaginatedRespose,
  OffsetPaginationOptions,
} from '../common/pagination';

export const GET_SERVICES_QUERY = gql`
  query GetServices($organizationUUID: ID!) {
    services(organizationUUID: $organizationUUID) {
      id
      name
      description
      owner {
        name
      }
      state
    }
  }
`;

const GET_SERVICE = gql`
  query GetService($organizationUUID: ID!, $serviceId: Int!) {
    service(organizationUUID: $organizationUUID, serviceId: $serviceId) {
      id
      name
      description
      state
      owner {
        id
        name
        members {
          meta {
            firstname
            lastname
            avatar
          }
        }
      }
    }
  }
`;

const GET_SERVICE_MONITORS = gql`
  ${MONITOR_DETAILS_FRAGMENT}
  query GetService(
    $organizationUUID: ID!
    $serviceId: Int!
    $pagination: OffsetPaginationOptions!
    $statisticsOptions: MonitorStatisticsInput!
  ) {
    service(organizationUUID: $organizationUUID, serviceId: $serviceId) {
      monitors(pagination: $pagination) {
        totalSize
        monitors {
          ...MonitorDetails
        }
      }
    }
  }
`;

const GET_SERVICE_INCIDENTS = gql`
  query GetService(
    $organizationUUID: ID!
    $serviceId: Int!
    $pagination: OffsetPaginationOptions!
    $query: String
  ) {
    service(organizationUUID: $organizationUUID, serviceId: $serviceId) {
      incidents(pagination: $pagination, query: $query) {
        totalSize
        incidents {
          id
          title
          summary
          createdAt
          priority {
            id
            name
            order
            color
            icon
          }
          state {
            id
            name
            order
            color
            icon
            isDefault
            isResolved
          }
          statuspageLinks {
            statuspage {
              id
              name
              customDomain
              domain
              slug
            }
          }
          affectedServices {
            service {
              name
            }
          }
        }
      }
    }
  }
`;

const UPDATE_SERVICE_MUTATION = gql`
  mutation UpdateService(
    $organizationUUID: ID!
    $serviceId: Int!
    $updateServiceSpec: UpdateServiceSpec!
  ) {
    updateService(
      organizationUUID: $organizationUUID
      serviceId: $serviceId
      updateServiceSpec: $updateServiceSpec
    ) {
      name
      description
      owner {
        id
        name
      }
    }
  }
`;

const GET_SERVICE_STATISTICS = gql`
  query ServiceStatistics(
    $organizationId: ID!
    $serviceId: Int!
    $windowStart: Time!
    $windowEnd: Time!
  ) {
    service(organizationUUID: $organizationId, serviceId: $serviceId) {
      statistics(windowStart: $windowStart, windowEnd: $windowEnd) {
        impactDuration {
          name
          unit
          datapoints {
            date
            value
          }
        }
        mtta {
          name
          unit
          datapoints {
            date
            value
          }
        }
        mttr {
          name
          unit
          datapoints {
            date
            value
          }
        }
      }
    }
  }
`;

const DELETE_SERVICE_MUTATION = gql`
  mutation DeleteService($organizationUUID: ID!, $serviceId: Int!) {
    deleteService(organizationUUID: $organizationUUID, serviceId: $serviceId)
  }
`;

@Injectable()
export class ServicesService {
  private apollo = inject(Apollo);

  loadServices(organizationId: string): Observable<Service[]> {
    return this.apollo
      .query<{ services: Service[] }>({
        query: GET_SERVICES_QUERY,
        variables: {
          organizationUUID: organizationId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data.services;
        })
      );
  }

  loadService(organizationId: string, serviceId: number): Observable<Service> {
    return this.apollo
      .query<{ service: Service }>({
        query: GET_SERVICE,
        variables: {
          organizationUUID: organizationId,
          serviceId: serviceId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data.service;
        })
      );
  }

  loadMonitorsForService(
    organizationId: string,
    serviceId: number,
    pagination: OffsetPaginationOptions,
    statisticsOptions: StatisticOptions
  ): Observable<OffsetPaginatedRespose<Monitor>> {
    return this.apollo
      .query<{ service: Service }>({
        query: GET_SERVICE_MONITORS,
        variables: {
          organizationUUID: organizationId,
          serviceId: serviceId,
          pagination: pagination,
          statisticsOptions: statisticsOptions,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return {
            totalSize: data.data.service.monitors?.totalSize ?? 0,
            items: toIdentifiableEntities(
              data.data.service.monitors?.monitors ?? [],
              'id'
            ),
          } as OffsetPaginatedRespose<Monitor>;
        })
      );
  }

  loadIncidentsForService(
    organizationId: string,
    serviceId: number,
    query: string,
    pagination: OffsetPaginationOptions
  ): Observable<OffsetPaginatedRespose<Incident>> {
    return this.apollo
      .query<{ service: Service }>({
        query: GET_SERVICE_INCIDENTS,
        variables: {
          organizationUUID: organizationId,
          serviceId: serviceId,
          pagination: pagination,
          query: query,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return {
            totalSize: data.data.service.incidents?.totalSize ?? 0,
            items: toIdentifiableEntities(
              data.data.service.incidents?.incidents ?? [],
              'id'
            ),
          } as OffsetPaginatedRespose<Incident>;
        })
      );
  }

  updateService(
    organizationId: string,
    serviceId: number,
    updateSpec: {
      name?: string;
      description?: string;
      ownerTeamPublicId?: number;
    }
  ): Observable<Partial<Service>> {
    return this.apollo
      .mutate<{ updateService: Service }>({
        mutation: UPDATE_SERVICE_MUTATION,
        variables: {
          organizationUUID: organizationId,
          serviceId: serviceId,
          updateServiceSpec: {
            name: updateSpec.name,
            description: updateSpec.description,
            owningTeamID: updateSpec.ownerTeamPublicId,
          },
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.updateService;
        })
      );
  }

  deleteService(
    organizationId: string,
    serviceId: number
  ): Observable<boolean> {
    return this.apollo
      .mutate<{ deleteService: boolean }>({
        mutation: DELETE_SERVICE_MUTATION,
        variables: {
          organizationUUID: organizationId,
          serviceId: serviceId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.deleteService;
        })
      );
  }

  loadServiceStatistics(
    organizationId: string,
    serviceId: number,
    windowStart: string,
    windowEnd: string
  ): Observable<ServiceStatistics> {
    return this.apollo
      .query<{ service: { statistics: ServiceStatistics } }>({
        query: GET_SERVICE_STATISTICS,
        variables: {
          organizationId: organizationId,
          serviceId: serviceId,
          windowStart: windowStart,
          windowEnd: windowEnd,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }

          return {
            impactDuration: mapStatistics(
              data.data!.service.statistics.impactDuration
            ),
            mttr: mapStatistics(data.data!.service.statistics.mttr),
            mtta: mapStatistics(data.data!.service.statistics.mtta),
          };
        })
      );
  }
}
