import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexGrid,
  ApexLegend,
  ApexMarkers,
  ApexPlotOptions,
  ApexStroke,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
  NgApexchartsModule,
} from 'ng-apexcharts';
import { DataPoint, Unit } from 'src/app/models/statistics';
import {
  ChartFormatter,
  chartFormatters,
  HeaderFormat,
  headerFormatters,
} from './formatters';
declare global {
  interface Window {
    Apex: any;
  }
}

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  grid: ApexGrid;
  xaxis: ApexXAxis;
  markers: ApexMarkers; //ApexMarkers;
  stroke: ApexStroke; //ApexStroke;
  yaxis: ApexYAxis;
  plotOptions: ApexPlotOptions;
  dataLabels: ApexDataLabels;
  colors: string[];
  labels: string[] | number[];
  title: ApexTitleSubtitle;
  subtitle: ApexTitleSubtitle;
  legend: ApexLegend;
  fill: ApexFill;
  tooltip: ApexTooltip;
};

@Component({
  selector: 'app-stats-card',
  standalone: true,
  templateUrl: './stats-card.component.html',
  styleUrls: ['./stats-card.component.scss'],
  imports: [CommonModule, NgApexchartsModule, TranslateModule],
})
export class StatsCardComponent implements OnInit, OnChanges {
  @ViewChild('chart', { static: false }) chart?: ChartComponent;

  @Input() header = '';
  @Input() subheader = '';
  @Input() unit: Unit = Unit.Seconds;
  @Input() headerFormat: HeaderFormat = HeaderFormat.Text;
  @Input() datapoints?: DataPoint[] = [];
  @Input() aggregation: 'last' | 'sum' | 'mean' = 'last';
  @Input() size: 'sm' | 'md' | 'lg' | 'xl' = 'md';
  @Input() variant: 'sparkline' | 'chart' = 'sparkline';
  @Input() group?: string;
  @Input() groupMemberId?: string;
  @Input() overwrittenDisplayValue?: string | number;

  @Input() loading: boolean | undefined = false;

  sizeMappings = {
    sm: 40,
    md: 60,
    lg: 120,
    xl: 180,
  };

  chartInitialized = false;
  showNotEnoughData = false;

  public displayValue() {
    if (this.overwrittenDisplayValue) {
      return this.overwrittenDisplayValue;
    }

    if (!this.datapoints || this.datapoints.length === 0) {
      return '-';
    }

    let currentValue: number = 0;

    switch (this.aggregation) {
      case 'last':
        currentValue = this.datapoints[this.datapoints.length - 1].value;
        break;
      case 'sum':
        currentValue = this.datapoints
          .map(p => p.value)
          .reduce((p, c) => p + c);
        break;
      case 'mean':
        // when every datapoint is null we cannot calculate a mean value
        if (this.datapoints.every(dp => dp.value == 0)) {
          return '-';
        }
        currentValue =
          this.datapoints.map(p => p.value).reduce((p, c) => p + c) /
          this.datapoints.filter(dp => dp.value > 0).length;
    }

    return headerFormatters[this.headerFormat](currentValue, this.unit);
  }

  public getFontSizeClass() {
    if (this.displayValue().toString().length > 10) {
      return this.size === 'md' ? 'text-lg' : 'text-md';
    } else {
      return this.size === 'md' ? 'text-3xl' : 'text-xl';
    }
  }

  ngOnInit() {
    this.chartOptions = this.getChartOptions();
    this.calculateChart();
    this.chartInitialized = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.chartInitialized) return;

    // check if changes are relevant
    if (!changes['datapoints']) {
      return;
    }

    if (changes['variant']) {
      this.chartOptions = this.getChartOptions();
    }

    this.calculateChart();
  }

  private calculateChart() {
    if (this.datapoints && this.datapoints.length >= 2) {
      this.chartOptions.series![0].data = this.datapoints.map(p => [
        p.date.getTime(),
        p.value,
      ]);
      this.chartOptions.series![0].name = this.header;
      this.chartOptions.yaxis!.max = undefined;
      this.showNotEnoughData = false;
    } else {
      this.showNotEnoughData = true;
    }

    this.chart?.updateOptions({
      chart: {
        height: this.sizeMappings[this.size],
      },
    });
  }

  chartOptions: Partial<ChartOptions> = {};

  getChartOptions(): Partial<ChartOptions> {
    if (this.variant === 'sparkline') {
      return {
        chart: {
          type: 'area',
          animations: {
            enabled: false,
          },
          height: this.sizeMappings[this.size],
          width: '100%',
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          curve: 'smooth',
        },
        tooltip: {
          enabled: false,
        },
        fill: {
          type: 'gradient',
          colors: ['#3078db'],
          gradient: {
            opacityFrom: 0.7,
            opacityTo: 0.4,
            gradientToColors: ['#3078db'],
          },
        },
        yaxis: {},
        series: [
          {
            data: [],
          },
        ],
      };
    } else {
      return {
        chart: {
          type: 'area',
          animations: {
            enabled: false,
          },
          height: this.sizeMappings[this.size],
          width: '100%',
          toolbar: {
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: false,
            },
          },
          id: this.groupMemberId,
          group: this.group,
        },
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        tooltip: {
          enabled: true,
          theme: 'dark',
          x: {
            show: true,
            formatter: chartFormatters[ChartFormatter.LocalDateTime],
          },
        },
        fill: {
          type: 'gradient',
          colors: ['#3078db'],
          gradient: {
            opacityFrom: 0.7,
            opacityTo: 0.4,
            gradientToColors: ['#3078db'],
          },
        },
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          min: 0,
          forceNiceScale: true,
          show: true,
          labels: {
            formatter: chartFormatters[ChartFormatter.Unit][this.unit],
            style: {
              colors: 'lightgray',
            },
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeUTC: true,
            datetimeFormatter: {
              year: 'yyyy',
              month: "MMM 'yy",
              day: 'dd MMM',
              hour: 'HH:mm',
              minute: 'mm:ss',
            },
            style: {
              colors: 'lightgray',
            },
          },
          tooltip: {
            enabled: false,
          },
          axisTicks: {
            color: 'lightgray',
          },
        },
        series: [
          {
            data: [],
          },
        ],
      };
    }
  }
}
