import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface RadioGroupOption {
  id: string;
  label: string;
  description: string;
  selectIcon?: string;
  icon?: string;
  image?: string;
}

@Component({
  selector: 'app-radio-group',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
})
export class RadioGroupComponent {
  @Input() options: RadioGroupOption[] = [];

  @Output() optionSelected = new EventEmitter();

  public selectOption(option: RadioGroupOption) {
    this.optionSelected.emit(option);
  }
}
