<v-card [center]="true" *ngIf="aal === 'aal1'" padding="lg">
  <!-- AAL1 Login Header -->
  <v-card-image-header
    slot="header"
    [text]="'pages.login.header' | translate"
    image="/assets/images/logos/incidite-full-white.svg"
    imageClass="!h-7"></v-card-image-header>

  <div class="vops-kratos-login-form mt-4" slot="body">
    <div class="vops-kratos-login-loading" *ngIf="initialFlowLoading">
      <p-skeleton height="100%"></p-skeleton>
      <p-skeleton height="100%"></p-skeleton>
      <p-skeleton height="100%"></p-skeleton>
    </div>

    <app-kratos-form
      [loading]="isCompletingLoginFlow"
      *ngIf="!initialFlowLoading && loginFlow"
      [flow]="loginFlow"
      [groups]="['password', 'default', 'oidc']"
      testIdPrefix="login-form-"
      (kratosFormSubmit)="submitFlow($event)"></app-kratos-form>
  </div>

  <div class="mt-6 text-center text-600 flex justify-between" slot="footer">
    <a
      tabindex="0"
      class="font-medium text-link block no-underline"
      [routerLink]="['/register']">
      {{ 'pages.login.createAccount' | translate }}
    </a>
    <a
      tabindex="0"
      class="font-medium text-link block no-underline"
      [routerLink]="['/recovery']">
      {{ 'pages.login.forgotPassword' | translate }}
    </a>
  </div>
</v-card>

<v-card [center]="true" *ngIf="aal === 'aal2'" padding="lg">
  <v-card-image-header
    slot="header"
    [text]="'pages.login.twoFAHeader' | translate"
    image="/assets/images/logos/incidite-full-white.svg"></v-card-image-header>

  <div slot="body">
    <app-kratos-form
      *ngIf="twoFAFlow"
      [loading]="isCompletingTwoFAFlow"
      [flow]="twoFAFlow"
      [groups]="['totp', 'webauthn', 'default']"
      (kratosFormSubmit)="submitTwoFAFlow($event)"></app-kratos-form>
  </div>
</v-card>
