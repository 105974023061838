import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { DialogService } from 'primeng/dynamicdialog';
import { catchError, map, of } from 'rxjs';
import { toIdentifiableEntities } from 'src/app/helpers/graphql';
import { Organization, OrganizationMember } from 'src/app/models/organization';
import { SlackChannel } from 'src/app/models/slack';
import { Invitation } from 'src/app/services/authentication/kratos.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import {
  addListEntity,
  ListEntities,
  loadEntities,
  NewListEntities,
  removeListEntity,
  updateListEntity,
} from 'src/app/store/common/list-entity';
import { UserState } from 'src/app/store/user/user.state';
import { Entity, loadEntity, NewEntity } from '../common/entity';
import {
  CreateInvitationAction,
  DeleteInvitationAction,
  DeleteMembershipAction,
  LoadOrganizationAction,
  LoadOrganizationInvitationsAction,
  LoadOrganizationMembersAction,
  UpdateOrganizationMemberRoleAction,
} from './organization.actions';
import { OrganizationService } from './organization.service';

export interface OrganizationDetailsStateModel {
  organization: Entity<Organization>;
  members: ListEntities<OrganizationMember>;
  invitations: ListEntities<Invitation>;
  slackChannels: ListEntities<SlackChannel>;
}

@State<OrganizationDetailsStateModel>({
  name: 'organizationDetails',
  children: [],
  defaults: {
    slackChannels: NewListEntities<SlackChannel>(),
    members: NewListEntities<OrganizationMember>(),
    invitations: NewListEntities<Invitation>(),
    organization: NewEntity<Organization>(),
  },
})
@Injectable()
export class OrganizationDetailsState {
  store = inject(Store);
  organizationService = inject(OrganizationService);
  toastService = inject(ToastService);
  dialogService = inject(DialogService);

  @Selector()
  static slackChannels(state: OrganizationDetailsStateModel) {
    return state.slackChannels;
  }

  @Selector()
  static members(state: OrganizationDetailsStateModel) {
    return state.members;
  }

  @Selector()
  static invitations(state: OrganizationDetailsStateModel) {
    return state.invitations;
  }

  @Selector()
  static organization(state: OrganizationDetailsStateModel) {
    return state.organization;
  }

  @Action(LoadOrganizationAction)
  loadOrganization(ctx: StateContext<OrganizationDetailsStateModel>) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return loadEntity(
      ctx,
      'organization',
      this.organizationService.loadOrganization(organizationID)
    );
  }

  @Action(LoadOrganizationMembersAction)
  loadOrganizationMembers(ctx: StateContext<OrganizationDetailsStateModel>) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return loadEntities(
      ctx,
      'members',
      this.organizationService.getOrganizationMembers(organizationID).pipe(
        map(members => {
          return toIdentifiableEntities(members, 'id');
        })
      )
    );
  }

  @Action(UpdateOrganizationMemberRoleAction)
  updateOrganizationMemberRole(
    ctx: StateContext<OrganizationDetailsStateModel>,
    { membershipId, newRoleId }: UpdateOrganizationMemberRoleAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return updateListEntity<OrganizationDetailsStateModel, OrganizationMember>(
      ctx,
      'members',
      membershipId,
      this.organizationService
        .updateOrganizationMemberRole(organizationID, membershipId, newRoleId)
        .pipe(
          map(updatedRole => {
            return {
              role: updatedRole,
            };
          })
        ),
      true
    );
  }

  @Action(DeleteMembershipAction)
  deleteOrganizationMember(
    ctx: StateContext<OrganizationDetailsStateModel>,
    { membershipId }: DeleteMembershipAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return removeListEntity<OrganizationDetailsStateModel, OrganizationMember>(
      ctx,
      'members',
      membershipId,
      (entity: OrganizationMember) => {
        return entity.id !== membershipId;
      },
      this.organizationService.deleteOrganizationMember(
        organizationID,
        membershipId
      )
    ).pipe(
      catchError(err => {
        this.toastService.showError(new Error(err.message));
        return of();
      })
    );
  }

  @Action(LoadOrganizationInvitationsAction)
  loadInvitations(ctx: StateContext<OrganizationDetailsStateModel>) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return loadEntities(
      ctx,
      'invitations',
      this.organizationService.getInvitations(organizationID).pipe(
        map(invitations => {
          return toIdentifiableEntities(invitations, 'id');
        })
      )
    );
  }

  @Action(CreateInvitationAction)
  addInvitation(
    ctx: StateContext<OrganizationDetailsStateModel>,
    { email, roleId, validUntilDuration }: CreateInvitationAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return addListEntity(
      ctx,
      'invitations',
      'id',
      this.organizationService.createInvitation(
        organizationID,
        email,
        roleId,
        validUntilDuration
      )
    );
  }

  @Action(DeleteInvitationAction)
  deleteInvitation(
    ctx: StateContext<OrganizationDetailsStateModel>,
    { invitationId }: DeleteInvitationAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return removeListEntity(
      ctx,
      'invitations',
      invitationId,
      (invitation: Invitation) => {
        return invitation.id !== invitationId;
      },
      this.organizationService.deleteInvitation(organizationID, invitationId)
    );
  }
}
