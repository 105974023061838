import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { UserState } from './user.state';
import { UserService } from './user.service';

@NgModule({
  declarations: [],
  imports: [NgxsModule.forFeature([UserState])],
  providers: [UserService],
  exports: [],
})
export class UserStateModule {}
