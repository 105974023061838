import { Dialog } from '@angular/cdk/dialog';
import { inject, Injectable } from '@angular/core';
import { take } from 'rxjs';
import {
  ConfirmationDialogConfig,
  VConfirmationDialogComponent,
} from './dialogs/confirmation.component';

@Injectable({
  providedIn: 'root',
})
export class VKitDialogService {
  private dialog = inject(Dialog);

  public confirm(config: Partial<ConfirmationDialogConfig>): Promise<boolean> {
    return new Promise(resolve => {
      const ref = this.dialog.open(VConfirmationDialogComponent, {
        data: config,
        panelClass: 'v-dialog',
      });

      ref.closed.pipe(take(1)).subscribe(result => {
        resolve(result === true);
      });
    });
  }
}
