export class OAuth2ConfirmLoginChallengeAction {
  static readonly type = '[OAuth2] Confirm Login Challenge';
  constructor(public challenge: string) {}
}

export class OAuth2ConfirmConsentChallengeAction {
  static readonly type = '[OAuth2] Confirm Consent Challenge';
  constructor(public challenge: string) {}
}

export class OAuth2GetConsentInfoAction {
  static readonly type = '[OAuth2] Get Consent Info';
  constructor(public challenge: string) {}
}
