import { Params } from '@angular/router';
import { Session } from './user.state';

export class LoadUserAction {
  static readonly type = '[Auth] Load User';
  constructor() {}
}

export class SetSessionAction {
  static readonly type = '[Auth] Set Session';
  constructor(public session: Session) {}
}

export class SelectOrganizationAction {
  static readonly type = '[Auth] Select Organization';
  constructor(public organizationId: string) {}
}

export class LogoutAction {
  static readonly type = '[Auth] Logout';
  constructor(
    public redirectUrl?: string,
    public queryParams?: Params
  ) {}
}
