<div>
  <header class="flex flex-col px-3 pt-3 pb-1">
    <div class="flex justify-between items-center flex-1">
      <h4 class="font-medium text-gray-200 m-0 p-0">
        {{ header | translate }}
      </h4>
      <div class="text-gray-200 text-sm">
        {{ subheader | translate }}
      </div>
    </div>
    <div class="flex h-8 items-center">
      <p
        class="{{
          getFontSizeClass()
        }} font-bold m-0 p-0 v-stats-current-value">
        <span *ngIf="aggregation === 'mean'">⌀</span>
        {{ displayValue() }}
      </p>
    </div>
  </header>
  <div class="vops-stats-chart relative">
    <div
      *ngIf="loading"
      class="absolute w-full h-full rounded-lg flex justify-center items-center">
      <i class="pi pi-spinner pi-spin mr-2"></i>Loading
    </div>

    <div
      [ngClass]="{
        'opacity-20 pointer-events-none': loading
      }">
      @if (chartInitialized) {
        @if (showNotEnoughData && variant === 'chart') {
          <div
            class="flex justify-center items-center h-20 bg-gray-700/20 rounded-lg">
            <p class="text-subtle flex items-center">
              <i class="pi pi-exclamation-circle mr-2"></i>
              {{ 'components.statsCard.notEnoughData' | translate }}
            </p>
          </div>
        } @else {
          <apx-chart
            #chart
            [series]="chartOptions.series!"
            [chart]="chartOptions.chart!"
            [stroke]="chartOptions.stroke!"
            [markers]="chartOptions.markers!"
            [grid]="chartOptions.grid!"
            [dataLabels]="chartOptions.dataLabels!"
            [yaxis]="chartOptions.yaxis!"
            [xaxis]="chartOptions.xaxis!"
            [fill]="chartOptions.fill!"
            [tooltip]="chartOptions.tooltip!"></apx-chart>
        }
      } @else {
        <div
          class="flex justify-center items-center h-32 bg-gray-700/20 rounded-lg"></div>
      }
    </div>
  </div>
</div>
