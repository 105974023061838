import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AutosizeModule } from 'ngx-autosize';

import { IntlModule } from 'angular-ecmascript-intl';
import { PermissionModule } from '../services/permission/permission.module';
import { TranslationService } from '../services/translation/translation.service';
import { VKitModule } from './components/ui-kit/uikit.module';
import { PrimeNGModule } from './primeng.module';

@NgModule({
  imports: [
    // ng core
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PrimeNGModule,
    TranslateModule,
    AutosizeModule,
    PermissionModule,
    IntlModule,
    VKitModule,
  ],
  providers: [TranslationService],
  exports: [
    // ng core
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PrimeNGModule,
    TranslateModule,
    AutosizeModule,
    PermissionModule,
    IntlModule,
    VKitModule,
  ],
})
export class SharedCommonModule {}
