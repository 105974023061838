import { gql } from 'apollo-angular';

export const ME_QUERY = gql`
  query me {
    me {
      id
      email
      firstname
      lastname
      avatar
      verified
      createdAt
      icUserHash
      memberships {
        id
        organizationId
        organization {
          name
        }
        role {
          name
          permissionRoles
        }
      }
    }
  }
`;
