import { Alert } from './alert';
import { BroadcastChannel } from './broadcast-channel';
import {
  IncidentSchemaPriority,
  IncidentSchemaState,
  IncidentSchemaVersion,
} from './incident-schema';
import { Service } from './service';
import { Statuspage } from './statuspage';

export interface Incident {
  id: number;
  schemaVersion: IncidentSchemaVersion;
  title: string;
  summary: string;
  priority: IncidentSchemaPriority;
  state: IncidentSchemaState;
  createdAt: Date;
  affectedServices?: AffectedService[];
  statuspageLinks?: IncidentStatuspageLink[];
  roles?: IncidentRoleWithAssignment[];
  events?: {
    events: IncidentEvent[];
    totalSize: number;
    nextPageToken: string;
  };
  timings: IncidentTimings;
  broadcastMessages?: BroadcastMessage[];
}

export interface IncidentRelatedAlertList {
  alerts: Alert[];
  totalSize: number;
}

export interface IncidentSpec {
  schemaVersionId: string;
  priorityId: string;
  title: string;
  summary: string;
  affectedServiceIds: number[];
  statuspageIds: number[];
}

export interface AffectedService {
  service: Service;
  impact: INCIDENT_SERVICE_IMPAGE;
}

export interface IncidentStatuspageLink {
  statuspage: {
    id: number;
    name: string;
    customDomain?: string;
    domain: string;
    slug: string;
  };
}

export interface IncidentRoleWithAssignment {
  role: IncidentRole;
  assignments: IncidentRoleAssignment[];
}

export interface IncidentRoleAssignment {
  id: string;
  memberId: string;
  user?: {
    firstname: string;
    lastname: string;
    avatar: string;
  };
  createdAt: Date;
  updatedAt: Date;
}

export interface IncidentTimings {
  impactStarted?: Date;
  impactDetected?: Date;
  impactResolved?: Date;
}

export enum IncidentEventActor {
  SYSTEM = 'system',
  USER = 'user',
}

export interface IncidentEvent {
  id: string;
  type: string;
  payload:
    | IncidentEventPayloadRole
    | IncidentEventPayloadFieldUpdated
    | IncidentEventPayloadComment
    | IncidentEventPayloadAffectedService
    | IncidentEventPayloadStatuspage
    | IncidentEventPayloadEmpty;
  createdAt: Date;
  actor: {
    type: IncidentEventActor;
    user?: {
      firstname: string;
      lastname: string;
      avatar: string;
    };
    name?: string;
  };
}

export interface IncidentEventPayloadRole {
  role: IncidentRole;
  assignee: {
    firstname: string;
    lastname: string;
    avatar: string;
  };
}

export interface IncidentEventPayloadResponder {
  responder: {
    firstname: string;
    lastname: string;
    avatar: string;
  };
}

export interface IncidentEventPayloadFieldUpdated {
  field: string;
  oldValue: FieldValue;
  newValue: FieldValue;
}

export interface IncidentEventPayloadStateUpdated {
  oldState: IncidentSchemaState;
  newState: IncidentSchemaState;
}

export interface IncidentEventPayloadPriorityUpdated {
  oldPriority: IncidentSchemaState;
  newPriority: IncidentSchemaState;
}

export interface FieldValue {
  stringValue?: string;
  intValue?: number;
}

export interface IncidentEventPayloadComment {
  comment: string;
}

export interface IncidentEventPayloadAffectedService {
  service: Service;
  impact: string;
}

export interface IncidentEventPayloadStatuspage {
  statuspage: Statuspage;
}

export interface IncidentEventPayloadRelatedAlert {
  alert: Alert;
}

export interface IncidentEventPayloadEmpty {}

export interface IncidentRole {
  id: string;
  name: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface BroadcastMessage {
  id: string;
  channel: BroadcastChannel;
}

export enum INCIDENT_SERVICE_IMPAGE {
  UNKNOWN = 'unknown',
  MINOR = 'minor',
  MAJOR = 'major',
}
