import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetRealtimeConnectionStateAction } from './misc.actions';

export interface MiscStateModel {
  realtimeConnectionState: string;
}

@State<MiscStateModel>({
  name: 'misc',
  defaults: { realtimeConnectionState: 'disconnected' },
})
@Injectable()
export class MiscState {
  @Selector()
  static getRealtimeConnectionState(state: MiscStateModel) {
    return state.realtimeConnectionState;
  }

  @Action(SetRealtimeConnectionStateAction)
  setConnectionState(
    ctx: StateContext<MiscStateModel>,
    { connectionState }: SetRealtimeConnectionStateAction
  ) {
    ctx.patchState({
      realtimeConnectionState: connectionState,
    });
  }
}
