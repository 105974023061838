import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SidebarModule } from 'primeng/sidebar';
import { VIconButtonComponent } from '../buttons/icon-button/icon-button.component';

@Component({
  selector: 'v-sidebar',
  standalone: true,
  imports: [CommonModule, SidebarModule, VIconButtonComponent],
  templateUrl: './sidebar.component.html',
})
export class VSideBarComponent {
  @Input() sidebarVisible = false;

  @Input() position = 'right';

  @Input() title = '';

  public open() {
    this.sidebarVisible = true;
  }

  public close() {
    this.sidebarVisible = false;
  }
}
