import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { IncidentsService } from './incidents.service';
import { IncidentSchemasService } from './incidentSchemas.service';
import { IncidentsState } from './incidents.state';
import { IncidentDetailsState } from './incidentDetails.state';
import { IncidentSchemaDetailsState } from './incidentSchemaDetails.state';

@NgModule({
  declarations: [],
  imports: [
    NgxsModule.forFeature([
      IncidentsState,
      IncidentDetailsState,
      IncidentSchemaDetailsState,
    ]),
  ],
  providers: [IncidentsService, IncidentSchemasService],
  bootstrap: [],
  exports: [],
})
export class IncidentsStateModule {}
