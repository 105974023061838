<div class="flex flex-col">
  <div class="mb-2 flex justify-between items-center h-10" #toolbar>
    <div class="flex gap-2 items-center">
      @if (title) {
        <h3 [ngClass]="titleClasses">{{ title }}</h3>
      }
      @if (layout === 'list') {
        <v-icon-button
          icon="pi pi-th-large"
          variant="secondary"
          size="small"
          (press)="setLayout('grid')"></v-icon-button>
      } @else {
        <v-icon-button
          icon="pi pi-list"
          variant="secondary"
          size="small"
          (press)="setLayout('list')"></v-icon-button>
      }
      <v-button
        *ngIf="groupingKeys.length > 0"
        icon="pi pi-tag"
        size="small"
        variant="secondary"
        [label]="currentGroupingKey?.label || 'All'"
        (press)="groupingMenu.toggle($event)"></v-button>

      <p-overlayPanel
        #groupingMenu
        [appendTo]="toolbar"
        styleClass="v-data-display-overlay">
        <v-menu
          [items]="items"
          size="small"
          (selected)="groupingMenu.hide()"></v-menu>
      </p-overlayPanel>
    </div>
    <div *ngIf="showPaginator && paginatorTotalRecords > 0">
      <v-paginator
        [totalRecords]="paginatorTotalRecords"
        [rows]="paginatorRows"
        [page]="paginatorPage"
        [rowsPerPageOptions]="paginatorRowsPerPageOptions"
        [size]="size"
        (pageChange)="paginatorPageChange.emit($event)"></v-paginator>
    </div>
  </div>

  @if (elements.length === 0 && !isLoading) {
    <div class="flex flex-col items-center">
      <h3 class="mt-4 text-subtle">No data available</h3>
    </div>
  }

  @if (isLoading && elements.length === 0) {
    <!-- Initial Loader -->
    <div
      [ngClass]="
        [
          'grid',
          layout === 'grid' ? 'grid-cols-' + gridColumns : 'grid-cols-1',
          size === 'sm' ? 'gap-2' : 'gap-4'
        ].join(' ')
      ">
      <p-skeleton
        [height]="skeletonHeight"
        width="100%"
        styleClass="!rounded-lg"></p-skeleton>
      <p-skeleton
        [height]="skeletonHeight"
        width="100%"
        styleClass="!rounded-lg"></p-skeleton>
    </div>
  } @else {
    <div class="relative">
      <div
        class="absolute inset-0 flex items-center justify-center"
        *ngIf="isLoading">
        <i class="pi pi-spinner pi-spin !text-lg"></i>
      </div>

      <div
        [ngClass]="{
          'opacity-40 pointer-events-none': isLoading
        }">
        @if (currentGroupingKey) {
          <div *ngFor="let group of groups | keyvalue; let $first = first">
            <h3
              class="font-medium pb-2"
              [ngClass]="{
                'pt-4': !$first
              }">
              {{ group.value.label }}
            </h3>
            <ul
              [ngClass]="
                [
                  'grid',
                  layout === 'grid'
                    ? 'grid-cols-' + gridColumns
                    : 'grid-cols-1',
                  size === 'sm' ? 'gap-2' : 'gap-4'
                ].join(' ')
              ">
              @for (item of group.value.elements; track item) {
                <ng-container
                  *ngTemplateOutlet="item.templateRef"></ng-container>
              }
            </ul>
          </div>
        } @else {
          <ul
            [ngClass]="
              [
                'grid',
                layout === 'grid' ? 'grid-cols-' + gridColumns : 'grid-cols-1',
                size === 'sm' ? 'gap-2' : 'gap-4'
              ].join(' ')
            ">
            @for (item of elements; track item) {
              <ng-container *ngTemplateOutlet="item.templateRef"></ng-container>
            }
          </ul>
        }
      </div>
    </div>
  }
</div>
