import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  LoginFlow,
  RecoveryFlow,
  RegistrationFlow,
  SettingsFlow,
  UiNode,
  UiNodeInputAttributes,
  UiText,
} from '@ory/client';
import { KratosAuthenticationService } from 'src/app/services/authentication/kratos.service';
import { SharedCommonModule } from '../../common.module';

import { TranslateService } from '@ngx-translate/core';
import {
  FilterNodesByGroups,
  filterNodesByGroups,
  getNodeLabel,
  isUiNodeImageAttributes,
  isUiNodeInputAttributes,
  isUiNodeTextAttributes,
} from '@ory/integrations/ui';
import { VKitModule } from '../ui-kit/uikit.module';

@Component({
  selector: 'app-kratos-form',
  standalone: true,
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  imports: [SharedCommonModule, VKitModule],
  providers: [KratosAuthenticationService],
})
export class KratosFormComponent implements OnChanges {
  private fb = inject(FormBuilder);
  private cd = inject(ChangeDetectorRef);
  private translate = inject(TranslateService);

  @Input() loading = false;
  @Input() flow?: RegistrationFlow | LoginFlow | RecoveryFlow | SettingsFlow;
  @Input() groups: string[] = [];

  // testIdPrefix is used to prefix the test ids of the form elements
  @Input() testIdPrefix?: string;

  @Output() kratosFormSubmit = new EventEmitter();

  kratosForm: FormGroup = this.fb.group({});
  uiNodes: UiNode[] = [];

  formMessages: UiText[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['flow']) {
      this.setFlow(changes['flow'].currentValue);
    }
    if (changes['loading']) {
      if (changes['loading'].currentValue) {
        this.kratosForm.disable();
      } else {
        this.kratosForm.enable();
      }
    }
  }

  public getVisibleNodes(nodes: UiNode[]): UiNode[] {
    const visibleNodes = this.filterUINodes({
      nodes: nodes,
      withoutDefaultGroup: true,
      groups: this.groups,
      excludeAttributes: ['submit', 'button'],
    }).filter(node => {
      if (node.attributes.node_type === 'input') {
        return (node.attributes as UiNodeInputAttributes).type !== 'hidden';
      }
      if (node.attributes.node_type === 'script') {
        return false;
      }
      return true;
    });

    return visibleNodes;
  }

  public isInputNode(node: UiNode) {
    return isUiNodeInputAttributes(node.attributes);
  }

  public isImageNode(node: UiNode) {
    return isUiNodeImageAttributes(node.attributes);
  }

  public isTextNode(node: UiNode) {
    return isUiNodeTextAttributes(node.attributes);
  }
  public getTestId(node: UiNode) {
    const label = getNodeLabel(node).toLowerCase().replaceAll(' ', '-');
    return `${this.testIdPrefix}${(node.attributes as any).type}-${label}`;
  }

  public getUiNodeLabel(node: UiNode) {
    const label = getNodeLabel(node).toLowerCase();

    switch (label) {
      case 'e-mail':
      case 'email':
      case 'id':
        return 'components.kratosForm.emailInput';
      case 'password':
        return 'components.kratosForm.passwordInput';
      case 'first name':
        return 'components.kratosForm.firstnameInput';
      case 'last name':
        return 'components.kratosForm.lastnameInput';
      case 'authenticator app qr code':
        return 'components.kratosForm.twoFAQRCode';
      case 'unlink totp authenticator app':
        return 'components.kratosForm.unlinkTotp';
      case 'authentication code':
        return 'components.kratosForm.authenticationCode';
      case 'this is your authenticator app secret. use it if you can not scan the qr code.':
        return 'components.kratosForm.twoFAManualCode';
      case 'verify code':
        return 'components.kratosForm.twoFAVerifyCode';
      case 'sign in':
        return 'components.kratosForm.signInBtn';
      case 'sign in and link':
        return 'components.kratosForm.signInAndLinkBtn';
      case 'sign up':
        return 'components.kratosForm.signUpBtn';
      case 'submit':
        return 'components.kratosForm.submitBtn';
      case 'save':
        return 'components.kratosForm.saveBtn';
      case 'resend code':
        return 'components.kratosForm.resendCode';
      case 'use authenticator':
        return 'components.kratosForm.twoFaUseTOTP';
      case 'use security key':
        return 'components.kratosForm.twoFaUseSecurityKey';
      case 'add security key':
        return 'components.kratosForm.twoFaAddSecurityKey';
      case 'name of the security key':
        return 'components.kratosForm.twoFaAddSecurityKeyName';
    }

    const removeKeyRegex = /remove security key "(.*)"/.exec(label);
    if (removeKeyRegex) {
      return this.translate.instant(
        'components.kratosForm.twoFaRemoveSecurityKey',
        { keyName: removeKeyRegex[1] }
      );
    }

    return label;
  }

  public filterUINodes(filter: FilterNodesByGroups) {
    return filterNodesByGroups(filter);
  }

  public excludeGroups(groups: string[], excludeGroups: string[]) {
    return groups.filter(group => !excludeGroups.includes(group));
  }

  public showOIDCGroups(): boolean {
    return this.groups.includes('oidc');
  }

  public getOIDCProvider(node: UiNode) {
    return (node.attributes as any).value;
  }

  private setFormMessages(messages: UiText[]) {
    // remove 'Please confirm this action by verifying that it is you.' (1010004)
    // when 'Please complete the second authentication challenge.' (1010003) is also included

    if (messages.findIndex(m => m.id === 1010004) > -1) {
      messages = messages.filter(m => m.id !== 1010003);
    }

    this.formMessages = messages;
  }

  setFlow(flow: LoginFlow | RegistrationFlow | RecoveryFlow | SettingsFlow) {
    this.flow = flow;

    const nodes = this.filterUINodes({
      nodes: flow.ui.nodes,
      withoutDefaultAttributes: true,
    });

    this.setFormMessages(flow.ui.messages || []);

    // create form fields
    nodes.forEach(node => {
      // check if node is in group
      if (node.group !== 'default' && !this.groups.includes(node.group)) {
        return;
      }

      if (isUiNodeInputAttributes(node.attributes)) {
        // hidden elements like csrf_token are added as controls, but
        // will not be displayed in the form
        if (!this.kratosForm.controls[node.attributes.name]) {
          this.kratosForm?.addControl(
            node.attributes.name,
            this.fb.control(
              node.attributes.value,
              node.attributes.required ? Validators.required : null
            )
          );
        }
      }
    });

    this.uiNodes = flow.ui.nodes.map(node => {
      if (node.meta.label?.id === 1070008) {
        (node.attributes as any).type = 'button';
      }
      return node;
    });

    // wait for the change detection to run before setting
    // form control errors as they would be removed otherwise
    // due to re-rendering the form when setting the array
    this.cd.detectChanges();

    // create form fields
    nodes.forEach(node => {
      if (isUiNodeInputAttributes(node.attributes)) {
        const control = this.kratosForm.controls[node.attributes.name];

        // check for errors
        if (node.messages.length > 0) {
          control.setErrors({
            validation: true,
          });
          control.markAsTouched();
        }
      }
    });
  }

  submit(customBtnNode?: UiNode) {
    let kratosBody: any = {};

    this.uiNodes.forEach(node => {
      if (node.group !== 'default' && !this.groups.includes(node.group)) {
        return;
      }

      if (node.attributes.node_type === 'input') {
        const attributes = node.attributes as UiNodeInputAttributes;

        if (
          (customBtnNode && attributes.type === 'submit') ||
          attributes.type === 'button'
        ) {
          return;
        }

        this.createNestedObject(
          kratosBody,
          attributes.name,
          this.kratosForm?.value[attributes.name]
        );
      }
    });

    if (customBtnNode) {
      const attributes = customBtnNode.attributes as UiNodeInputAttributes;
      kratosBody.method = customBtnNode.group;
      kratosBody = this.createNestedObject(
        kratosBody,
        attributes.name,
        attributes.value
      );
    }

    this.kratosFormSubmit.emit(kratosBody);
  }

  createNestedObject(base: any, path: string, value: any) {
    const keys = path.split('.');
    keys.reduce((prev, curr, index) => {
      return (prev[curr] =
        index === keys.length - 1 ? value : prev[curr] || {});
    }, base);
    return base;
  }

  onBtnClick(node: UiNode) {
    const attributes = node.attributes as UiNodeInputAttributes;
    if (attributes.name === 'webauthn_register_trigger') {
      this.webauthnRegistration(node);
    } else if (attributes.name === 'webauthn_login_trigger') {
      this.webauthnLogin(node);
    } else {
      this.submit(node);
    }
  }

  public webauthnRegistration(node: UiNode) {
    const onClickAttr = (node.attributes as any).onclick;

    const startIndex = onClickAttr.indexOf('{');
    const endIndex = onClickAttr.lastIndexOf('}');
    const fidoConfig = JSON.parse(
      onClickAttr.substring(startIndex, endIndex + 1)
    );

    fidoConfig.publicKey.challenge = this.__oryWebAuthnBufferDecode(
      fidoConfig.publicKey.challenge
    );
    fidoConfig.publicKey.user.id = this.__oryWebAuthnBufferDecode(
      fidoConfig.publicKey.user.id
    );

    navigator.credentials.create(fidoConfig).then(res => {
      if (!res || res.type !== 'public-key')
        throw Error('failed to create public key');

      const credentials = res as PublicKeyCredential;
      const attestationObject =
        credentials.response as AuthenticatorAttestationResponse;

      const result = {
        id: credentials.id,
        rawId: this.__oryWebAuthnBufferEncode(credentials.rawId),
        type: credentials.type,
        response: {
          attestationObject: this.__oryWebAuthnBufferEncode(
            attestationObject.attestationObject
          ),
          clientDataJSON: this.__oryWebAuthnBufferEncode(
            credentials.response.clientDataJSON
          ),
        },
      };

      this.kratosForm.controls['webauthn_register'].setValue(
        JSON.stringify(result)
      );

      this.submit(node);
    });
  }

  public webauthnLogin(node: UiNode) {
    const onClickAttr = (node.attributes as any).onclick;

    const startIndex = onClickAttr.indexOf('{');
    const endIndex = onClickAttr.lastIndexOf('}');
    const fidoConfig = JSON.parse(
      onClickAttr.substring(startIndex, endIndex + 1)
    );

    fidoConfig.publicKey.challenge = this.__oryWebAuthnBufferDecode(
      fidoConfig.publicKey.challenge
    );

    fidoConfig.publicKey.allowCredentials =
      fidoConfig.publicKey.allowCredentials.map((value: any) => {
        return {
          ...value,
          id: this.__oryWebAuthnBufferDecode(value.id),
        };
      });

    navigator.credentials.get(fidoConfig).then(res => {
      if (!res || res.type !== 'public-key')
        throw Error('failed to create public key');

      const credentials = res as PublicKeyCredential;
      const attestationObject =
        credentials.response as AuthenticatorAssertionResponse;

      const result = {
        id: credentials.id,
        rawId: this.__oryWebAuthnBufferEncode(credentials.rawId),
        type: credentials.type,
        response: {
          authenticatorData: this.__oryWebAuthnBufferEncode(
            attestationObject.authenticatorData
          ),
          clientDataJSON: this.__oryWebAuthnBufferEncode(
            attestationObject.clientDataJSON
          ),
          signature: this.__oryWebAuthnBufferEncode(
            attestationObject.signature
          ),
          userHandle: this.__oryWebAuthnBufferEncode(
            attestationObject.userHandle!
          ),
        },
      };

      this.kratosForm.controls['webauthn_login'].setValue(
        JSON.stringify(result)
      );

      this.submit(node);
    });
  }

  __oryWebAuthnBufferDecode(value: any) {
    return Uint8Array.from(
      atob(value.replaceAll('-', '+').replaceAll('_', '/')),
      function (c) {
        return c.charCodeAt(0);
      }
    );
  }

  __oryWebAuthnBufferEncode(value: ArrayBuffer) {
    return btoa(String.fromCharCode.apply(null, new Uint8Array(value) as any))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');
  }
}
