<div
  class="h-4 w-4 flex-none rounded-full p-1"
  [ngClass]="{
    'bg-green-500/30 text-green-500': status === 'success',
    'bg-yellow-500/30 text-yellow-500': status === 'warning',
    'bg-red-500/30 text-red-500': status === 'error',
    'bg-gray-100/10 text-gray-500': status === 'neutral'
  }">
  <div class="h-2 w-2 rounded-full bg-current"></div>
</div>
