import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlContainer,
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'v-dropdown',
  standalone: true,
  imports: [CommonModule, DropdownModule, FormsModule, ReactiveFormsModule],
  styleUrl: './dropdown.component.scss',
  templateUrl: './dropdown.component.html',
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class VDropdownComponent {
  /**
   * Options to display in the dropdown
   */
  @Input()
  public options: any[] = [];

  /**
   * Placeholder text to display
   */
  @Input()
  public placeholder: string = 'Select';

  /**
   * Optional form control name
   */
  @Input()
  fControlName?: string;

  /**
   * The key to use for the option label
   */
  @Input()
  optionLabel: string = 'label';

  /**
   * The key to use for the option value
   */
  @Input()
  optionValue: string = 'value';

  @Input()
  appendDropdownTo?: any;

  @Output()
  changed = new EventEmitter<DropdownChangeEvent>();
}
