<v-dialog-wrapper>
  <div class="px-4 pb-4 pt-5 sm:p-6 sm:pb-4 bg-card">
    <div class="flex items-start">
      <div
        class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-700/15">
        <i class="pi pi-exclamation-triangle text-danger !text-2xl"></i>
      </div>
      <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left flex-1">
        <h3
          class="text-base font-semibold leading-6 text-primary"
          id="modal-title">
          {{ header }}
        </h3>
        <div class="mt-2">
          <p class="text-sm text-subtle">{{ description }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-card-active px-4 py-3 flex flex-row gap-2 justify-end">
    <v-button
      variant="secondary"
      (press)="dialogWrapper.close(false)"
      [icon]="cancelIcon"
      [label]="cancelText"></v-button>
    <v-button
      variant="danger"
      (press)="dialogWrapper.close(true)"
      [icon]="confirmIcon"
      [label]="confirmText"></v-button>
  </div>
</v-dialog-wrapper>
