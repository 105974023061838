import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { Store } from '@ngxs/store';
import { UserState } from 'src/app/store/user/user.state';
import { LoadUserAction, LogoutAction } from 'src/app/store/user/user.actions';

// AuthGuard checks wether a user has a valid session and at least one membership
export const AuthGuard = async () => {
  const router = inject(Router);
  const store = inject(Store);

  try {
    let user = store.selectSnapshot(UserState.getUser);

    // when user is empty we probably did not fetch it yet as the user just logged in -> fetch
    if (!user) {
      await firstValueFrom(store.dispatch(new LoadUserAction()));
      user = store.selectSnapshot(UserState.getUser);
    }

    if (!user) {
      throw new Error('No user');
    }

    // validate session
    const session = store.selectSnapshot(UserState.getSession);
    if (!session || Date.now() > session?.expiresAt.getTime()) {
      // logout if session is expired
      store.dispatch(new LogoutAction());

      throw new Error('Session expired');
    }

    // check for membership
    if (user.memberships.length == 0) {
      router.navigate(['/organizations/new']);
    }

    return true;
  } catch (ex) {
    console.warn(ex);
    router.navigate(['/login']);
  }

  return true;
};
