<p-multiSelect
  [options]="options"
  [optionLabel]="optionLabel"
  [optionValue]="optionValue"
  [placeholder]="placeholder"
  [formControlName]="fControlName!"
  [appendTo]="appendDropdownTo"
  panelStyleClass="v-multiselect-panel"
  styleClass="v-multiselect"
  display="chip"></p-multiSelect>
