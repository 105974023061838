import { patch } from '@ngxs/store/operators';
import {
  DefaultListEntitiesState,
  IdentifiableEntities,
  ListEntities,
} from './list-entity';
import {
  PaginatedListEntitiesState,
  PaginatedListEntityFields,
} from './paginated-list-entity';

export type PaginatedResponse<T> =
  | CursorPaginatedResponse<T>
  | OffsetPaginatedRespose<T>;

export interface CursorPaginatedResponse<T> {
  items: IdentifiableEntities<T>;
  totalSize: number;
  nextPageToken: string;
}

export interface OffsetPaginatedRespose<T> {
  items: IdentifiableEntities<T>;
  totalSize: number;
}

export type PaginationOptions =
  | CursorPaginationOptions
  | OffsetPaginationOptions;

export interface CursorPaginationOptions {
  pageToken?: string;
  pageSize: number;
}

export interface OffsetPaginationOptions {
  page: number;
  pageSize: number;
}

export type PaginationState = CursorPaginationState | OffsetPaginationState;

export interface CursorPaginationState {
  totalRecords: number;
  pageSize: number;
  nextPageToken: string;
  pageToken: string;
}

export interface OffsetPaginationState {
  totalRecords: number;
  pageSize: number;
  page: number;
}

export type Pagination = CursorPagination | OffsetPagination;

export interface IPagination {
  optionsToState(options: PaginationOptions): object;
  responseToState(
    state: PaginatedListEntitiesState<any>,
    response: PaginatedResponse<any>
  ): PaginatedListEntityFields<any>;
}

export class CursorPagination implements IPagination {
  static GetDefaultState<T>(): PaginatedListEntitiesState<
    T,
    CursorPaginationState
  > {
    return {
      ...DefaultListEntitiesState,
      pagination: {
        pageSize: 10,
        nextPageToken: '',
        totalRecords: 0,
        pageToken: '',
      },
    };
  }

  responseToState(
    state: PaginatedListEntitiesState<any, CursorPaginationState>,
    response: CursorPaginatedResponse<any>
  ): PaginatedListEntityFields<any> {
    return {
      entities:
        state.pagination.pageToken === ''
          ? response.items
          : patch(response.items),
      pagination: patch({
        totalRecords: response.totalSize,
        nextPageToken: response.nextPageToken,
      }),
    };
  }

  optionsToState(options: CursorPaginationOptions) {
    return options;
  }
}

export class OffsetPagination implements IPagination {
  static GetDefaultState<T>(
    defaultPageSize = 10
  ): PaginatedListEntitiesState<T, OffsetPaginationState> {
    return {
      ...DefaultListEntitiesState,
      pagination: {
        page: 1,
        pageSize: defaultPageSize,
        totalRecords: 0,
      },
    };
  }

  responseToState(
    state: PaginatedListEntitiesState<any, OffsetPaginationState>,
    response: OffsetPaginatedRespose<any>
  ): PaginatedListEntityFields<ListEntities<any>> {
    return {
      entities: response.items,
      pagination: patch({
        totalRecords: response.totalSize,
      }),
    };
  }

  optionsToState(options: OffsetPaginationOptions) {
    return options;
  }
}
