<li
  tabindex="0"
  (click)="press.emit()"
  (keydown.enter)="press.emit()"
  [ngClass]="{
    'bg-gray-900/30 hover:bg-gray-900/40 active:bg-gray-900/60': dark,
    'bg-card hover:bg-card-hover active:bg-card-active': !dark,
    'h-full': fullHeight
  }"
  class="relative flex items-center space-x-4 rounded-lg p-4 cursor-pointer">
  <div class="min-w-0 flex-auto">
    <div class="flex items-center gap-x-3">
      @if (status) {
        <v-status-indicator [status]="status"></v-status-indicator>
      }
      @if (icon) {
        <i class="pi {{ icon }}"></i>
      }
      <h2 class="min-w-0 truncate text-md font-semibold leading-6 text-primary">
        <span class="truncate">{{ title }}</span>
      </h2>
    </div>
    <div class="mt-3 flex items-center gap-x-2.5 text-sm font-medium leading-5">
      <p class="truncate text-subtle">{{ subtitle }}</p>
    </div>
  </div>
  <svg
    class="h-5 w-5 flex-none text-gray-400"
    viewBox="0 0 20 20"
    fill="currentColor"
    aria-hidden="true">
    <path
      fill-rule="evenodd"
      d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
      clip-rule="evenodd" />
  </svg>
</li>
