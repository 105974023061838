import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { VButtonComponent } from '../../buttons/button/button.component';

@Component({
  selector: 'v-basic-header',
  standalone: true,
  imports: [CommonModule, VButtonComponent],
  templateUrl: './basic-header.component.html',
})
export class VBasicHeaderComponent {
  @Input()
  title?: string = '';

  @Input()
  subtitle?: string = '';

  @Input()
  titleSize: 'sm' | 'md' | 'lg' = 'md';
}
