import { Injectable, inject } from '@angular/core';
import { Actions, Store, ofActionCompleted } from '@ngxs/store';

import posthog from 'posthog-js';
import { forkJoin, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoadOrganizationAction } from '../organization/organization.actions';
import { OrganizationDetailsState } from '../organization/organization.state';
import { LoadUserAction } from '../user/user.actions';
import { UserState } from '../user/user.state';
import { UpdateFeatureFlagsAction } from './features.actions';

@Injectable()
export class FeaturesService {
  store = inject(Store);
  actions = inject(Actions);

  constructor() {
    posthog.onFeatureFlags(
      (flags: string[], variants: Record<string, string | boolean>) => {
        this.store.dispatch(new UpdateFeatureFlagsAction(variants));
      }
    );

    forkJoin([
      this.actions.pipe(ofActionCompleted(LoadUserAction), take(1)),
      this.actions.pipe(ofActionCompleted(LoadOrganizationAction), take(1)),
    ]).subscribe(() => {
      const user = this.store.selectSnapshot(UserState.getUser);
      const organization = this.store.selectSnapshot(
        OrganizationDetailsState.organization
      );

      if (organization.data) {
        posthog.identify(user!.id, {
          email: user?.email,
          organizationId: organization.data.id,
          environment: environment.posthog.environment,
        });

        posthog.group('company', organization.data.id, {
          name: organization.data.name,
          plan: organization.data.subscription.plan.name,
          environment: environment.posthog.environment,
        });
      }
    });
  }
}
