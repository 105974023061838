export class ErrReauthenticationNeeded extends Error {
  constructor() {
    super('Reauthentication needed');
  }
}

export class ErrAlreadyLoggedIn extends Error {
  constructor() {
    super('Already logged in');
  }
}

export class ErrTwoFARequired extends Error {
  constructor() {
    super('Two Factor required');
  }
}

export class ErrBrowserLocationChangeRequired extends Error {
  redirectUri: string;

  constructor(redirectUri: string) {
    super('Browser Location Change required');

    this.redirectUri = redirectUri;
  }
}
