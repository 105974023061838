<div class="w-full flex justify-between items-center">
  <div>
    <h1
      class="text-2xl font-semibold text-primary"
      [ngClass]="{
        'text-2xl': titleSize === 'lg',
        'text-xl': titleSize === 'md',
        'text-lg': titleSize === 'sm'
      }">
      {{ title }}
    </h1>
    <p class="text-sm text-subtle">{{ subtitle }}</p>
  </div>
  <div class="flex gap-x-2">
    <ng-content></ng-content>
  </div>
</div>
