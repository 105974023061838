import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  ControlContainer,
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { AutosizeModule } from 'ngx-autosize';

@Component({
  selector: 'v-textarea',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, FormsModule, AutosizeModule],
  templateUrl: './textarea.component.html',
  styleUrl: './textarea.component.scss',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class VTextareaComponent {
  /**
   * Placeholder text to display
   */
  @Input()
  placeholder: string = 'Enter text';

  /**
   * Amount of rows to display in the textarea by default
   */
  @Input()
  rows = 3;

  /**
   * Sets the minimum amount of rows to display in the textarea
   */
  @Input()
  minRows?: number;

  /**
   * Optional form control name
   */
  @Input()
  fControlName?: string;

  /**
   * Switch to make the textarea read only
   */
  @Input()
  readOnly = false;
}
