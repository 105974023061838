import { Injectable, inject } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  createSelector,
} from '@ngxs/store';

import { Router } from '@angular/router';
import { UpdateFeatureFlagsAction } from './features.actions';
import { FeaturesService } from './features.service';
import { FLAGS } from './flags';

export type FeatureFlags = Record<string, string | boolean>;

export interface FeaturesStateModel {
  flags: FeatureFlags;
}

@State<FeaturesStateModel>({
  name: 'features',
  defaults: { flags: {} },
})
@Injectable()
export class FeaturesState {
  private fs = inject(FeaturesService);
  private router = inject(Router);

  static boolFlag(flag: string) {
    return createSelector([FeaturesState], (state: FeaturesStateModel) => {
      return state.flags[flag] ? state.flags[flag] : false;
    });
  }

  @Selector()
  static flags(state: FeaturesStateModel) {
    return state.flags;
  }

  @Action(UpdateFeatureFlagsAction)
  updateFeatureFlags(
    ctx: StateContext<FeaturesStateModel>,
    action: UpdateFeatureFlagsAction
  ) {
    ctx.patchState({
      flags: action.flags,
    });

    if (action.flags[FLAGS.HALO_PROTOCOL_FRONTENT]) {
      this.router.navigate(['/maintenance']);
    }
  }
}
