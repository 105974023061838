import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { IncidentSchema } from 'src/app/models/incident-schema';
import { ToastService } from 'src/app/services/toast/toast.service';
import { Entity, NewEntity, loadEntity } from 'src/app/store/common/entity';
import { UserState } from 'src/app/store/user/user.state';
import { LoadIncidentSchemaDetails } from './incidentSchemaDetails.actions';
import { IncidentSchemasService } from './incidentSchemas.service';

export interface IncidentSchemaDetailsStateModel {
  schema: Entity<IncidentSchema>;
}

@State<IncidentSchemaDetailsStateModel>({
  name: 'incidentSchemaDetails',
  defaults: {
    schema: NewEntity(),
  },
})
@Injectable()
export class IncidentSchemaDetailsState {
  store = inject(Store);
  incidentSchemaService = inject(IncidentSchemasService);
  toast = inject(ToastService);
  translate = inject(TranslateService);

  @Selector()
  static schema(
    state: IncidentSchemaDetailsStateModel
  ): Entity<IncidentSchema> {
    return state.schema;
  }

  @Action(LoadIncidentSchemaDetails)
  loadIncidentSchemaDetails(
    ctx: StateContext<IncidentSchemaDetailsStateModel>,
    { id }: LoadIncidentSchemaDetails
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    const observable = this.incidentSchemaService.getIncidentSchema(
      organizationID,
      id
    );

    return loadEntity(ctx, 'schema', observable);
  }
}
