import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError } from 'rxjs';
import { Statuspage } from 'src/app/models/statuspage';
import { ToastService } from 'src/app/services/toast/toast.service';
import {
  addListEntity,
  removeListEntity,
} from 'src/app/store/common/list-entity';
import {
  PaginatedListEntities,
  PaginatedListEntitiesState,
} from 'src/app/store/common/paginated-list-entity';
import {
  OffsetPagination,
  OffsetPaginationState,
} from 'src/app/store/common/pagination';
import { UserState } from 'src/app/store/user/user.state';
import { StatuspageDetailsState } from './statuspage-details.state';
import { StatuspageService } from './statuspage.service';
import {
  CreateStatuspageAction,
  DeleteStatuspageAction,
  LoadStatuspagesAction,
} from './statuspages.actions';

export interface StatuspageStateModel {
  statuspages: PaginatedListEntitiesState<Statuspage, OffsetPaginationState>;
}

@State<StatuspageStateModel>({
  name: 'statuspages',
  defaults: {
    statuspages: OffsetPagination.GetDefaultState(),
  },
  children: [StatuspageDetailsState],
})
@Injectable()
export class StatuspageState {
  store = inject(Store);
  toast = inject(ToastService);
  statuspageService = inject(StatuspageService);

  statuspagePagination = new PaginatedListEntities<
    StatuspageStateModel,
    OffsetPagination
  >('statuspages', new OffsetPagination());

  @Selector()
  static statuspages(state: StatuspageStateModel) {
    return state.statuspages;
  }

  @Selector()
  static lastCreatedStatuspageId(state: StatuspageStateModel) {
    return state.statuspages.latestCreatedId;
  }

  @Action(LoadStatuspagesAction)
  loadStatuspages(ctx: StateContext<StatuspageStateModel>) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return this.statuspagePagination.loadEntititesPaginated(
      ctx,
      { page: 1, pageSize: 10 },
      this.statuspageService.loadStatuspages(organizationID)
    );
  }

  @Action(DeleteStatuspageAction)
  deleteStatusPage(
    ctx: StateContext<StatuspageStateModel>,
    { statuspageId }: DeleteStatuspageAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return removeListEntity(
      ctx,
      'statuspages',
      statuspageId,
      (sp: Statuspage) => {
        return sp.id !== statuspageId;
      },
      this.statuspageService.deleteStatuspage(organizationID, statuspageId)
    ).pipe(
      catchError(err => {
        this.toast.showError(err);
        throw err;
      })
    );
  }

  @Action(CreateStatuspageAction)
  createStatusPage(
    ctx: StateContext<StatuspageStateModel>,
    { spec }: CreateStatuspageAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return addListEntity(
      ctx,
      'statuspages',
      'id',
      this.statuspageService.createStatuspage(organizationID, {
        name: spec.name,
        visibility: spec.visibility,
      })
    ).pipe(
      catchError(err => {
        this.toast.showError(err);
        throw err;
      })
    );
  }
}
