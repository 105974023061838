import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl, AbstractControlDirective } from '@angular/forms';
import { NamedValidator } from 'src/app/core/validators/oneOf.validator';

@Component({
  selector: 'v-form-error-component',
  standalone: true,
  imports: [CommonModule],
  templateUrl: 'form-error.component.html',
  styleUrl: 'form-error.component.scss',
})
export class FormErrorComponent {
  errorMsgList: any = [];

  @Input() control?: AbstractControl | AbstractControlDirective;

  errorMessage: any = {
    required: () => `This field is required`,
    min: (params: any) => `Minimum value must be ${params.min}`,
    max: (params: any) => `Maximum value must be ${params.max}`,
    maxlength: (params: any) =>
      `Maximum ${params.requiredLength} characters are allowed`,
    minlength: (params: any) =>
      `Minimum ${params.requiredLength} characters are required`,
    pattern: () => `Invalid format`,
    whitespace: () => `White spaces are not allowed`,
    hexcolor: () => `Invalid hex color`,
    domain: () => `Invalid domain`,
    restrictedDomain: () => `Domain not allowed`,
    integer: () => `Only integers are allowed`,
    url: () => `Invalid URL`,
    httpHeaderName: () => `Invalid HTTP header name`,
    integerList: () => `Invalid integer list`,
    oneOf: (params: { validators: NamedValidator[] }) =>
      `Must be one of: ${params.validators.map(v => v.name).join(', ')}`,
    ipV4: () => `Invalid IPv4 address`,
    restrictedIPv4: () => `IPv4 address not allowed`,
    ipV6: () => `Invalid IPv6 address`,
    restrictedIPv6: () => `IPv6 address not allowed`,
  };

  listErrors() {
    if (this.control && this.control.errors) {
      this.errorMsgList = [];

      Object.keys(this.control.errors).map(error => {
        if (this.control?.dirty) {
          this.errorMsgList.push(
            this.errorMessage[error](this.control?.errors![error])
          );
        }
      });

      return this.errorMsgList;
    } else {
      return [];
    }
  }
}
