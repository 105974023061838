import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

export type StatusIndicatorStatus = 'success' | 'warning' | 'error' | 'neutral';

@Component({
  selector: 'v-status-indicator',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './status-indicator.component.html',
})
export class VStatusIndicatorComponent {
  @Input() status: StatusIndicatorStatus = 'neutral';
}
