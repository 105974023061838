import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  StatusIndicatorStatus,
  VStatusIndicatorComponent,
} from '../../../status-indicator/status-indicator.component';

@Component({
  selector: 'v-basic-data-item',
  standalone: true,
  imports: [CommonModule, VStatusIndicatorComponent],
  templateUrl: './basic-data-item.component.html',
})
export class VBasicDataItemComponent {
  @Input() title: string = 'Basic Data Item';
  @Input() subtitle: string = 'Some more information';

  @Input() dark = false;
  @Input() fullHeight = false;

  @Input() status?: StatusIndicatorStatus;
  @Input() icon?: string;

  @Output() press = new EventEmitter<void>();
}
