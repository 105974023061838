import { Injectable, inject } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { toIdentifiableEntities } from 'src/app/helpers/graphql';
import {
  ChangeSubscriptionOptions,
  Plan,
  SubscriptionPlan,
} from 'src/app/models/subscription-plan';
import { IdentifiableEntities } from '../common/list-entity';

const FIELDS = `
  id
  status
  canStartTrial
  plan {
    id
    name
    features {
      name
      tier
      quota {
        type
        quota
      }
      usage {
        unit
        value
      }
    }
  }
`;

const DETAILS_FIELDS = `
  details {
    currentPlan {
      plan {
        name
        features {
          name
          tier
          quota {
            type
            quota
          }
          usage {
            unit
            value
          }
        }
      }
      price
      currency
      billingCycle
    }
    changeAt
    nextPlan {
      plan {
        name
        features {
          name
          tier
          quota {
            type
            quota
          }
          usage {
            unit
            value
          }
        }
      }
      price
      currency
      billingCycle
    }
    upcomingInvoice {
      currency
      subtotal
      subtotalExcludingTax
      tax
      creationDate
    }
    currentPeriodStart
    currentPeriodEnd
    cancelAt
  }
`;

const CANCEL_SUBSCRIPTION_MUTATION = gql`
  mutation CancelSubscription($organizationId: ID!) {
    cancelSubscription(organizationId: $organizationId) {
      ${FIELDS}
      ${DETAILS_FIELDS}
    }
  }
`;

const UNCANCEL_SUBSCRIPTION_MUTATION = gql`
  mutation UnCancelSubscription($organizationId: ID!) {
    unCancelSubscription(organizationId: $organizationId) {
      ${FIELDS}
      ${DETAILS_FIELDS}
    }
  }
`;

const CANCEL_SUBSCRIPTION_DOWNGRADE_MUTATION = gql`
  mutation CancelSubscriptionDowngrade($organizationId: ID!) {
    cancelSubscriptionDowngrade(organizationId: $organizationId) {
      ${FIELDS}
      ${DETAILS_FIELDS}
    }
  }
`;

const CHANGE_SUBSCRIPTION_MUTATION = gql`
  mutation ChangeSubscription($organizationId: ID!, $options: ChangeSubscriptionOptions!) {
    changeSubscription(organizationId: $organizationId, options: $options) {
      ${FIELDS}
      ${DETAILS_FIELDS}
    }
  }
`;

const CREATE_SUBSCRIPTION_MUTATION = gql`
  mutation CreateSubscription($organizationId: ID!, $options: CreateSubscriptionOptions!) {
    createSubscription(organizationId: $organizationId, options: $options) {
      ${FIELDS}
      ${DETAILS_FIELDS}
    }
  }
`;

const GET_SUBSCRIPTION = gql`
  query GetSubscription($organizationId: ID!) {
    subscription(organizationId: $organizationId) {
      ${FIELDS}
    }
  }
`;

const GET_SUBSCRIPTION_DETAILS = gql`
  query GetSubscriptionDetails($organizationId: ID!) {
    subscription(organizationId: $organizationId) {
      ${FIELDS}
      ${DETAILS_FIELDS}
    }
  }
`;

const GET_PLANS = gql`
  query GetPlans($organizationId: ID!) {
    plans(organizationId: $organizationId) {
      id
      name
      order
      features {
        name
        tier
        quota {
          type
          quota
        }
        usage {
          unit
          value
        }
      }
      prices {
        id
        currency
        interval
        intervalCount
        trialPeriodDays
        amount
      }
    }
  }
`;

@Injectable()
export class SubscriptionService {
  private apollo = inject(Apollo);

  getSubscription(
    organizationId: string,
    withDetails = false
  ): Observable<SubscriptionPlan> {
    return this.apollo
      .query<{ subscription: SubscriptionPlan }>({
        query: withDetails ? GET_SUBSCRIPTION_DETAILS : GET_SUBSCRIPTION,
        variables: {
          organizationId: organizationId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.subscription;
        })
      );
  }

  cancelSubscription(organizationId: string): Observable<SubscriptionPlan> {
    return this.apollo
      .mutate<{ cancelSubscription: SubscriptionPlan }>({
        mutation: CANCEL_SUBSCRIPTION_MUTATION,
        variables: {
          organizationId: organizationId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.cancelSubscription;
        })
      );
  }

  cancelSubscriptionDowngrade(
    organizationId: string
  ): Observable<SubscriptionPlan> {
    return this.apollo
      .mutate<{ cancelSubscriptionDowngrade: SubscriptionPlan }>({
        mutation: CANCEL_SUBSCRIPTION_DOWNGRADE_MUTATION,
        variables: {
          organizationId: organizationId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.cancelSubscriptionDowngrade;
        })
      );
  }

  unCancelSubscription(organizationId: string): Observable<SubscriptionPlan> {
    return this.apollo
      .mutate<{ unCancelSubscription: SubscriptionPlan }>({
        mutation: UNCANCEL_SUBSCRIPTION_MUTATION,
        variables: {
          organizationId: organizationId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.unCancelSubscription;
        })
      );
  }

  createSubscription(
    organizationId: string,
    options: ChangeSubscriptionOptions
  ): Observable<SubscriptionPlan> {
    return this.apollo
      .mutate<{ createSubscription: SubscriptionPlan }>({
        mutation: CREATE_SUBSCRIPTION_MUTATION,
        variables: {
          organizationId: organizationId,
          options: options,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.createSubscription;
        })
      );
  }

  changeSubscription(
    organizationId: string,
    options: ChangeSubscriptionOptions
  ): Observable<SubscriptionPlan> {
    // isTrial is not supported for change subscription requests
    delete options.isTrial;

    return this.apollo
      .mutate<{ changeSubscription: SubscriptionPlan }>({
        mutation: CHANGE_SUBSCRIPTION_MUTATION,
        variables: {
          organizationId: organizationId,
          options: options,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.changeSubscription;
        })
      );
  }

  getPlans(organizationId: string): Observable<IdentifiableEntities<Plan>> {
    return this.apollo
      .query<{ plans: Plan[] }>({
        query: GET_PLANS,
        variables: {
          organizationId: organizationId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return toIdentifiableEntities(data.data!.plans, 'id');
        })
      );
  }
}
