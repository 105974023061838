<v-card [center]="true" padding="lg">
  <v-card-image-header
    slot="header"
    [text]="'pages.maintenance.header' | translate"
    image="/assets/images/logos/incidite-full-white.svg"
    imageClass="!h-7"></v-card-image-header>

  <div slot="body">
    <div class="text-center">
      <img
        src="/assets/images/maintenance.svg"
        alt="maintenance"
        class="w-6 my-6" />

      @if ((maintenanceEnabled$ | async) === false) {
        <div
          class="flex justify-center"
          *ngIf="(maintenanceEnabled$ | async) === false">
          <v-button
            [label]="'pages.maintenance.returnBtn' | translate"
            (press)="returnToDashboard()"></v-button>
        </div>
      } @else {
        <p class="text-lg text-500">
          {{ 'pages.maintenance.message' | translate }}
        </p>
      }
    </div>
  </div>
</v-card>
