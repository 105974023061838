export interface Statistic {
  name: string;
  unit: Unit;
  datapoints: DataPoint[];
}

export interface DataPoint {
  date: Date;
  value: number;
}

export interface StatisticOptions {
  windowStart: Date;
  windowEnd?: Date;
}

export enum Unit {
  Seconds = 's',
  Milliseconds = 'ms',
  Percent = 'percent',
}

export const mapStatistics = (statistics: Statistic): Statistic => {
  return {
    ...statistics,
    datapoints: statistics.datapoints.map(dp => ({
      ...dp,
      date: new Date(dp.date),
    })),
  };
};

export const UnitFormatters = {
  [Unit.Milliseconds]: (value: number) => value.toFixed(0) + ' ms',
  [Unit.Seconds]: (value: number) => value.toFixed(2) + ' s',
  [Unit.Percent]: (value: number) => (value * 100).toFixed(3) + ' %',
};
