import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  Observable,
  catchError,
  forkJoin,
  map,
  of,
  tap,
  throwError,
} from 'rxjs';
import { toIdentifiableEntities } from 'src/app/helpers/graphql';
import { Alert } from 'src/app/models/alert';
import { EventTypeToHeader } from 'src/app/models/events';
import {
  Incident,
  IncidentEvent,
  IncidentEventActor,
  IncidentEventPayloadFieldUpdated,
  IncidentRoleWithAssignment,
} from 'src/app/models/incident';
import { OrganizationMember } from 'src/app/models/organization';
import { Service } from 'src/app/models/service';
import { ToastService } from 'src/app/services/toast/toast.service';
import {
  Entity,
  NewEntity,
  loadEntity,
  saveEntity,
} from 'src/app/store/common/entity';
import {
  IdentifiableEntities,
  ListEntities,
  addListEntity,
  removeListEntity,
} from 'src/app/store/common/list-entity';
import {
  PaginatedListEntities,
  PaginatedListEntitiesState,
} from 'src/app/store/common/paginated-list-entity';
import {
  CursorPagination,
  CursorPaginationState,
  OffsetPagination,
  OffsetPaginationState,
} from 'src/app/store/common/pagination';
import { UserState } from 'src/app/store/user/user.state';
import { ServicesState } from '../services/services.state';
import {
  AddCommentToIncidentAction,
  AddIncidentEventAction,
  AssignResponderRoleAction,
  DeleteIncidentEventAction,
  LinkServiceToIncident,
  LinkStatuspageToIncident,
  LoadIncidentDetailsAction,
  LoadIncidentRelatedAlertsAction,
  UnLinkServiceFromIncident,
  UnassignResponderRoleAction,
  UnlinkStatuspageFromIncident,
  UpdateSelectedIncidentPriority,
  UpdateSelectedIncidentServiceImpact,
  UpdateSelectedIncidentState,
  UpdateSelectedIncidentSummary,
  UpdateSelectedIncidentTiming,
  UpdateSelectedIncidentTitle,
} from './incidents.actions';
import { IncidentsService } from './incidents.service';

export interface IncidentDetailsStateModel {
  incident: Entity<Incident>;
  events: PaginatedListEntitiesState<IncidentEvent, CursorPaginationState>;
  relatedAlerts: PaginatedListEntitiesState<Alert, OffsetPaginationState>;
}

@State<IncidentDetailsStateModel>({
  name: 'incidentDetails',
  defaults: {
    incident: NewEntity(),
    events: CursorPagination.GetDefaultState(),
    relatedAlerts: OffsetPagination.GetDefaultState(4),
  },
})
@Injectable()
export class IncidentDetailsState {
  store = inject(Store);
  incidentsService = inject(IncidentsService);
  toast = inject(ToastService);
  translate = inject(TranslateService);

  eventPagination = new PaginatedListEntities<
    IncidentDetailsStateModel,
    CursorPagination
  >('events', new CursorPagination());

  relatedAlertsPagination = new PaginatedListEntities<
    IncidentDetailsStateModel,
    OffsetPagination
  >('relatedAlerts', new OffsetPagination());

  @Selector()
  static incidentDetails(state: IncidentDetailsStateModel): Entity<Incident> {
    return state.incident;
  }

  @Selector()
  static events(
    state: IncidentDetailsStateModel
  ): PaginatedListEntitiesState<IncidentEvent, CursorPaginationState> {
    return state.events;
  }

  @Selector()
  static relatedAlerts(
    state: IncidentDetailsStateModel
  ): PaginatedListEntitiesState<Alert, OffsetPaginationState> {
    return state.relatedAlerts;
  }

  @Selector([UserState.membership])
  static getOwnAssignedRoles(
    state: IncidentDetailsStateModel,
    membership: OrganizationMember
  ) {
    if (!state.incident.data?.roles) return [];

    return state.incident?.data?.roles?.filter(role => {
      const assignment = role.assignments.find(assignment => {
        return assignment.memberId === membership.id;
      });
      return assignment !== undefined;
    });
  }

  @Selector([ServicesState.services])
  static availableServices(
    state: IncidentDetailsStateModel,
    allServices: ListEntities<Service>
  ): Service[] {
    const selectedServices = state.incident?.data?.affectedServices?.map(
      s => s.service.id
    );

    return allServices.entities
      ? Object.values(allServices.entities)
          .filter(s => !selectedServices?.includes(s.data!.id))
          .map(entity => entity.data!)
      : [];
  }

  @Action(LoadIncidentDetailsAction)
  loadIncidentDetails(
    ctx: StateContext<IncidentDetailsStateModel>,
    { id, eventPagination }: LoadIncidentDetailsAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    let refresh = false;
    if (ctx.getState().incident.data?.id === id) {
      refresh = true;
    }

    if (!eventPagination) {
      if (refresh) {
        eventPagination = ctx.getState().events.pagination;
      } else {
        eventPagination = CursorPagination.GetDefaultState().pagination;
      }
    }

    const observable = this.incidentsService.loadIncident(
      organizationID,
      id,
      eventPagination
    );

    return forkJoin([
      loadEntity(ctx, 'incident', observable, refresh),
      this.eventPagination.loadEntititesPaginated(
        ctx,
        eventPagination!,
        observable.pipe(
          map((incident: Incident) => {
            let events: IdentifiableEntities<IncidentEvent> = {};
            if (incident.events) {
              events = toIdentifiableEntities(
                incident.events.events.map(event => {
                  return event;
                }),
                'id'
              );

              // check if there were any errors when loading the event payload
              events = Object.fromEntries(
                Object.entries(events).map(([key, val]) => {
                  const payload = Object.values(val.data!.payload)[0];

                  if (payload instanceof Error) {
                    val.loadingError = payload.message;
                  }

                  return [key, val];
                })
              );
            }

            return {
              items: events,
              totalSize: incident.events?.totalSize ?? 0,
              nextPageToken: incident.events?.nextPageToken,
            };
          })
        ),
        refresh
      ),
    ]);
  }

  @Action(LoadIncidentRelatedAlertsAction)
  loadRelatedAlerts(
    ctx: StateContext<IncidentDetailsStateModel>,
    { id, relatedAlertsPagination }: LoadIncidentRelatedAlertsAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    let refresh = false;
    if (ctx.getState().incident.data?.id === id) {
      refresh = true;
    }

    if (!relatedAlertsPagination) {
      if (refresh) {
        relatedAlertsPagination = ctx.getState().relatedAlerts.pagination;
      } else {
        relatedAlertsPagination =
          OffsetPagination.GetDefaultState(4).pagination;
      }
    }

    return this.relatedAlertsPagination.loadEntititesPaginated(
      ctx,
      relatedAlertsPagination!,
      this.incidentsService.loadRelatedAlerts(
        organizationID,
        id,
        relatedAlertsPagination
      ),
      refresh
    );
  }

  @Action(UpdateSelectedIncidentTitle)
  updateIncidentTitle(
    ctx: StateContext<IncidentDetailsStateModel>,
    { newTitle }: UpdateSelectedIncidentTitle
  ) {
    return this.execute(
      ctx,
      this.incidentsService.updateIncidentTitle.bind(this.incidentsService),
      newTitle
    );
  }

  @Action(UpdateSelectedIncidentSummary)
  updateIncidentSummary(
    ctx: StateContext<IncidentDetailsStateModel>,
    { newSummary }: UpdateSelectedIncidentSummary
  ) {
    return this.execute(
      ctx,
      this.incidentsService.updateIncidentSummary.bind(this.incidentsService),
      newSummary
    );
  }

  @Action(UpdateSelectedIncidentPriority)
  updateIncidentPriority(
    ctx: StateContext<IncidentDetailsStateModel>,
    { newPriorityId }: UpdateSelectedIncidentPriority
  ) {
    return this.execute(
      ctx,
      this.incidentsService.updateIncidentPriority.bind(this.incidentsService),
      newPriorityId
    );
  }

  @Action(UpdateSelectedIncidentState)
  updateIncidentState(
    ctx: StateContext<IncidentDetailsStateModel>,
    { newStateId }: UpdateSelectedIncidentState
  ) {
    return this.execute(
      ctx,
      this.incidentsService.updateIncidentState.bind(this.incidentsService),
      newStateId
    );
  }

  @Action(UpdateSelectedIncidentServiceImpact)
  updateIncidentServiceImpact(
    ctx: StateContext<IncidentDetailsStateModel>,
    { service, impact }: UpdateSelectedIncidentServiceImpact
  ) {
    return this.execute(
      ctx,
      this.incidentsService.updateIncidentServiceImpact.bind(
        this.incidentsService
      ),
      service,
      impact
    );
  }

  @Action(UpdateSelectedIncidentTiming)
  updateIncidentTiming(
    ctx: StateContext<IncidentDetailsStateModel>,
    { timing, datetime, setNull }: UpdateSelectedIncidentTiming
  ) {
    return this.execute(
      ctx,
      this.incidentsService.updateIncidentImpactTiming.bind(
        this.incidentsService
      ),
      timing,
      datetime,
      setNull
    );
  }

  @Action(LinkServiceToIncident)
  linkServiceToIncident(
    ctx: StateContext<IncidentDetailsStateModel>,
    { publicServiceId }: LinkServiceToIncident
  ) {
    return this.execute(
      ctx,
      this.incidentsService.linkService.bind(this.incidentsService),
      publicServiceId
    );
  }

  @Action(UnLinkServiceFromIncident)
  unLinkServiceFromIncident(
    ctx: StateContext<IncidentDetailsStateModel>,
    { publicServiceId }: UnLinkServiceFromIncident
  ) {
    return this.execute(
      ctx,
      this.incidentsService.unLinkService.bind(this.incidentsService),
      publicServiceId
    );
  }

  @Action(LinkStatuspageToIncident)
  linkStatuspageToIncident(
    ctx: StateContext<IncidentDetailsStateModel>,
    { publicStatuspageId }: LinkStatuspageToIncident
  ) {
    return this.execute(
      ctx,
      this.incidentsService.linkStatuspage.bind(this.incidentsService),
      publicStatuspageId
    );
  }

  @Action(UnlinkStatuspageFromIncident)
  unlinkStatuspageToIncident(
    ctx: StateContext<IncidentDetailsStateModel>,
    { publicStatuspageId }: UnlinkStatuspageFromIncident
  ) {
    return this.execute(
      ctx,
      this.incidentsService.unlinkStatuspage.bind(this.incidentsService),
      publicStatuspageId
    );
  }

  @Action(AddCommentToIncidentAction)
  addCommentToIncident(
    ctx: StateContext<IncidentDetailsStateModel>,
    { comment }: AddCommentToIncidentAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    const state = ctx.getState();

    if (!state.incident.data) throw new Error('No incident selected');

    return this.incidentsService.addCommentToIncident(
      organizationID,
      state.incident.data.id,
      comment
    );
  }

  @Action(AssignResponderRoleAction)
  assignIdentityToIncidentRole(
    ctx: StateContext<IncidentDetailsStateModel>,
    { memberId, roleId }: AssignResponderRoleAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    const state = ctx.getState();

    if (!state.incident.data) throw new Error('No incident selected');

    return this.incidentsService
      .assignResponderRole(
        organizationID,
        state.incident.data.id,
        memberId,
        roleId
      )
      .pipe(
        tap(incidentRoleAssignment => {
          // deep copy the roles array
          const roles = Object.assign(
            [],
            state.incident.data!.roles!
          ) as IncidentRoleWithAssignment[];

          // find the role that was assigned
          for (const role of roles) {
            if (role.role.id === roleId) {
              role.assignments.push(incidentRoleAssignment);
            }
          }

          ctx.setState(
            patch({
              incident: patch<Entity<Incident>>({
                data: patch<Incident>({
                  roles: roles,
                }),
              }),
            })
          );
        }),
        catchError(error => {
          this.toast.showError(error);

          return throwError(() => error);
        })
      );
  }

  @Action(UnassignResponderRoleAction)
  unassignIdentityFromIncidentRole(
    ctx: StateContext<IncidentDetailsStateModel>,
    { roleId, assignmentId, memberId }: UnassignResponderRoleAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    const state = ctx.getState();

    if (!state.incident.data) throw new Error('No incident selected');

    return this.incidentsService
      .unassignResponderRole(
        organizationID,
        state.incident.data.id,
        assignmentId
      )
      .pipe(
        tap(() => {
          const roles = Object.assign(
            [],
            state.incident.data!.roles!
          ) as IncidentRoleWithAssignment[];
          for (const role of roles) {
            if (role.role.id === roleId) {
              role.assignments = role.assignments.filter(
                a => a.memberId !== memberId
              );
            }
          }

          ctx.setState(
            patch({
              incident: patch<Entity<Incident>>({
                data: patch<Incident>({
                  roles: roles,
                }),
              }),
            })
          );
        }),
        catchError(error => {
          this.toast.showError(error);

          return throwError(() => error);
        })
      );
  }

  @Action(DeleteIncidentEventAction)
  deleteIncidentEvent(
    ctx: StateContext<IncidentDetailsStateModel>,
    { eventId }: DeleteIncidentEventAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    const state = ctx.getState();

    if (!state.incident.data) throw new Error('No incident selected');

    return removeListEntity(
      ctx,
      'events',
      eventId,
      (event: IncidentEvent) => {
        return event.id !== eventId;
      },
      this.incidentsService.deleteIncidentEvent(
        organizationID,
        state.incident.data.id,
        eventId
      )
    ).pipe(
      tap(() => {
        this.toast.showInfo(
          this.translate.instant('incidentEvents.toasts.eventDeleted.header'),
          this.translate.instant('incidentEvents.toasts.eventDeleted.body')
        );
      })
    );
  }

  @Action(AddIncidentEventAction)
  addIncidentEvent(
    ctx: StateContext<IncidentDetailsStateModel>,
    { incidentId, event }: AddIncidentEventAction
  ) {
    const state = ctx.getState();
    if (state.incident.data?.id === incidentId) {
      const statePatch: any = {};

      if (event.type === 'FIELD_UPDATED') {
        const payload = event.payload as IncidentEventPayloadFieldUpdated;

        if (payload.newValue.intValue) {
          statePatch[payload.field] = payload.newValue.intValue;
        } else if (payload.newValue.stringValue) {
          statePatch[payload.field] = payload.newValue.stringValue;
        }

        ctx.setState(
          patch({
            incident: patch({
              data: patch(statePatch),
            }),
          })
        );
      } else {
        this.store.dispatch(new LoadIncidentDetailsAction(incidentId));
      }

      const translatedEventAction = this.translate.instant(
        EventTypeToHeader(event)
      );

      if (event.actor.type === IncidentEventActor.SYSTEM) {
        this.toast.showInfo(
          this.translate.instant('incidentEvents.toastHeader'),
          `System ${translatedEventAction}`
        );
      } else if (event.actor.type === IncidentEventActor.USER) {
        this.toast.showInfo(
          this.translate.instant('incidentEvents.toastHeader'),
          `${event.actor.user!.firstname} ${event.actor.user!.lastname} ${translatedEventAction}`
        );
      }

      return addListEntity(ctx, 'events', 'id', of(event));
    }

    return of();
  }

  // update incident helper
  // Generic function to call the incidents service
  private execute<K extends keyof Incident, T extends any[]>(
    ctx: StateContext<IncidentDetailsStateModel>,
    serviceMethod: (
      organizationId: string,
      incidentId: number,
      ...args: T
    ) => Observable<Pick<Incident, K>>,
    ...args: T
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    const state = ctx.getState();

    return saveEntity(
      ctx,
      'incident',
      serviceMethod(organizationID, state.incident.data!.id!, ...args),
      true
    ).pipe(
      catchError(error => {
        this.toast.showError(error);
        throw error;
      })
    );
  }
}
