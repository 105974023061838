import { NgModule } from '@angular/core';

import { StyleClassModule } from 'primeng/styleclass';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { BadgeModule } from 'primeng/badge';
import { DividerModule } from 'primeng/divider';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { MessagesModule } from 'primeng/messages';

import { MessageModule } from 'primeng/message';
import { TagModule } from 'primeng/tag';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SkeletonModule } from 'primeng/skeleton';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ChipModule } from 'primeng/chip';
import { TimelineModule } from 'primeng/timeline';
import { SplitButtonModule } from 'primeng/splitbutton';
import { DialogModule } from 'primeng/dialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SplitterModule } from 'primeng/splitter';
import { InputMaskModule } from 'primeng/inputmask';
import { ToastModule } from 'primeng/toast';

@NgModule({
  imports: [
    StyleClassModule,
    MenuModule,
    ButtonModule,
    BadgeModule,
    DividerModule,
    CheckboxModule,
    InputTextModule,
    MessageModule,
    MessagesModule,
    TagModule,
    InputSwitchModule,
    InputTextareaModule,
    SkeletonModule,
    DynamicDialogModule,
    ChipModule,
    TimelineModule,
    SplitButtonModule,
    DialogModule,
    OverlayPanelModule,
    SplitterModule,
    InputMaskModule,
    ToastModule,
  ],
  exports: [
    StyleClassModule,
    MenuModule,
    ButtonModule,
    BadgeModule,
    DividerModule,
    CheckboxModule,
    InputTextModule,
    MessageModule,
    MessagesModule,
    TagModule,
    InputSwitchModule,
    InputTextareaModule,
    SkeletonModule,
    DynamicDialogModule,
    ChipModule,
    TimelineModule,
    SplitButtonModule,
    DialogModule,
    OverlayPanelModule,
    SplitterModule,
    InputMaskModule,
    ToastModule,
  ],
})
export class PrimeNGModule {}
