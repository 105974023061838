import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { ToastService } from 'src/app/services/toast/toast.service';
import {
  CreateBroadcastChannelAction,
  DeleteBroadcastChannelAction,
  LoadBroadcastChannelsAction,
  UpdateBroadcastChannelAction,
} from './broadcast-channels.actions';
import { UserState } from 'src/app/store/user/user.state';
import { catchError, map } from 'rxjs';
import {
  addListEntity,
  ListEntities,
  loadEntities,
  NewListEntities,
  removeListEntity,
  updateListEntity,
} from 'src/app/store/common/list-entity';
import { toIdentifiableEntities } from 'src/app/helpers/graphql';
import { BroadcastChannelsService } from './broadcast-channels.service';
import { BroadcastChannel } from 'src/app/models/broadcast-channel';

export interface BroadcastChannelStateModel {
  channels: ListEntities<BroadcastChannel>;
}

@State<BroadcastChannelStateModel>({
  name: 'broadcastChannels',
  defaults: {
    channels: NewListEntities(),
  },
})
@Injectable()
export class BroadcastChannelState {
  store = inject(Store);
  bcService = inject(BroadcastChannelsService);
  toastService = inject(ToastService);

  @Selector()
  static broadcastChannels(state: BroadcastChannelStateModel) {
    return state.channels;
  }

  @Action(LoadBroadcastChannelsAction)
  loadBroadcastChannels(ctx: StateContext<BroadcastChannelStateModel>) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return loadEntities(
      ctx,
      'channels',
      this.bcService.listBroadcastChannels(organizationID).pipe(
        map(channels => {
          return toIdentifiableEntities(channels, 'id');
        })
      )
    );
  }
  @Action(CreateBroadcastChannelAction)
  createBroadcastChannel(
    ctx: StateContext<BroadcastChannelStateModel>,
    {
      iql,
      providerProfileId,
      recipientEndpointId,
    }: CreateBroadcastChannelAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return addListEntity(
      ctx,
      'channels',
      'id',
      this.bcService.createBroadcastChannel(
        organizationID,
        iql,
        recipientEndpointId,
        providerProfileId
      )
    ).pipe(
      catchError(err => {
        this.toastService.showError(err);
        throw err;
      })
    );
  }

  @Action(UpdateBroadcastChannelAction)
  updateBroadcastChannel(
    ctx: StateContext<BroadcastChannelStateModel>,
    {
      id,
      iql,
      providerProfileId,
      recipientEndpointId,
    }: UpdateBroadcastChannelAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return updateListEntity(
      ctx,
      'channels',
      id,
      this.bcService.updateBroadcastChannel(
        organizationID,
        id,
        iql,
        providerProfileId,
        recipientEndpointId
      )
    ).pipe(
      catchError(err => {
        this.toastService.showError(err);
        throw err;
      })
    );
  }

  @Action(DeleteBroadcastChannelAction)
  deleteBroadcastChannel(
    ctx: StateContext<BroadcastChannelStateModel>,
    { id }: DeleteBroadcastChannelAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return removeListEntity(
      ctx,
      'channels',
      id,
      (bc: BroadcastChannel) => {
        return bc.id !== id;
      },
      this.bcService.deleteBroadcastChannel(organizationID, id)
    );
  }
}
