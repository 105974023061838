import {
  addMinutes,
  DurationUnit,
  formatDuration,
  intervalToDuration,
} from 'date-fns';
import { Unit, UnitFormatters } from 'src/app/models/statistics';

export enum ChartFormatter {
  Unit = 'unit',
  LocalDateTime = 'localDateTime',
}

export const chartFormatters = {
  [ChartFormatter.Unit]: UnitFormatters,
  [ChartFormatter.LocalDateTime]: (value: number) => {
    return new Date(value).toLocaleString();
  },
};

export enum HeaderFormat {
  Text = 'text',
  Duration = 'duration',
  Unit = 'unit',
}

export const headerFormatters = {
  [HeaderFormat.Text]: (value: number) => value.toString(),
  [HeaderFormat.Unit]: (value: number, unit: Unit) => {
    return UnitFormatters[unit](value);
  },
  [HeaderFormat.Duration]: (value: number) => {
    // eslint-disable-next-line no-case-declarations
    const duration = intervalToDuration({
      start: new Date(),
      end: addMinutes(new Date(), value),
    });

    // eslint-disable-next-line no-case-declarations
    let units: DurationUnit[] = [];

    if (duration.days) {
      units = ['days', 'hours'];
    } else if (duration.hours) {
      units = ['hours', 'minutes'];
    } else {
      units = ['minutes', 'seconds'];
    }

    return formatDuration(
      duration,
      value === 0
        ? { format: ['minutes'], zero: true }
        : { format: units, zero: true }
    );
  },
};
