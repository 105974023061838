import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, map, tap } from 'rxjs';
import { Statuspage, StatuspageSubscription } from 'src/app/models/statuspage';
import { ToastService } from 'src/app/services/toast/toast.service';
import {
  Entity,
  loadEntity,
  NewEntity,
  saveEntity,
} from 'src/app/store/common/entity';
import { UserState } from 'src/app/store/user/user.state';
import {
  PaginatedListEntities,
  PaginatedListEntitiesState,
} from '../common/paginated-list-entity';
import { OffsetPagination, OffsetPaginationState } from '../common/pagination';
import { StatuspageService } from './statuspage.service';
import {
  CreateStatuspageComponentAction,
  LoadStatuspageDetailsAction,
  LoadSubscriptionsAction,
  OrderStatuspageComponentsAction,
  RemoveStatuspageComponentAction,
  UnsubscribeFromStatuspageAction,
  UpdateStatuspageAction,
  UpdateStatuspageComponentAction,
} from './statuspages.actions';

export interface StatuspageDetailsStateModel {
  statuspage: Entity<Statuspage>;
  subscriptions: PaginatedListEntitiesState<
    StatuspageSubscription,
    OffsetPaginationState
  >;
}

@State<StatuspageDetailsStateModel>({
  name: 'statuspageDetails',
  defaults: {
    statuspage: NewEntity(),
    subscriptions: OffsetPagination.GetDefaultState(),
  },
})
@Injectable()
export class StatuspageDetailsState {
  store = inject(Store);
  statuspageService = inject(StatuspageService);
  toast = inject(ToastService);

  subscriptionPagination = new PaginatedListEntities<
    StatuspageDetailsStateModel,
    OffsetPagination
  >('subscriptions', new OffsetPagination());

  @Selector()
  static statuspageDetails(
    state: StatuspageDetailsStateModel
  ): Entity<Statuspage> {
    return state.statuspage;
  }

  @Selector()
  static subscriptions(state: StatuspageDetailsStateModel) {
    return state.subscriptions;
  }

  @Action(LoadStatuspageDetailsAction)
  loadStatuspageDetails(
    ctx: StateContext<StatuspageDetailsStateModel>,
    { statuspageId }: LoadStatuspageDetailsAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    const observable = this.statuspageService.loadStatuspage(
      organizationID,
      statuspageId
    );

    return loadEntity(ctx, 'statuspage', observable);
  }

  @Action(UpdateStatuspageAction)
  UpdateStatuspage(
    ctx: StateContext<StatuspageDetailsStateModel>,
    { statuspageId, updateSpec }: UpdateStatuspageAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return saveEntity(
      ctx,
      'statuspage',
      this.statuspageService.updateStatuspage(
        organizationID,
        statuspageId,
        updateSpec
      ),
      true
    ).pipe(
      tap(() => {
        this.toast.showInfo('Success', 'Statuspage updated');
      }),
      catchError(error => {
        this.toast.showError(new Error('Could not update statuspage:' + error));
        return error;
      })
    );
  }

  @Action(CreateStatuspageComponentAction)
  CreateStatuspageComponent(
    ctx: StateContext<StatuspageDetailsStateModel>,
    { statuspageId, componentSpec }: CreateStatuspageComponentAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return saveEntity(
      ctx,
      'statuspage',
      this.statuspageService
        .createStatuspageComponent(organizationID, statuspageId, componentSpec)
        .pipe(
          map(component => {
            const state = ctx.getState();

            return {
              components: [state.statuspage.data!.components, component].flat(),
            };
          })
        ),
      true
    );
  }

  @Action(UpdateStatuspageComponentAction)
  UpdateStatuspageComponent(
    ctx: StateContext<StatuspageDetailsStateModel>,
    { statuspageId, componentId, updateSpec }: UpdateStatuspageComponentAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return saveEntity(
      ctx,
      'statuspage',
      this.statuspageService
        .updateStatuspageComponent(
          organizationID,
          statuspageId,
          componentId,
          updateSpec
        )
        .pipe(
          map(component => {
            const state = ctx.getState();

            return {
              components: state.statuspage.data!.components?.map(c => {
                if (c.id === componentId) {
                  return {
                    ...c,
                    ...component,
                  };
                }
                return c;
              }),
            };
          })
        ),
      true
    );
  }

  @Action(RemoveStatuspageComponentAction)
  RemoveStatuspageComponent(
    ctx: StateContext<StatuspageDetailsStateModel>,
    { statuspageId, componentId }: RemoveStatuspageComponentAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return saveEntity(
      ctx,
      'statuspage',
      this.statuspageService
        .deleteStatuspageComponent(organizationID, statuspageId, componentId)
        .pipe(
          map(() => {
            const state = ctx.getState();

            return {
              components: state.statuspage.data!.components?.filter(
                component => {
                  return component.id !== componentId;
                }
              ),
            };
          })
        ),
      true
    );
  }

  @Action(OrderStatuspageComponentsAction)
  OrderStatuspageComponents(
    ctx: StateContext<StatuspageDetailsStateModel>,
    { statuspageId, orderedComponentIds }: OrderStatuspageComponentsAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return saveEntity(
      ctx,
      'statuspage',
      this.statuspageService
        .orderStatuspageComponents(
          organizationID,
          statuspageId,
          orderedComponentIds
        )
        .pipe(
          map(() => {
            const state = ctx.getState();

            return {
              components: orderedComponentIds.map(id => {
                const component = state.statuspage.data?.components?.find(
                  c => c.id === id
                );
                return component;
              }),
            };
          })
        ),
      true
    ).pipe(
      tap(() => {
        this.toast.showInfo('Success', 'Order updated');
      })
    );
  }

  @Action(LoadSubscriptionsAction)
  loadSubscriptions(
    ctx: StateContext<StatuspageDetailsStateModel>,
    { pagination }: LoadSubscriptionsAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    const statuspage = this.store.selectSnapshot(
      StatuspageDetailsState.statuspageDetails
    );
    if (!statuspage.data) throw new Error('No statuspage ID set');

    return this.subscriptionPagination.loadEntititesPaginated(
      ctx,
      pagination,
      this.statuspageService.loadSubscriptions(
        organizationID,
        statuspage.data.id,
        pagination
      ),
      true
    );
  }

  @Action(UnsubscribeFromStatuspageAction)
  unsubscribeFromStatuspage(
    ctx: StateContext<StatuspageDetailsStateModel>,
    { manageToken }: UnsubscribeFromStatuspageAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    const statuspage = this.store.selectSnapshot(
      StatuspageDetailsState.statuspageDetails
    );
    if (!statuspage.data) throw new Error('No statuspage ID set');

    return this.statuspageService
      .unsubscribeFromStatuspage(statuspage.data.slug, manageToken)
      .pipe(
        tap(() => {
          this.toast.showInfo('Success', 'Removed subscription');
          ctx.dispatch(
            new LoadSubscriptionsAction({
              page: ctx.getState().subscriptions.pagination.page,
              pageSize: ctx.getState().subscriptions.pagination.pageSize,
            })
          );
        }),
        catchError(error => {
          this.toast.showError(
            new Error('Could not remove subscription:' + error)
          );
          return error;
        })
      );
  }
}
